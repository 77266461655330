import { Box, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { countryIdSelector } from '../../store/component-state/component-state.selectors';
import { Address } from './Address/Address';
import { Contact } from './Contact/Contact';
import { TechSupportBar } from './TechSupportBar/TechSupportBar';
import FooterLinks from './FooterLinks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.h1.fontSize,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h2.fontSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    paddedGrid: {
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
      },
    },
  })
);

export function Footer() {
  const { t } = useTranslation();

  const theme = useStyles();
  const countryId = useSelector(countryIdSelector);

  return (
    <>
      <TechSupportBar />

      <Divider />

      {countryId && (
        <Grid className={theme.container} container justify="space-evenly" alignItems="center">
          <Grid item xs={12} md={6} className={theme.paddedGrid}>
            <Typography variant="h1" className={theme.title}>
              {parse(t(`by-country.${countryId}.footer.company`))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={theme.paddedGrid}>
            <Address />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={theme.paddedGrid}>
            <Contact />
          </Grid>
        </Grid>
      )}
      <Divider />
      <Box p={3} paddingTop={1}>
        <Typography variant="caption">{parse(t(`by-country.${countryId}.footer.disclaimer`))}</Typography>
      </Box>
      <Divider />
      <FooterLinks />
    </>
  );
}
