import { StoreModel, ConstructionDataModel } from '../store.model';
import { ConstructionType, Category, ConstructionLayer } from '../../types/domain/construction-data.types';

export const constructionDataSelector = (store: StoreModel): ConstructionDataModel => store.constructionData;

export const constructionTypesSelector = (store: StoreModel): ConstructionType[] => constructionDataSelector(store).types;

export const isLoadingConstructionTypesSelector = (store: StoreModel): boolean => store.componentState.isLoading;

export const constructionCategoriesSelector = (store: StoreModel): Category[] => [...(store.constructionData?.types ?? [])]
  .sort((a, b) => b.category.localeCompare(a.category))
  .map(ct => ct.category)
  .filter((value, index, self) => self.indexOf(value) === index);

export const constructionLayerSelector = (constructionTypeId: string) => (store: StoreModel): ConstructionLayer[] => store.constructionData.layers[constructionTypeId] ?? [];

export const constructionTypeNameSelector = (constructionTypeId: string) => (store: StoreModel): string => constructionDataSelector(store).types
  .find(ct => ct.id === constructionTypeId)
  ?.nameLocalized ?? constructionTypeId;
