import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCalculation, deleteCalculation, downloadCalculation, directDownloadCalculation } from '../../store/calculations/calculations.action-creators';
import { Box, Typography, Divider, Button, makeStyles, createStyles, Theme, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { Link } from 'react-router-dom';
import { calculationArraySelector, deletingCalculationsSelector } from '../../store/calculations/calculations.selectors';
import { isAuthenticationInitialized } from '../../store/authentication/authentication.selectors';
import { isLoadingSelector, countryIdSelector, downloadingCalculationIdSelector, countrySelector, selectedCultureSelector } from '../../store/component-state/component-state.selectors';

import MyCalculationsDesktop from './MyCalculations.desktop';
import MyCalculationsMobile from './MyCalculations.mobile';
import { displayPdfDownloadToast } from '../../helpers/toast-helpers';
import { Calculation } from '../../types/domain/calculation-data.types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Country } from '../../types/domain/construction-data.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressContainer: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

export type MyCalculationsProps = {
  calculations: Calculation[];
  countryId: string;
  country: Country | null;
  handleDeleteClick: (calculationId: string) => void;
  handleDownloadClick: (calculationId: string, calculationAccessCode: string) => void;
  handleDirectDownloadClick: (calculationId: string, calculationAccessCode: string) => void;
  isDeleting: (calculationId: string) => boolean;
  isDownloading: () => boolean;
  isCalculationDownloading: (calculationId: string) => boolean;
};

export default function MyCalculations() {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const country = useSelector(countrySelector);
  const countryId = useSelector(countryIdSelector);
  const selectedCulture = useSelector(selectedCultureSelector);
  const calculations = useSelector(calculationArraySelector);
  const isAuthInitialized = useSelector(isAuthenticationInitialized);
  const deletingCalculationsStore = useSelector(deletingCalculationsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const downloadingCalculationId: string | null = useSelector(downloadingCalculationIdSelector);
  const classes = useStyles();

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  React.useEffect(() => {
    if (isAuthInitialized && !isEmpty(countryId)) {
      dispatch(fetchCalculation(countryId));
    }
  }, [dispatch, isAuthInitialized, countryId, selectedCulture]);

  const [deletingCalculationIds, setDeletingCalclationIds] = useState([] as string[]);
  const [downloadingState, setDownloadingState] = useState(false);

  React.useEffect(() => {
    if (!downloadingState && downloadingCalculationId) {
      setDownloadingState(true);
    } else if (downloadingState && !downloadingCalculationId) {
      setDownloadingState(false);
    }
  }, [downloadingState, downloadingCalculationId, setDownloadingState]);

  const handleDeleteClick = (calculationId: string) => {
    setDeletingCalclationIds([...deletingCalculationIds, calculationId]);
    dispatch(deleteCalculation(calculationId));
  };

  const handleDownloadClick = async (calculationId: string, calculationAccessCode: string) => {
    const result = await dispatch(downloadCalculation(calculationId, calculationAccessCode));
    displayPdfDownloadToast(result, t);
  };

  const handleDirectDownloadClick = async (calculationId: string, calculationAccessCode: string) => {
    await dispatch(directDownloadCalculation(calculationId, calculationAccessCode));
  };

  React.useEffect(() => {
    setDeletingCalclationIds(deletingCalculationsStore);
  }, [deletingCalculationsStore]);

  const isDeleting = (calculationId: string): boolean => {
    return deletingCalculationIds.some(c => c === calculationId);
  };

  const isDownloading = (): boolean => {
    return downloadingState;
  };

  const isCalculationDownloading = (calculationId: string): boolean => {
    return downloadingCalculationId === calculationId;
  };

  const renderMyCalculationsView = () => {
    return isMobileView ? (
      <MyCalculationsMobile
        calculations={calculations}
        country={country}
        countryId={countryId}
        handleDownloadClick={handleDownloadClick}
        handleDirectDownloadClick={handleDirectDownloadClick}
        handleDeleteClick={handleDeleteClick}
        isDeleting={isDeleting}
        isDownloading={isDownloading}
        isCalculationDownloading={isCalculationDownloading}
      />
    ) : (
      <MyCalculationsDesktop
        calculations={calculations}
        country={country}
        countryId={countryId}
        handleDownloadClick={handleDownloadClick}
        handleDirectDownloadClick={handleDirectDownloadClick}
        handleDeleteClick={handleDeleteClick}
        isDeleting={isDeleting}
        isDownloading={isDownloading}
        isCalculationDownloading={isCalculationDownloading}
      />
    );
  };

  return (
    <>
      {!isMobileView && (
        <>
          <Box marginBottom={4}>
            <Typography variant="h1" component="h2">
              <Trans i18nKey="my-calculations-ui.title">My Calculations</Trans>
            </Typography>
          </Box>

          <Divider />

          <Box my={4}>
            <Button variant="outlined" color="secondary" component={Link} to={buildRouteWithCountry(countryId, '')}>
              <Trans i18nKey="my-calculations-ui.new-calculation">New Calculation</Trans>
            </Button>
          </Box>

          <Divider />
        </>
      )}

      {isAuthInitialized && !isLoading ? (
        renderMyCalculationsView()
      ) : (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}
