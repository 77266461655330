import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import CalculationLayer from '../CalculationLayer/CalculationLayer';
import RcValue from '../RcValue/RcValue';

import './Calculation.module.scss';
import { isLoadingSelector } from '../../store/component-state/component-state.selectors';
import CalculationWizardControls from './CalculationWizardControls';
import { projectDetailsSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { dispatchCalculationAttempt } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { CalculationStep } from '../CalculationPage/calculation-page.types';

export type CalculationProps = {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  activeStepIndex: number;
  constructionTypeId: string;
  steps: CalculationStep[];
}

export default function Calculation(props: CalculationProps) {
  const { activeStepIndex, constructionTypeId, steps, handleNextStep, handlePreviousStep } = props;

  const dispatch = useDispatch();

  const projectDetails = useSelector(projectDetailsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));

  useEffect(() => {
    if (!isLoading) {
      dispatch(dispatchCalculationAttempt());
    }
  }, [dispatch, isLoading]);

  const hasSteps = steps && steps.length > 0;

  return (
    <Box>
      <Box marginBottom={4}>
        <Grid container spacing={2} >
          <Grid item xs="auto" sm="auto" md={1} />
          <Grid item xs={12} sm={12} md={10}>
            <Box className={'calculationTitle'} data-qa-id="calculationNameLabel">
              {constructionTypeName}
            </Box>
            <Box className={'calculationSubTitle'} data-qa-id="projectNameLabel">
              {projectDetails?.name}
            </Box>
          </Grid>
          <Grid item xs="auto" sm="auto" md={1} />
        </Grid>
      </Box>

      {hasSteps ? <Grid container>
        <Grid item xs="auto" sm="auto" md={1} />
        <Grid item xs={12} sm={8} md={7}>
          <Box marginBottom={4}>
            <CalculationLayer />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Box marginBottom={4}>
            <RcValue />
          </Box>
        </Grid>
      </Grid> : <Box m={2}><CircularProgress /></Box>}

      {hasSteps && <CalculationWizardControls activeStepIndex={activeStepIndex} steps={steps} handleBackClick={handlePreviousStep} handleNextClick={handleNextStep} />}
    </Box>
  );
}
