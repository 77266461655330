import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector } from 'react-redux';
import { ConstructionLayer, Material } from '../../types/domain/construction-data.types';
import { CalculationLayerProps } from './CalculationLayer';
import MaterialParametersAux from './MaterialParametersAux';

export default function MaterialParameters(props: CalculationLayerProps) {
  const { isLinkedMaterial } = props;
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const currentSelectedThickness: number = useSelector(currentSelectedThicknessSelector(isLinkedMaterial));
  const currentSelectedMatrial: Material | undefined = useSelector(currentSelectedMaterialSelector(isLinkedMaterial));

  return (
    <MaterialParametersAux
      layer={layer}
      currentSelectedThickness={currentSelectedThickness}
      currentSelectedMaterial={currentSelectedMatrial}
      isLinkedMaterial={isLinkedMaterial}
    />
  );
}
