import * as actionTypes from './account.action-types';
import { AccountDataModel } from '../store.model';
import { ResponseCode } from '../../types/application/response-code.types';

export const initialState: AccountDataModel = {
  uploadCompanyLogoState: {
    isLoading: false
  }
};

export const accountReducer = (
  state: AccountDataModel = initialState,
  action: actionTypes.AccountActionTypes,
): AccountDataModel => {
  switch (action.type) {

    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        loggedInUser: action.payload.user,
        isNewUser: action.payload.isNewUser
      };
    case actionTypes.GDPR_CONSENT_RESPONSE_SELECTED:
      return {
        ...state,
        gdprConsent: action.payload,
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_ATTEMPT:
      return {
        ...state,
        uploadCompanyLogoState: {
          isLoading: true,
          error: null,
        }
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        uploadCompanyLogoState: {
          isLoading: false,
          response: {
            status: ResponseCode.OK,
            ok: action.payload,
          },
        }
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_BAD_REQUEST:
      return {
        ...state,
        uploadCompanyLogoState: {
          isLoading: false,
          error: action.error,
        }
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        uploadCompanyLogoState: {
          isLoading: false,
          error: action.error,
        }
      };
    case actionTypes.SET_IS_CONFIRMED_GUEST:
      return {
        ...state,
        isConfirmedGuest: action.payload,
      };
    default:
      return state;
  }
};