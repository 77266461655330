import React from 'react';
import { useSelector } from 'react-redux';
import { selectedCultureSelector } from '../../store/component-state/component-state.selectors';

export interface FormatNumberProps {
  toFormat: number | null;
}

export default function FormatNumber(props: FormatNumberProps) {
  const culture = useSelector(selectedCultureSelector);

  return props.toFormat
    ? <span>{props.toFormat.toLocaleString(culture, { minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
    : null;
}