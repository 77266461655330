
export enum NavigationItemId {
  NEW_CALCULATION,
  MY_CALCULATIONS,
  MY_ACCOUNT,
}

export type NavigationItem = {
  id: NavigationItemId;
  route: string;
  translationKey: string;
  show: boolean;
  icon: () => JSX.Element;
};