import { StoreModel } from '../store.model';

export const loggedInUserSelector = (store: StoreModel) => store.accountData.loggedInUser;

export const uploadCompanyLogoSelector = (store: StoreModel) => store.accountData.uploadCompanyLogoState;

export const isNewUserSelector = (store: StoreModel) => store.accountData.isNewUser;

export const gdprConsentSelector = (store: StoreModel) => store.accountData.gdprConsent;

export const isConfirmedGuestSelector = (store: StoreModel) => store.accountData.isConfirmedGuest;