import {
  FETCH_USER_ATTEMPT,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPLOAD_COMPANY_LOGO_ATTEMPT,
  UPLOAD_COMPANY_LOGO_SUCCESS,
  UPLOAD_COMPANY_LOGO_BAD_REQUEST,
  UPLOAD_COMPANY_LOGO_FAILURE,
  FetchUserAttempt,
  FetchUserSuccess,
  FetchUserFailure,
  SetIsConfirmedGuest,
  UploadCompanyLogoAttempt,
  UploadCompanyLogoSuccess,
  UploadCompanyLogoBadRequest,
  UploadCompanyLogoFailure,
  GDPRConsentResponseSelected,
  GDPR_CONSENT_RESPONSE_SELECTED,
  RecordGDPRConsentSuccess,
  RECORD_GDPR_CONSENT_SUCCESS,
  RecordGDPRConsentFailure,
  RecordGDPRConsentAttempt,
  RECORD_GDPR_CONSENT_FAILURE,
  RECORD_GDPR_CONSENT_ATTEMPT,
  SET_IS_CONFIRMED_GUEST
}
from './account.action-types';
  
import { User } from '../../types/domain/account.types';

export const fetchUserAttempt = (): FetchUserAttempt => ({
  type: FETCH_USER_ATTEMPT,
});

export const fetchUserSuccess = (data: User, isNewUser: boolean = false): FetchUserSuccess => ({
  type: FETCH_USER_SUCCESS,
  payload: { user: data, isNewUser },
});

export const fetchUserFailure = (error: any): FetchUserFailure => ({
  type: FETCH_USER_FAILURE,
  error,
});

export const recordGDPRConsentSuccess = (): RecordGDPRConsentSuccess => ({
  type: RECORD_GDPR_CONSENT_SUCCESS
});

export const recordGDPRConsentFailure = (): RecordGDPRConsentFailure => ({
  type: RECORD_GDPR_CONSENT_FAILURE
});

export const recordGDPRConsentAttempt = (): RecordGDPRConsentAttempt => ({
  type: RECORD_GDPR_CONSENT_ATTEMPT
});

export const GDPRConsentResponse = (consent: boolean): GDPRConsentResponseSelected => ({
  type: GDPR_CONSENT_RESPONSE_SELECTED,
  payload: consent,
});

export const uploadCompanyLogoAttempt = (): UploadCompanyLogoAttempt => ({
  type: UPLOAD_COMPANY_LOGO_ATTEMPT
});

export const uploadCompanyLogoSuccess = (data: any): UploadCompanyLogoSuccess => ({
  type: UPLOAD_COMPANY_LOGO_SUCCESS,
  payload: data,
});

export const uploadCompanyLogoBadRequest = (error: any): UploadCompanyLogoBadRequest => ({
  type: UPLOAD_COMPANY_LOGO_BAD_REQUEST,
  error,
});

export const uploadCompanyLogoFailure = (error: any): UploadCompanyLogoFailure => ({
  type: UPLOAD_COMPANY_LOGO_FAILURE,
  error,
});

export const setIsConfirmedGuest = (isConfirmedGuest: boolean): SetIsConfirmedGuest => ({
  type: SET_IS_CONFIRMED_GUEST,
  payload: isConfirmedGuest,
});