import { CalculationResultType } from './calculation-data.types';

export type Country = {
  countryId: string;
  englishName: string;
  localizedName: string;
  calculationResultType: CalculationResultType;
  flagEmoji: string;
  calculationStandardName: string;
}

export type ConstructionType = {
  id: string;
  nameLocalized: string;
  category: Category;
  complexity: Complexity;
  defaultWoodPercentage?: number;
  additionalParameters?: ConstructionTypeAdditionalParameters[]
};

export type ConstructionLayer = {
  constructionLayerId: number;
  constructionTypeId: string;
  name: string;
  nameLocalized: string;
  materials: Material[];
  airCavityGradings: AirCavityGrading[];
  layerType: LayerType;
  isWithinBridgingFrame: boolean;
  isPseudoLayer: boolean;
};

export type Material = {
  materialId: string;
  name: string;
  defaultThicknessMillimetres: number;
  optionsForThicknessMillimetres: number[];
  defaultNumberOfAnchorsPerMetreSquare: number;
  optionsForNumberOfAnchorsPerMetreSquare: number[];
  defaultDiameterOfAnchorsMillimetres: number;
  optionsForDiameterOfAnchorsMillimetres: number[];
  defaultAnchorPenetrationMillimetres?: number;
  optionsForAnchorPenetrationMillimetres?: number[];
  linkedMaterials: Material[];
  isLinkedMaterial: boolean;
  isDefaultLayerMaterial: boolean;
  defaultAirCavityGradingId?: string | null;
};

export type AirCavityGrading = {
  airCavityGradingId: string;
  airCavityThicknessId: string | null;
  defaultThicknessMillimetres: number | null;
  isDefaultLayerAirCavityGrading: boolean;
  optionsForThicknessMillimetres: number[];
};

export type ProjectConfig ={
  fieldid: string, 
  country: string,
  fieldNames: string[] | undefined
}

export type County ={
  id: number,
  code: string,
  countryId: string
}

export type FieldData ={
  id:number, 
  fieldName: string,
  code: string
}

export enum Category {
  Floor = 'Floor',
  Roof = 'Roof',
  Wall = 'Wall',
}

export enum LayerType {
  Other = 'Other',
  Anchors = 'Anchors',
  Insulation = 'Insulation',
  AirCavity = 'AirCavity',
  Bridging = 'Bridging',
  AnchorsPreCalculated = 'AnchorsPreCalculated',
  WoodPercentage = 'WoodPercentage',
}

export enum Complexity {
  Simple = 'Simple',
  Complex = 'Complex',
}

export enum ConstructionTypeAdditionalParameters {
  PerimeterAndArea = 'PerimeterAndArea',
}