import React from 'react';
import { Grid, makeStyles, createStyles, Theme, CircularProgress } from '@material-ui/core';
import { CalculationResult } from '../../types/domain/calculation-data.types';
import { useSelector } from 'react-redux';
import { currentCalculationResultSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { Trans } from 'react-i18next';
import { calculationResultLoadingStateSelector } from '../../store/component-state/component-state.selectors';
import FormatNumber from '../FormatNumber/FormatNumber';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    resultProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -34,
      marginLeft: -34,
    },
    loadingClass: {
      opacity: 0.4,
    },
  }),
);

export default function RcValue() {
  const classes = useStyles();
  const result: CalculationResult | undefined = useSelector(currentCalculationResultSelector);
  const isCalculationResultLoading: boolean = useSelector(calculationResultLoadingStateSelector);

  return (
    <div className={classes.wrapper}>
      <Grid container justify="center" alignItems="center" direction="column" className={isCalculationResultLoading ? classes.loadingClass : ''}>
        <Grid item>
          <div className={'rcValueLabel'} data-qa-id="rcValueTitle">
            {result ?
              <Trans i18nKey={`calculation-ui.${result.resultType.toLowerCase()}`}>{result.resultType}</Trans> :
              '--'}
          </div>
        </Grid>
        <Grid item>
          <div className={'rcValueNumber'} data-qa-id="rcValue">
            {result && result.resultValue ?
              <FormatNumber toFormat={result.resultValue} /> :
              '-,--'}
          </div>
        </Grid>
        <Grid item>
          <div className={''} data-qa-id="rcValueUnit">
            {result ?
              result.resultUnits :
              '--'}
          </div>
        </Grid>
        <Grid item>
          <div className={'rcTotalLabel marginTop40'} data-qa-id="totalThicknessLabel">
            <Trans i18nKey='calculation-ui.construction-thickness-label'>Total Construction Thickness</Trans>
          </div>
        </Grid>
        <Grid item>
          <div className={'rcTotalNumber'} data-qa-id="totalThicknessValue">
            {result ?
              result.totalThicknessMillimetres :
              '--'} mm
        </div>
        </Grid>
      </Grid>
      {isCalculationResultLoading && <CircularProgress size={68} className={classes.resultProgress} />}
    </div>
  );
}
