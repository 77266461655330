import {
  FETCH_CALCULATIONS_ATTEMPT,
  FETCH_CALCULATIONS_SUCCESS,
  FETCH_CALCULATIONS_FAILURE,
  FetchCalculationsAttempt,
  FetchCalculationsSuccess,
  FetchCalculationsFailure,
  DELETE_CALCULATION_ATTEMPT,
  DELETE_CALCULATION_SUCCESS,
  DELETE_CALCULATION_FAILURE,
  DeleteCalculationAttempt,
  DeleteCalculationSuccess,
  DeleteCalculationFailure,
  DOWNLOAD_CALCULATION_ATTEMPT,
  DOWNLOAD_CALCULATION_SUCCESS,
  DOWNLOAD_CALCULATION_FAILURE,
  DownloadCalculationAttempt,
  DownloadCalculationSuccess,
  DownloadCalculationFailure,
  DOWNLOAD_PDF_CALCULATION_ATTEMPT,
  DOWNLOAD_PDF_CALCULATION_SUCCESS,
  DOWNLOAD_PDF_CALCULATION_FAILURE,
  DownloadPdfCalculationAttempt,
  DownloadPdfCalculationSuccess,
  DownloadPdfCalculationFailure,
} from './calculations.action-types';
import { Calculation } from '../../types/domain/calculation-data.types';

export const fetchCalculationAttempt = (): FetchCalculationsAttempt => ({
  type: FETCH_CALCULATIONS_ATTEMPT,
});

export const fetchCalculationSuccess = (data: { [key: string]: Calculation }): FetchCalculationsSuccess => ({
  type: FETCH_CALCULATIONS_SUCCESS,
  payload: data,
});

export const fetchCalculationFailure = (error: any): FetchCalculationsFailure => ({
  type: FETCH_CALCULATIONS_FAILURE,
  error,
});

export const deleteCalculationAttempt = (calculationId: string): DeleteCalculationAttempt => ({
  type: DELETE_CALCULATION_ATTEMPT,
  payload: calculationId,
});

export const deleteCalculationSuccess = (calculationId: string): DeleteCalculationSuccess => ({
  type: DELETE_CALCULATION_SUCCESS,
  payload: calculationId,
});

export const deleteCalculationFailure = (error: any, calculationId: string): DeleteCalculationFailure => ({
  type: DELETE_CALCULATION_FAILURE,
  error,
  payload: calculationId,
});

export const downloadCalculationAttempt = (calculationId: string): DownloadCalculationAttempt => ({
  type: DOWNLOAD_CALCULATION_ATTEMPT,
  payload: calculationId,
});

export const downloadCalculationSuccess = (calculationId: string): DownloadCalculationSuccess => ({
  type: DOWNLOAD_CALCULATION_SUCCESS,
  payload: calculationId,
});

export const downloadCalculationFailure = (error: any, calculationId: string): DownloadCalculationFailure => ({
  type: DOWNLOAD_CALCULATION_FAILURE,
  error,
  payload: calculationId,
});

export const downloadPdfCalculationAttempt = (calculationId: string): DownloadPdfCalculationAttempt => ({
  type: DOWNLOAD_PDF_CALCULATION_ATTEMPT,
  payload: calculationId,
});

export const downloadPdfCalculationSuccess = (calculationId: string): DownloadPdfCalculationSuccess => ({
  type: DOWNLOAD_PDF_CALCULATION_SUCCESS,
  payload: calculationId,
});

export const downloadPdfCalculationFailure = (error: any, calculationId: string): DownloadPdfCalculationFailure => ({
  type: DOWNLOAD_PDF_CALCULATION_FAILURE,
  error,
  payload: calculationId,
});
