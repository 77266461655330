import React from 'react';
import { ConstructionLayer, AirCavityGrading } from '../../types/domain/construction-data.types';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, currentSelectedAirCavityGradingSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { setThickness } from '../../store/current-calculation-data/current-calculation-data.actions';
import ThicknessSlider from './ThicknessSlider';
import NoSlider from './NoSlider';

export default function AirCavityThickness() {
  const dispatch = useDispatch();
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const currentSelectedAcg: AirCavityGrading | undefined = useSelector(currentSelectedAirCavityGradingSelector);
  const currentSelectedThickness: number = useSelector(currentSelectedThicknessSelector(false));
  const isFixedLayer: boolean = layer.isWithinBridgingFrame;

  const handleThicknessChange = (value: number) => {
    dispatch(setMaterialParameter(setThickness, layer, value, false));
  };

  if (!isFixedLayer) {
    return (<>
    <ThicknessSlider
    labelKey='thickness-label'
    handleChange={handleThicknessChange}
    selectedThickness={currentSelectedThickness}
    disabled={!currentSelectedAcg || isFixedLayer}
    marks={currentSelectedAcg?.optionsForThicknessMillimetres ?? []}
    min={currentSelectedAcg?.optionsForThicknessMillimetres[0] ?? 0}
    max={currentSelectedAcg?.optionsForThicknessMillimetres[currentSelectedAcg.optionsForThicknessMillimetres.length - 1] ?? 100}
    defaultValue={currentSelectedAcg?.defaultThicknessMillimetres ?? 0}
    units='mm'
    isValueFixed={isFixedLayer} />
    </>);
  }
  else {
    return (<>
    <NoSlider
      labelKey='thickness-label'
      selectedThickness={currentSelectedThickness}
      units='mm' />
      </>);
  }

}
