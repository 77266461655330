
import axios from 'axios';
import { ConstructionLayer, Material, LayerType, Complexity, AirCavityGrading } from '../../types/domain/construction-data.types';
import { CurrentCalculationDataActionTypes } from './current-calculation-data.action-types';
import { CalculationLayer, ProjectDetails, Calculation } from '../../types/domain/calculation-data.types';
import {
  newCalculationStarted,
  fetchCalculationResultsAttempt,
  fetchCalculationResultsSuccess,
  fetchCalculationResultsClientError,
  fetchCalculationResultsFailure,
  setMaterialInternal,
  setThickness,
  setAnchorDiameter,
  setAnchorAmountPerSquareMetre,
  createLinkedLayer,
  removeLinkedLayer,
  setCalculatedLayerValue,
  setAnchorPenetration,
  editCalculationStarted,
  copyCalculationStarted,
  setAirCavityGradingInternal,
} from './current-calculation-data.actions';
import { selectedCultureSelector } from '../../store/component-state/component-state.selectors';
import { StoreModel } from '../store.model';
import { getCalculationLayerKey } from './current-calculation-data.reducer';
import { AuthenticationState } from 'react-aad-msal';
import { activeConstructionTypeSelector } from '../component-state/component-state.selectors';
import { additionalParametersSelector, projectDetailsSelector } from './current-calculation-data.selectors';
import { mapApiResponseToCalculcation } from '../../helpers/calculation-response-helper';
import { debounce } from 'lodash';

const getDefaultMaterial = (layer: ConstructionLayer): Material | undefined => layer.materials.filter(m => m.isDefaultLayerMaterial)[0] ?? layer.materials[0];

const getDefaultLinkedMaterial = (parentMaterial: Material | undefined): Material | undefined => (parentMaterial && parentMaterial.linkedMaterials.filter(m => m.isDefaultLayerMaterial)[0]) ?? (parentMaterial && parentMaterial.linkedMaterials[0]);

const getDefaultAirCavityGrading = (layer: ConstructionLayer): AirCavityGrading | undefined => layer.airCavityGradings.filter(ac => ac.isDefaultLayerAirCavityGrading)[0] ?? layer.airCavityGradings[0];

const applyAutomaticBridgedInsulationAndAirCavityScenario = (constructionLayers: ConstructionLayer[], calculationLayers: { [key: string]: CalculationLayer }) => {
  // In the scenario where there is a bridging layer, and a bridged insulation layer, and an air cavity layer,
  // then the air cavity layer needs its value setting automatically.
  const bridgedInsulationLayerId: number | undefined = constructionLayers.filter(cl => cl.layerType === LayerType.Insulation && cl.isWithinBridgingFrame)[0]?.constructionLayerId;
  const bridgingLayerId: number | undefined = constructionLayers.filter(cl => cl.layerType === LayerType.Bridging)[0]?.constructionLayerId;
  const bridgedAirCavityLayerId: number | undefined = constructionLayers.filter(cl => cl.layerType === LayerType.AirCavity && cl.isWithinBridgingFrame)[0]?.constructionLayerId;
  if (bridgedInsulationLayerId && bridgingLayerId && bridgedAirCavityLayerId) {
    const insulationLayer = calculationLayers[getCalculationLayerKey(bridgedInsulationLayerId)];
    const airLayer = calculationLayers[getCalculationLayerKey(bridgedAirCavityLayerId)];
    const availableGradings = constructionLayers.find(cl => cl.constructionLayerId === airLayer.constructionLayerId)?.airCavityGradings;
    airLayer.thicknessMillimetres = calculationLayers[getCalculationLayerKey(bridgingLayerId)].thicknessMillimetres - insulationLayer.thicknessMillimetres;

    if (airLayer.thicknessMillimetres === 0 && availableGradings) {
      const noAirLayerGrading = availableGradings.find(a => a.airCavityGradingId === 'no-air-layer');
      airLayer.airCavityGrading = noAirLayerGrading;
    }
    else if (insulationLayer.material?.defaultAirCavityGradingId && availableGradings) {
      airLayer.airCavityGrading = availableGradings.find(g => g.airCavityGradingId === insulationLayer.material?.defaultAirCavityGradingId);
    }
  }
};

const mapToCalculationLayers = (mappingFunction: (layer: ConstructionLayer) => CalculationLayer, constructionLayers: ConstructionLayer[]): { [key: string]: CalculationLayer } => {
  const calculationLayers = constructionLayers.map(mappingFunction).reduce((obj, item) => {
    return {
      ...obj,
      [getCalculationLayerKey(item.constructionLayerId)]: item
    };
  }, {}) as { [key: string]: CalculationLayer };

  applyAutomaticBridgedInsulationAndAirCavityScenario(constructionLayers, calculationLayers);

  // Add in any linked layers for the default materials
  constructionLayers.forEach(cl => {
    const defaultMaterial = getDefaultMaterial(cl);
    const defaultLinkedMaterial = getDefaultLinkedMaterial(defaultMaterial);
    if (defaultLinkedMaterial) {
      calculationLayers[getCalculationLayerKey(cl.constructionLayerId, true)] = {
        constructionLayerId: cl.constructionLayerId,
        thicknessMillimetres: defaultLinkedMaterial.defaultThicknessMillimetres,
        material: defaultLinkedMaterial
      };
    }
  });

  return calculationLayers;
};

export const startNewCalculation = (constructionTypeId: string, constructionLayers: ConstructionLayer[]) => (

  async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {

    const calculationLayers = mapToCalculationLayers(cl => {
      const defaultMaterial = getDefaultMaterial(cl);
      const defaultAirCavityGrading = getDefaultAirCavityGrading(cl);

      switch (cl.layerType) {
        case LayerType.WoodPercentage:
          return {
            constructionLayerId: cl.constructionLayerId,
            thicknessMillimetres: Math.round((getState().constructionData.types.filter(ct => ct.id === constructionTypeId)[0].defaultWoodPercentage ?? 0.2) * 100),
          } as CalculationLayer;
        case LayerType.Anchors:
          return {
            constructionLayerId: cl.constructionLayerId,
            material: defaultMaterial,
            diameterOfAnchorsMillimetres: defaultMaterial?.defaultDiameterOfAnchorsMillimetres,
            numberOfAnchorsPerMetreSquare: defaultMaterial?.defaultNumberOfAnchorsPerMetreSquare,
            anchorPenetrationDepthMillimetres: defaultMaterial?.defaultAnchorPenetrationMillimetres,
          } as CalculationLayer;
        case LayerType.AnchorsPreCalculated:
          return {
            constructionLayerId: cl.constructionLayerId,
            material: defaultMaterial,
            netAreaOfAnchorsPerMetreSquareMillimetresSqrd: 50,
          } as CalculationLayer;
        case LayerType.AirCavity:
          return {
            constructionLayerId: cl.constructionLayerId,
            thicknessMillimetres: defaultAirCavityGrading?.defaultThicknessMillimetres,
            airCavityGrading: defaultAirCavityGrading,
          } as CalculationLayer;
        default:
          return {
            constructionLayerId: cl.constructionLayerId,
            thicknessMillimetres: defaultMaterial?.defaultThicknessMillimetres,
            material: defaultMaterial,
          } as CalculationLayer;
      }
    }, constructionLayers);

    dispatch(newCalculationStarted(calculationLayers, constructionTypeId));
  }
);

const buildCalculationLayersFromSaved = (calculation: Calculation, constructionLayers: ConstructionLayer[]) => {
  const calculationLayers = mapToCalculationLayers(cl => {
    const savedReferenceLayer = Object
      .values(calculation.calculationLayers)
      .find(calculationLayer => calculationLayer.constructionLayerId === cl.constructionLayerId &&
        calculationLayer.material?.isLinkedMaterial !== true);

    const material = cl.materials.filter(m => m.materialId === savedReferenceLayer?.material?.materialId)[0] ?? getDefaultMaterial(cl);
    const airCavityGrading = cl.airCavityGradings.filter(ac => ac.airCavityGradingId === savedReferenceLayer?.airCavityGrading?.airCavityGradingId)[0] ?? getDefaultAirCavityGrading(cl);

    switch (cl.layerType) {
      case LayerType.WoodPercentage:
        return {
          constructionLayerId: cl.constructionLayerId,
          thicknessMillimetres: (calculation.woodPercentage ?? 0.2) * 100,
        } as CalculationLayer;
      case LayerType.Anchors:
        return {
          constructionLayerId: cl.constructionLayerId,
          material: material,
          diameterOfAnchorsMillimetres: savedReferenceLayer?.diameterOfAnchorsMillimetres ?? material?.defaultDiameterOfAnchorsMillimetres,
          numberOfAnchorsPerMetreSquare: savedReferenceLayer?.numberOfAnchorsPerMetreSquare ?? material?.defaultNumberOfAnchorsPerMetreSquare,
          anchorPenetrationDepthMillimetres: savedReferenceLayer?.anchorPenetrationDepthMillimetres ?? material?.defaultAnchorPenetrationMillimetres,
        } as CalculationLayer;
      case LayerType.AnchorsPreCalculated:
        return {
          constructionLayerId: cl.constructionLayerId,
          material: material,
          netAreaOfAnchorsPerMetreSquareMillimetresSqrd: savedReferenceLayer?.netAreaOfAnchorsPerMetreSquareMillimetresSqrd ?? 50,
        } as CalculationLayer;
      case LayerType.AirCavity:
        return {
          constructionLayerId: cl.constructionLayerId,
          thicknessMillimetres: airCavityGrading?.defaultThicknessMillimetres,
          airCavityGrading: airCavityGrading,
        } as CalculationLayer;
      default:
        return {
          constructionLayerId: cl.constructionLayerId,
          thicknessMillimetres: savedReferenceLayer?.thicknessMillimetres ?? material?.defaultThicknessMillimetres,
          material: material,
        } as CalculationLayer;
    }
  }, constructionLayers);

  const projectDetails: ProjectDetails = {
    name: calculation.projectDetails.name,
    startDate: calculation.projectDetails.startDate,
    siteArea: calculation.projectDetails.siteArea,
    county: calculation.projectDetails.county,
    size: calculation.projectDetails.size,
    type: calculation.projectDetails.type,
    ribaStatus: calculation.projectDetails.ribaStatus,
    buildingUse: calculation.projectDetails.buildingUse,
    postcode: calculation.projectDetails.postcode
  };

  // Include the linked layers in to the calculation (fixes bug that stops linked layers from being reloaded)
  Object
    .values(calculation.calculationLayers)
    .filter(calculationLayer => calculationLayer.material?.isLinkedMaterial === true)
    .forEach(linkedLayer => {
      calculationLayers[getCalculationLayerKey(linkedLayer.constructionLayerId, true)] = {
        ...linkedLayer
      };
    });

  return {
    projectDetails,
    calculationLayers
  };
};

export const startEditCalculation = (calculation: Calculation, constructionLayers: ConstructionLayer[]) => (
  async (dispatch: (action: CurrentCalculationDataActionTypes) => void) => {
    const { calculationLayers, projectDetails } = buildCalculationLayersFromSaved(calculation, constructionLayers);
    dispatch(editCalculationStarted(
      calculation.calculationId,
      calculation.calculationAccessCode,
      calculationLayers,
      calculation.constructionTypeId,
      projectDetails,
      calculation.calculationResult,
      calculation.additionalParameters));
  }
);

export const startCopyCalculation = (calculation: Calculation, constructionLayers: ConstructionLayer[]) => (
  async (dispatch: (action: CurrentCalculationDataActionTypes) => void) => {
    const { calculationLayers, projectDetails } = buildCalculationLayersFromSaved(calculation, constructionLayers);
    dispatch(copyCalculationStarted(calculationLayers, calculation.constructionTypeId, projectDetails, calculation.additionalParameters));
  }
);

export const setAirCavityGrading = (layer: ConstructionLayer, airCavityGrading: AirCavityGrading) => (
  async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {

    dispatch(setAirCavityGradingInternal(airCavityGrading, layer.constructionLayerId));

    // This is the calulated layer for a complex construction type and doesn't need its thickness setting
    if (!layer.isWithinBridgingFrame) {
      setMaterialParameter(
        setThickness,
        layer,
        airCavityGrading.defaultThicknessMillimetres ?? 0,
        false)(dispatch, getState);
    }

    await debouncedDispatchCalculationAttempt(dispatch, getState);
  }
);

const getNextAirCavityLayer = (layer: ConstructionLayer, store: StoreModel): ConstructionLayer | undefined => {
  const constructionLayers = store.constructionData.layers[layer.constructionTypeId];
  const airLayers = constructionLayers.filter(l => l.layerType === LayerType.AirCavity);
  if (airLayers.length === 1) { return airLayers[0]; }
  else if (airLayers.length > 1) {
    const insulationLayerIndex = constructionLayers.indexOf(layer);
    return constructionLayers.filter((l, i) => insulationLayerIndex < i && l.layerType === LayerType.AirCavity)[0];
  }
  return undefined;
};

export const setMaterial = (layer: ConstructionLayer, material: Material) => (
  async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {
    const defaultLinkedMaterial = getDefaultLinkedMaterial(material);
    if (defaultLinkedMaterial) {
      dispatch(createLinkedLayer({
        constructionLayerId: layer.constructionLayerId,
        thicknessMillimetres: defaultLinkedMaterial.defaultThicknessMillimetres,
        material: defaultLinkedMaterial,
      }));
    } else if (!material.isLinkedMaterial) {
      dispatch(removeLinkedLayer(layer.constructionLayerId));
    }

    dispatch(setMaterialInternal(material, layer.constructionLayerId, material.isLinkedMaterial));

    switch (layer.layerType) {
      case LayerType.Anchors:
        const calculationLayer = getState().currentCalculationData.calculationLayers[layer.constructionLayerId.toString()];

        // We do an optional dispatch intentionally,
        // so that we keep the currently selected values for the anchor layer's parameters wherever possible.
        // The requirement is that "Anchors & Fastening should not change amount, diameter or penetration when selecting different anchor materials"
        const optionalDispatch = (currentValue: number | undefined, options: (number | undefined)[] | undefined, action: CurrentCalculationDataActionTypes) => {
          if ((options || []).indexOf(currentValue) === -1) {
            dispatch(action);
          }
        };

        optionalDispatch(
          calculationLayer?.numberOfAnchorsPerMetreSquare,
          material.optionsForNumberOfAnchorsPerMetreSquare,
          setAnchorAmountPerSquareMetre(material.defaultNumberOfAnchorsPerMetreSquare, layer.constructionLayerId, false));

        optionalDispatch(
          calculationLayer?.diameterOfAnchorsMillimetres,
          material.optionsForDiameterOfAnchorsMillimetres,
          setAnchorDiameter(material.defaultDiameterOfAnchorsMillimetres, layer.constructionLayerId, false));

        optionalDispatch(
          calculationLayer?.anchorPenetrationDepthMillimetres,
          material.optionsForAnchorPenetrationMillimetres,
          setAnchorPenetration(material.defaultAnchorPenetrationMillimetres, layer.constructionLayerId, false));

        break;
      default:
        if (layer.layerType === LayerType.AirCavity && layer.isWithinBridgingFrame) {
          // This is the calulated layer for a complex construction type and doesn't need its thickness setting
          break;
        }
        setMaterialParameter(
          setThickness,
          layer,
          material.defaultThicknessMillimetres,
          material.isLinkedMaterial)(dispatch, getState);

        if (layer.layerType === LayerType.Insulation && material.defaultAirCavityGradingId && !material.isLinkedMaterial) {
          const nextAirLayer = getNextAirCavityLayer(layer, getState());
          const defaultGrading = nextAirLayer?.airCavityGradings.find(ac => ac.airCavityGradingId === material.defaultAirCavityGradingId);
          if (nextAirLayer && defaultGrading) {
            setAirCavityGrading(nextAirLayer, defaultGrading)(dispatch, getState);
          }
        }

        break;
    }

    await debouncedDispatchCalculationAttempt(dispatch, getState);
  }
);

export const setMaterialParameter = (action: Function, layer: ConstructionLayer, value: number, isLinkedLayer: boolean) => (

  async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {
    const store = getState();
    const constructionType = activeConstructionTypeSelector(store);

    switch (constructionType?.complexity) {
      case Complexity.Simple:
        dispatch(action(value, layer.constructionLayerId, isLinkedLayer));
        break;
      case Complexity.Complex:
        dispatch(action(value, layer.constructionLayerId, isLinkedLayer));
        const layers = store.constructionData.layers[store.componentState.activeConstructionTypeId];
        if ((layer.layerType === LayerType.Bridging || layer.layerType === LayerType.Insulation) &&
          layers.some(l => l.layerType === LayerType.AirCavity && l.isWithinBridgingFrame)) {
          dispatch(setCalculatedLayerValue(
            layers.filter(l => l.layerType === LayerType.Bridging)[0],
            layers.filter(l => l.layerType === LayerType.Insulation && l.isWithinBridgingFrame)[0],
            layers.filter(l => l.layerType === LayerType.AirCavity && l.isWithinBridgingFrame)[0],
          ));
        }
        break;
    }
    await debouncedDispatchCalculationAttempt(dispatch, getState);
  }
);

export const dispatchCalculationAttempt = () => dispatchCalculationAttemptInternal;

const debouncedDispatchCalculationAttempt = debounce(async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {
  await dispatchCalculationAttemptInternal(dispatch, getState);
}, 250);

const dispatchCalculationAttemptInternal = async (dispatch: (action: CurrentCalculationDataActionTypes) => void, getState: () => StoreModel) => {

  const calculation = buildCalculationData(getState);

  if (!calculation || !calculation.layers || calculation.layers.length === 0) {
    // Do not dispatch the calculation if we're in a state where we have no layers yet, and therefore we're unable to build a calculation model.
    return;
  }

  dispatch(fetchCalculationResultsAttempt());

  try {
    const { authenticationState } = getState();

    const { data } = await axios.post('/api/calculation', calculation, {
      withCredentials: authenticationState.state === AuthenticationState.Authenticated
    }
    );

    dispatch(fetchCalculationResultsSuccess(mapApiResponseToCalculcation(data)));
  } catch (error) {
    // Only dispatch the failure if it is not a client error.
    // Note that we respond with 409 when simultaneous requests for the same calculation cause a concurrency error, when only one of the requests wins and the rest are essentially rejected.
    const isClientError = error?.response?.status && !isNaN(error.response.status) && error.response.status >= 400 && error.response.status < 500;
    if (isClientError) {
      dispatch(fetchCalculationResultsClientError());
    } else {
      dispatch(fetchCalculationResultsFailure(error?.message));
    }
  }
};

const buildCalculationData = (getState: () => StoreModel) => {
  const store = getState();
  const selectedCulture = selectedCultureSelector(store);
  const constructionType = activeConstructionTypeSelector(store);
  const constructionTypeLayers = store.constructionData.layers[constructionType?.id ?? -1];
  if (!constructionTypeLayers || constructionTypeLayers.length === 0) {
    return null;
  }
  const pseudoLayers = constructionTypeLayers.filter(cl => cl.isPseudoLayer);
  let layers: { [key: string]: CalculationLayer } = store.currentCalculationData.calculationLayers;
  let woodPercentage: number = 0;

  if (constructionType?.complexity === Complexity.Complex && pseudoLayers.length > 0) {
    const woodPercentageLayerId = pseudoLayers.filter(cl => cl.layerType === LayerType.WoodPercentage)[0].constructionLayerId;
    //extract pseudo layer
    layers = Object.entries(store.currentCalculationData.calculationLayers).reduce(
      (obj, kvp: [string, CalculationLayer]) => {
        const [key, value] = kvp;
        if (pseudoLayers.some(cl => cl.constructionLayerId === value.constructionLayerId)) {
          if (value.constructionLayerId === woodPercentageLayerId) {
            woodPercentage = value.thicknessMillimetres;
          }
          return obj;
        }
        return {
          ...obj,
          [key]: value
        };
      }, {}) as { [key: string]: CalculationLayer };
  }

  const projectDetails = projectDetailsSelector(store);
  const additionalParameters = additionalParametersSelector(store);

  const calculation = {
    calculationId: store.currentCalculationData.calculationId,
    calculationAccessCode: store.currentCalculationData.calculationAccessCode,
    constructionTypeId: store.componentState.activeConstructionTypeId,
    countryId: store.componentState.selectedCountry?.countryId ?? '',
    woodPercentage: woodPercentage / 100,
    layers: Object.entries(layers).map((kvp) => {
      const [, value] = kvp;
      return {
        constructionLayerId: value.constructionLayerId,
        materialId: value.material?.materialId,
        airCavityGradingId: value.airCavityGrading?.airCavityGradingId,
        thicknessMillimetres: value.thicknessMillimetres,
        numberOfAnchorsPerMetreSquare: value.numberOfAnchorsPerMetreSquare,
        diameterOfAnchorsMillimetres: value.diameterOfAnchorsMillimetres,
        anchorPenetrationDepthMillimetres: value.anchorPenetrationDepthMillimetres,
        netAreaOfAnchorsPerMetreSquareMillimetresSqrd: value.netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
      };
    }),
    clientTimestamp: new Date().getTime(),
    cultureOfUser: selectedCulture,
    projectDetails: {
      name: projectDetails?.name,
      siteArea: projectDetails?.siteArea,
      startDate: projectDetails?.startDate ? formatDate(projectDetails?.startDate) : '',
      county: projectDetails?.county,
      size: projectDetails?.size,
      type: projectDetails?.type,
      ribaStatus: projectDetails?.ribaStatus,
      buildingUse: projectDetails?.buildingUse,
      postcode: projectDetails?.postcode
    },
    additionalParameters: {
      areaMetresSquared: additionalParameters?.areaMetresSquared,
      perimeterMetres: additionalParameters?.perimeterMetres
    }
  };

  return calculation;
};

const formatDate = (date: Date | undefined): string => {
  if (date === undefined) {
    return '';
  }
  return `${date.getFullYear()}-${appendLeadingZero(date.getMonth() + 1)}-${appendLeadingZero(date.getDate())}T00:00:00.000Z`;
};

const appendLeadingZero = (n: number): string => n <= 9 ? '0' + n : n.toString();
