import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItemText,
  ListItem,
  Box,
  DialogTitle,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
  Typography,
  Grid,
  ListItemIcon,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trans, useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import AzureAD, { IAzureADFunctionProps } from 'react-aad-msal';
import authProviderFactory from '../../authentication/authProvider';
import { useDispatch, useStore } from 'react-redux';
import { setIsConfirmedGuest } from '../../store/account/account.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullWidthButton: {
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  })
);

export interface SignInDialogProps {
  open: boolean;
  handleClose: (cont: boolean) => void;
}

export default function SignInDialog(props: SignInDialogProps) {

  const { open, handleClose } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const store = useStore();
  const { t } = useTranslation();

  const reasons = [
    t('sign-in-dialog.reason-1'),
    t('sign-in-dialog.reason-2'),
    t('sign-in-dialog.reason-3'),
  ];

  const closeWindow = (cont: boolean, isConfirmedGuest: boolean) => {
    dispatch(setIsConfirmedGuest(isConfirmedGuest));
    handleClose(cont);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => closeWindow(false, false)}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle disableTypography>
        <Box marginRight={4}>
          <Typography variant="h4">
            <Trans i18nKey="sign-in-dialog.title">Make your experience even better</Trans>
          </Typography>
        </Box>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => closeWindow(false, false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>

        <Box>
          <Trans i18nKey="sign-in-dialog.sub-title">Creating an account is easy and free.</Trans>
        </Box>

        <List>
          {reasons.map((reason, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={reason}></ListItemText>
            </ListItem>
          ))}
        </List>

      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          <Grid item xs={12} sm={'auto'}>
            <AzureAD provider={authProviderFactory()} reduxStore={store}>
              {({ login }: IAzureADFunctionProps) => {
                return (
                  <Button variant="contained" color="primary" className={isMobileView ? classes.fullWidthButton : ''} onClick={() => { closeWindow(true, false); login(); }}>
                    <Trans i18nKey="sign-in-dialog.signup-signin">
                      Sign In
                    </Trans>
                  </Button>
                );
              }}
            </AzureAD>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Button onClick={() => closeWindow(true, true)} className={isMobileView ? classes.fullWidthButton : ''} color="primary" data-qa-id="guest-btn">
              <Trans i18nKey="sign-in-dialog.guest-button">
                Continue as guest
              </Trans>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}


