import React from 'react';
import { ConstructionLayer, LayerType } from '../../types/domain/construction-data.types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { insertSuggestedHyphenationIfRequired } from './hyphenSuggestion';

export type LayerNameProps = {
  layer: ConstructionLayer | null | undefined;
  insertSuggestedHyphenationIfRequired?: boolean | null | undefined;
}

const getText = (props: LayerNameProps, t: TFunction) => {
  const text = props.layer?.layerType === LayerType.WoodPercentage
    ? t('calculation-ui.wood-percentage')
    : props.layer?.nameLocalized;

  return props.insertSuggestedHyphenationIfRequired === true
    ? insertSuggestedHyphenationIfRequired(text ?? '')
    : text;
};

export default function LayerName(props: LayerNameProps) {
  const { t } = useTranslation();

  return <span>{getText(props, t)}</span>;
}
