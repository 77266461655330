import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import styles from './ChooseCountry.module.scss';
import kingspanLogo from '../../assets/images/kingspan-logo.svg';
import { Link } from 'react-router-dom';
import { countriesSelector } from '../../store/component-state/component-state.selectors';
import FooterLinks from '../Footer/FooterLinks';

export default function ChooseCountry() {
  const countries = useSelector(countriesSelector);

  return (
    <div>
      <Grid container direction="column" alignItems="center">
        <img src={kingspanLogo} alt="Kingspan Logo" />
        <h1 className={styles.title}>
          <Trans i18nKey="choose-country.title">U-value Calculator</Trans>
        </h1>
        <div className={styles.prompt}>
          {countries.length === 0 ? (
            <Trans i18nKey="choose-country.maintenance-text">Sorry, we're down for scheduled maintenance right now. We'll be up and running again soon.</Trans>
          ) : (
            <Trans i18nKey="choose-country.prompt">Welcome to the Kingspan Insulation U-value Calculator. Please start your calculation below by selecting a country.</Trans>
          )}
        </div>
      </Grid>

      <ul className={styles.countryList}>
        {countries.map(c => (
          <li key={c.countryId}>
            <Link to={`/${c.countryId.toLowerCase()}`} className={c.localizedName.length > 20 ? styles.longName : ''}>
              {c.localizedName}
            </Link>
          </li>
        ))}
      </ul>

      <Grid container direction="column" className={styles.footer}>
        <div className={styles.disclaimer}>
          <Trans i18nKey="choose-country.disclaimer">
            Whilst the information and/or specification contained here is to the best of our knowledge true and accurate we specifically exclude any liability for errors, omissions or otherwise arising therefrom. Details, practices,
            principles, values and calculations should be verified as to accuracy and suitability for the required purpose for use.
          </Trans>
        </div>
        <FooterLinks />
      </Grid>
    </div>
  );
}
