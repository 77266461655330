import { NewsItem } from '../../types/domain/news-item.types';

export const FETCH_NEWS_ITEM_ATTEMPT = '[News Items] Fetch News Item Attempt';
export const FETCH_NEWS_ITEM_SUCCESS = '[News Items] Fetch News Item Success';
export const FETCH_NEWS_ITEM_FAILURE = '[News Items] Fetch News Item Failure';
export const FETCH_NEWS_ITEM_SKIPPED = '[News Items] Fetch News Item Skipped';
export const CLEAR_NEWS_ITEM = '[News Items] Clear News Item';

export interface FetchNewsItemAttempt {
  type: typeof FETCH_NEWS_ITEM_ATTEMPT;
}

export interface FetchNewsItemSuccess {
  type: typeof FETCH_NEWS_ITEM_SUCCESS;
  payload: NewsItem;
}

export interface FetchNewsItemFailure {
  type: typeof FETCH_NEWS_ITEM_FAILURE;
  error: string | undefined | null;
}

export interface FetchNewsItemSkipped {
  type: typeof FETCH_NEWS_ITEM_SKIPPED;
}

export interface ClearNewsItem {
  type: typeof CLEAR_NEWS_ITEM;
}

export type NewsItemActionTypes =
  | FetchNewsItemAttempt
  | FetchNewsItemSuccess
  | FetchNewsItemFailure
  | FetchNewsItemSkipped
  | ClearNewsItem;
