import { Material, AirCavityGrading } from './construction-data.types';

export type CalculationLayer = {
  constructionLayerId: number;
  thicknessMillimetres: number;
  numberOfAnchorsPerMetreSquare?: number;
  diameterOfAnchorsMillimetres?: number;
  anchorPenetrationDepthMillimetres?: number;
  netAreaOfAnchorsPerMetreSquareMillimetresSqrd?: number;
  material?: Material;
  airCavityGrading?: AirCavityGrading;
}

export type CalculationResult = {
  calculationLayers: CalculationLayerInfo[];
  resultType: CalculationResultType;
  resultUnits: string;
  resultValue: number | null;
  failureMessage: string | null;
  totalThicknessMillimetres: number;
  thicknessComparison?: ThicknessComparisonResult | null;
  isBengCompliant: boolean | null;
}

export type ThicknessComparisonResult = {
  sourceMaterialId: string;
  sourceMaterialName: string;
  sourceTotalConstructionThicknessMillimetres: number;
  sourceLayerRValue: number;
  comparisonMaterialId: string;
  comparisonMaterialName: string;
  comparisonTotalConstructionThicknessMillimetres: number;
  comparisonLayerRValue: number;
  totalConstructionThicknessSavingPercentage: number;
  layerThicknessSavingPercentage: number;
}

export type ProjectDetails = {
  name: string;
  siteArea: string;
  startDate: Date;
  county: string;
  size: string;
  type: string;
  ribaStatus: string;
  buildingUse: string;
  postcode: string;
}

export type AdditionalParameters = {
  perimeterMetres?: number;
  areaMetresSquared?: number;
}

export type CalculationLayerInfo = {
  layerName: string;
  materialId?: string | null;
  materialName: string;
  specificationPageUrl?: string | null;
}

export enum CalculationResultType {
  RValue = 'RValue',
  UValue = 'UValue',
}

export type Calculation = {
  calculationId: string;
  calculationAccessCode: string;
  calculationLayers: { [key: string]: CalculationLayer };
  calculationResult: CalculationResult;
  projectDetails: ProjectDetails;
  additionalParameters: AdditionalParameters;
  createdOn: Date;
  updatedOn: Date;
  constructionTypeId: string;
  constructionTypeNameLocalized: string;
  countryId: string;
  woodPercentage: number | null;
};
