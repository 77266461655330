import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, CircularProgress, createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, useMediaQuery,FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getAppConfig } from '../../config/config';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { activeConstructionTypeIdSelector, activeConstructionTypeSelector, countiesSelector, countryIdSelector, projectConfigSelector, selectedCultureSelector,fieldDataSelector } from '../../store/component-state/component-state.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { setAdditionalParameters, setProjectDetails } from '../../store/current-calculation-data/current-calculation-data.actions';
import { additionalParametersSelector, projectDetailsSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { ConstructionTypeAdditionalParameters } from '../../types/domain/construction-data.types';
import NewsItemBanner from '../NewsItem/NewsItemBanner';
import { PerimeterAreaConstants } from './PerimeterAndArea/perimeter-area.types';

import PerimeterAndArea from './PerimeterAndArea/PerimeterAndArea';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.down('xs')]: {
      formControl: {
        width: '100%',
      }
    },
    [theme.breakpoints.up('sm')]: {
      formControl: {
        width: 320,
      }
    },
    [theme.breakpoints.up('md')]: {
      formControl: {
        width: 380,
      }
    },
  }),
);

export type CalculationProjectDetailsProps = {
  handleContinue: () => void;
}

export default function CalculationProjectDetails(props: CalculationProjectDetailsProps) {

  const { handleContinue } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const apiUrl = getAppConfig()?.apiUrl;

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const constructionTypeId = useSelector(activeConstructionTypeIdSelector);
  const activeConstructionType = useSelector(activeConstructionTypeSelector);

  const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));
  const countryId = useSelector(countryIdSelector);
  const language = useSelector(selectedCultureSelector);

  const projectDetails = useSelector(projectDetailsSelector);
  const projectConfig = useSelector(projectConfigSelector);
  const counties = useSelector(countiesSelector);
  const fieldData = useSelector(fieldDataSelector);

  const [projectName, setProjectName] = useState({ value: '', error: '' });
  const [startDate, setStartDate] = useState<{ value: Date | null, error: string }>({ value: null, error: '' });
  const [area, setArea] = useState<number>(PerimeterAreaConstants.defaultArea);
  const [perimeter, setPerimeter] = useState<number>(PerimeterAreaConstants.defaultPerimeter);
  const additionalParameters = useSelector(additionalParametersSelector);

  const countiesFiltered = counties.filter((county) =>{
      return county.countryId === projectConfig?.country;            
  });

  const [countyName, setCountyName] = useState({value: '', error:''});
  
  const sizes = fieldData.filter((fieldData) => {
    return fieldData.fieldName === 'ProjectSize';
  });

  const types = fieldData.filter((fieldData) => {
    return fieldData.fieldName === 'ProjectType';
  });

  const statuses = fieldData.filter((fieldData) => {
    return fieldData.fieldName === 'RIBAStatus';
  });

  const uses = fieldData.filter((fieldData) => {
    return fieldData.fieldName === 'BuildingUse';
  }); 

  const siteAreas = fieldData.filter((fieldData) => {
    if(projectConfig?.fieldNames?.includes('projectsitearea')){
      return fieldData.fieldName === 'ProjectSiteArea';
    }
    else{
      return fieldData.fieldName === 'SiteArea';
    }
  });

  const [projectSize, setProjectSize] = useState({value: '' , error:''}); 
  const [projectType, setProjectType] = useState({value: '' , error:''});
  const [projectRibaStatus, setRIBAStatus] = useState({value:'', error:''});
  const [projectBuidingUse, setBuildingUse] = useState({value:'', error:''});
  const [projectPostcode, setPostcode] = useState({value:'', error:''});
  
  const [siteArea, setSiteArea] = useState({ value: '', error: '' });

 
  // Initialise values if they already exist in the store
  useEffect(() => {
    if (projectDetails !== undefined) {
      setProjectName({ value: projectDetails.name, error: '' });
      setStartDate({ value: projectDetails.startDate, error: '' });
    
      if(projectConfig?.fieldNames?.includes('projectsitearea') || projectConfig?.fieldNames?.includes('sitearea')){
        setSiteArea({ value: projectDetails.siteArea, error: '' });
      }
      else{
        setSiteArea({ value: projectDetails.siteArea, error: '' });
      }

      if(projectConfig?.fieldNames?.includes('projectcounty')){
        setCountyName({ value: projectDetails.county, error: '' });
      }
      else{
        setCountyName({ value: '', error: '' });
      }

       if(projectConfig?.fieldNames?.includes('projectsize')){
        setProjectSize({ value: projectDetails.size, error: '' });
      }
      else{
        setProjectSize({ value: '', error: '' });
      }

      if(projectConfig?.fieldNames?.includes('projecttype')){
        setProjectType({ value: projectDetails.type, error: '' });
      }
      else{
        setProjectType({ value: '', error: '' });
      }

      if(projectConfig?.fieldNames?.includes('ribastatus')){
        setRIBAStatus({ value: projectDetails.ribaStatus, error: '' });
      }
      else{
        setRIBAStatus({ value: '', error: '' });
      }

      if(projectConfig?.fieldNames?.includes('buildinguse')){
        setBuildingUse({ value: projectDetails.buildingUse, error: '' });
      }
      else{
        setBuildingUse({ value: '', error: '' });
      }

      if(projectConfig?.fieldNames?.includes('postcode')){
        setPostcode({ value: projectDetails.postcode, error: '' });
      }
      else{
        setPostcode({ value: '', error: '' });
      }
    }
  }, [projectDetails, projectConfig]);

  useEffect(() => {
    if (additionalParameters !== undefined) {
      setArea(additionalParameters.areaMetresSquared ?? PerimeterAreaConstants.defaultArea);
      setPerimeter(additionalParameters.perimeterMetres ?? PerimeterAreaConstants.defaultPerimeter);
    }
  }, [additionalParameters]);

  const onProjectNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectName({
      value: event.target.value as string,
      error: '',
    });
  };

  const onSiteAreaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSiteArea({
      value: event.target.value as string,
      error: '',
    });
  };

  const onStartDateChange = (date: Date | null) => {
    setStartDate({
      value: date,
      error: '',
    });
  };

  const onCountyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountyName({
      value: event.target.value as string,
      error: '',
    });
  };

  const onProjectSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectSize({
      value: event.target.value as string,
      error: '',
    });
  };

  const onProjectTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectType({
      value: event.target.value as string,
      error: '',
    });
  };

  const onRIBAStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRIBAStatus({
      value: event.target.value as string,
      error: '',
    });
  };

  const onBuildingUseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBuildingUse({
      value: event.target.value as string,
      error: '',
    });
  };

  const onPostcodeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setPostcode({
      value: event.target.value as string,
      error: '',
    });
  };


  const validate = (): boolean => {

    let isValid = true;

    if (projectName.value === '') {
      isValid = false;
      setProjectName({
        ...projectName,
        error: t('project-details-ui.project-name.error-empty'),
      });
    }

    if (projectName.value.length > 150) {
      isValid = false;
      setProjectName({
        ...projectName,
        error: t('project-details-ui.project-name.error-too-long'),
      });
    }

    if (startDate.value === null) {
      isValid = false;
      setStartDate({
        ...startDate,
        error: t('project-details-ui.start-date.error-empty'),
      });
    }

    if (projectPostcode.value.length > 150) {
      isValid = false;
      setPostcode({
        ...projectPostcode,
        error: t('project-details-ui.postcode.error-too-long'),
      });
    }   
    
    if (projectConfig?.fieldNames?.includes('projectcounty')) {
      if(countyName.value === ''){
        isValid = false;
        setCountyName({
          ...countyName,
          error: t('project-details-ui.county.error-empty'),
        });
      }      
    }  
    
    if (projectConfig?.fieldNames?.includes('projectsitearea')) {
      if(siteArea.value === ''){
        isValid = false;
        setSiteArea({
          ...siteArea,
          error: t('project-details-ui.site-area.error-empty'),
        });
      }      
    }   

    if (projectConfig?.fieldNames?.includes('projectsize')) {
      if(projectSize.value === ''){
        isValid = false;
        setProjectSize({
          ...projectSize,
          error: t('project-details-ui.size.error-empty'),
        });
      }      
    }   

    if (projectConfig?.fieldNames?.includes('projecttype')) {
      if(projectType.value === ''){
        isValid = false;
        setProjectType({
          ...projectType,
          error: t('project-details-ui.type.error-empty'),
        });
      }      
    }
    
    if (projectConfig?.fieldNames?.includes('ribastatus')) {
      if(projectRibaStatus.value === ''){
        isValid = false;
        setRIBAStatus({
          ...projectRibaStatus,
          error: t('project-details-ui.ribastatus.error-empty'),
        });
      }      
    }   

    if (projectConfig?.fieldNames?.includes('buildinguse')) {
      if(projectBuidingUse.value === ''){
        isValid = false;
        setBuildingUse({
          ...projectBuidingUse,
          error: t('project-details-ui.buildinguse.error-empty'),
        });
      }      
    }   

    return isValid;
  };

  const onContinueClick = () => {
    const isValid = validate();
    if (isValid) {
      dispatch(setProjectDetails({
        name: projectName.value,
        siteArea: siteArea.value,
        startDate: startDate.value!,
        county: countyName.value!,
        size: projectSize.value!,
        type: projectType.value!,
        ribaStatus: projectRibaStatus.value!,
        buildingUse: projectBuidingUse.value!,
        postcode: projectPostcode.value!
      }));
      dispatch(setAdditionalParameters({
        areaMetresSquared: area,
        perimeterMetres: perimeter
      }));
      handleContinue();
    }
  };

  const getLocale = (localeCode: string): Locale => {
    const locales = (Locales as any);
    return locales[localeCode.replace('-', '')] ??
      locales[localeCode.substring(0, 2)] ??
      locales['enGB'];
  };

  return (
    <Box>
      <NewsItemBanner countryId={countryId} constructionType={constructionTypeId} language={language} />

      <Box mx={2}>
        <h1>{constructionTypeName}</h1>
        <h2><Trans i18nKey="project-details-ui.sub-title">Project details</Trans></h2>
      </Box>
      <Grid container direction={isMobileView ? 'column-reverse' : 'row'}>
        <Grid item container xs={12} sm={6}>
          <Grid item xs={12}>
            <Box mx={2} my={4}>
              <TextField id="project-name" label={t('project-details-ui.project-name.label')} variant="outlined" className={classes.formControl}
                value={projectName.value} onChange={onProjectNameChange}
                error={!isEmpty(projectName.error)} helperText={projectName.error} />
            </Box>            

            <Box mx={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(language)}>
                <KeyboardDatePicker
                  disableToolbar variant="inline" format="dd/MM/yyyy" inputVariant="outlined"
                  margin="normal" id="start-date-picker" label={t('project-details-ui.start-date.label')}
                  className={classes.formControl}
                  value={startDate.value} onChange={onStartDateChange}
                  error={!isEmpty(startDate.error)} helperText={startDate.error}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>  

          {
            projectConfig?.fieldNames?.includes('postcode') &&
            <Box mx={2} my={4}>
            <TextField id="postcode" label={t('project-details-ui.postcode.label')} variant="outlined" className={classes.formControl}
              value={projectPostcode.value} onChange={onPostcodeChange}
              error={!isEmpty(projectPostcode.error)} helperText={projectPostcode.error} />
            </Box>
          }   

          {
            (projectConfig?.fieldNames?.includes('projectsitearea') || projectConfig?.fieldNames?.includes('sitearea')) &&
            <Box mx={2} my={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="site-area-select-label" error={!isEmpty(siteArea.error)}>{t('project-details-ui.site-area.label')}</InputLabel>
                <Select
                  labelId="site-area-select-label"
                  id="site-area-select-helper"
                  label={t('project-details-ui.site-area.label')}
                  value={siteArea.value}
                  onChange={onSiteAreaChange}
                  error={!isEmpty(siteArea.error)}                  
                  
                >
                  {siteAreas.map(siteArea => (
                    <MenuItem key={siteArea.id} value={siteArea.code}>
                      <Trans i18nKey={`project-details-ui.site-area.${siteArea.code}`}> {siteArea.code} </Trans>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!isEmpty(siteArea.error)}>{siteArea.error}</FormHelperText>
              </FormControl>
            </Box>
          }          

          {
            projectConfig?.fieldNames?.includes('projectcounty') &&
            <Box mx={2} my={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="county-select-label" error={!isEmpty(countyName.error)}>{t('project-details-ui.county.label')}</InputLabel>
              <Select
                labelId="county-select-label"
                id="county-select-helper"
                label={t('project-details-ui.county.label')}
                value={countyName.value}
                onChange={onCountyChange}
                error={!isEmpty(countyName.error)}              
              >
                {countiesFiltered.map(county => (
                  <MenuItem key={county.id} value={county.code}>
                    <Trans i18nKey={`project-details-ui.county.${county}`}> {county.code} </Trans>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!isEmpty(countyName.error)}>{countyName.error}</FormHelperText>
            </FormControl>
          </Box>
          }

          {
            projectConfig?.fieldNames?.includes('projectsize') && 
            <Box mx={2} my={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="size-select-label" error={!isEmpty(projectSize.error)}>{t('project-details-ui.size.label')}</InputLabel>
                <Select
                  labelId="size-select-label"
                  id="size-select-helper"
                  label={t('project-details-ui.size.label')}
                  value={projectSize.value}
                  onChange={onProjectSizeChange}
                  error={!isEmpty(projectSize.error)}
                >
                  {sizes.map(size => (
                    <MenuItem key={size.id} value={size.code}>
                      <Trans i18nKey={`project-details-ui.size.${size.code}`}> {size.code} </Trans>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!isEmpty(projectSize.error)}>{projectSize.error}</FormHelperText>
              </FormControl>
            </Box>
          }

          {
            projectConfig?.fieldNames?.includes('projecttype') && 
            <Box mx={2} my={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="type-select-label" error={!isEmpty(projectType.error)}>{t('project-details-ui.type.label')}</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select-helper"
                  label={t('project-details-ui.type.label')}
                  value={projectType.value}
                  onChange={onProjectTypeChange}
                  error={!isEmpty(projectType.error)}
                >
                  {types.map(type => (
                    <MenuItem key={type.id} value={type.code}>
                      <Trans i18nKey={`project-details-ui.type.${type.code}`}> {type.code} </Trans>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!isEmpty(projectType.error)}>{projectType.error}</FormHelperText>
              </FormControl>
            </Box>
          }

          {
            projectConfig?.fieldNames?.includes('ribastatus') && 
            <Box mx={2} my={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ribastatus-select-label" error={!isEmpty(projectRibaStatus.error)}>{t('project-details-ui.ribastatus.label')}</InputLabel>
                <Select
                  labelId="ribastatus-select-label"
                  id="ribastatus-select-helper"
                  label={t('project-details-ui.ribastatus.label')}
                  value={projectRibaStatus.value}
                  onChange={onRIBAStatusChange}
                  error={!isEmpty(projectRibaStatus.error)}
                >
                  {statuses.map(status => (
                    <MenuItem key={status.id} value={status.code}>
                      <Trans i18nKey={`project-details-ui.ribastatus.${status.code}`}> {status.code} </Trans>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!isEmpty(projectRibaStatus.error)}>{projectRibaStatus.error}</FormHelperText>
              </FormControl>
            </Box>
          }

          {
            projectConfig?.fieldNames?.includes('buildinguse') && 
            <Box mx={2} my={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="buildinguse-select-label" error={!isEmpty(projectBuidingUse.error)}>{t('project-details-ui.buildinguse.label')}</InputLabel>
                <Select
                  labelId="buildinguse-select-label"
                  id="buildinguse-select-helper"
                  label={t('project-details-ui.buildinguse.label')}
                  value={projectBuidingUse.value}
                  onChange={onBuildingUseChange}
                  error={!isEmpty(projectBuidingUse.error)}
                >
                  {uses.map(use => (
                    <MenuItem key={use.id} value={use.code}>
                      <Trans i18nKey={`project-details-ui.buildinguse.${use.code}`}> {use.code} </Trans>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!isEmpty(projectBuidingUse.error)}>{projectBuidingUse.error}</FormHelperText>
              </FormControl>
            </Box>
          }

          {
            activeConstructionType?.additionalParameters &&
            <Grid item xs={12}>
              <Box mx={2} my={2}>
                {
                  activeConstructionType.additionalParameters.some(a => a === ConstructionTypeAdditionalParameters.PerimeterAndArea) &&
                  <PerimeterAndArea perimeter={perimeter} setPerimeter={setPerimeter} area={area} setArea={setArea} />
                }
              </Box>
            </Grid>
          }

          <Grid item xs={12}>
            <Box mx={2} marginTop={2}>
              <Button variant="contained" color="primary" onClick={onContinueClick} className={classes.formControl} data-qa-id="continueBtn">
                <Trans i18nKey="project-details-ui.continue-button">Continue</Trans>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mx={2} marginTop={2} marginBottom={4}>
              <RouterLink to={buildRouteWithCountry(countryId, '/')}>
                <Button color="primary" onClick={onContinueClick} className={classes.formControl}>
                  <Trans i18nKey="project-details-ui.back-button">Back</Trans>
                </Button>
              </RouterLink>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          {isEmpty(constructionTypeId)
            ? <CircularProgress />
            : <img
              data-qa-id="construction-type-image"
              style={{ maxWidth: '100%' }}
              src={`${apiUrl}/content/build-up-images/${countryId.toUpperCase()}/${countryId.toUpperCase()}-${constructionTypeId}.jpg`}
              alt={constructionTypeName} />
          }
        </Grid>
      </Grid>
    </Box>
  );
}


