import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentAnchorPropertiesSelector, currentSelectedMaterialSelector, previousInsulationCalculationLayerSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionLayer, Material } from '../../types/domain/construction-data.types';
import ThicknessSlider from './ThicknessSlider';
import { setAnchorAmountPerSquareMetre, setAnchorDiameter, setAnchorPenetration } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { useTranslation } from 'react-i18next';
import { CalculationLayer } from '../../types/domain/calculation-data.types';

export default function AnchorParameters() {
  const dispatch = useDispatch();
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const { numberOfAnchorsPerMetreSquare, diameterOfAnchorsMillimetres, anchorPenetrationDepthMillimetres } = useSelector(currentAnchorPropertiesSelector);
  const currentSelectedMatrial: Material | undefined = useSelector(currentSelectedMaterialSelector(false));
  const previousInsulationLayer: CalculationLayer | undefined = useSelector(previousInsulationCalculationLayerSelector);

  const [optionsForAnchorPenetrationMillimetres, setOptionsForAnchorPenetrationMillimetres] = React.useState([] as number[]);

  const handleAmountChange = (value: number) => {
    dispatch(setMaterialParameter(setAnchorAmountPerSquareMetre, layer, value, false));
  };

  const handleDiameterChange = (value: number) => {
    dispatch(setMaterialParameter(setAnchorDiameter, layer, value, false));
  };

  const handlePenetrationChange = (value: number) => {
    dispatch(setMaterialParameter(setAnchorPenetration, layer, value, false));
  };

  const { t } = useTranslation();
  React.useEffect(() => {
    if (currentSelectedMatrial && currentSelectedMatrial.optionsForAnchorPenetrationMillimetres && previousInsulationLayer) {
      setOptionsForAnchorPenetrationMillimetres(currentSelectedMatrial.optionsForAnchorPenetrationMillimetres.filter(o => o <= previousInsulationLayer.thicknessMillimetres));
    }
  }, [setOptionsForAnchorPenetrationMillimetres, currentSelectedMatrial, previousInsulationLayer]);

  const defaultMarks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      <ThicknessSlider
        labelKey='amount-label'
        handleChange={handleAmountChange}
        selectedThickness={numberOfAnchorsPerMetreSquare}
        disabled={!currentSelectedMatrial}
        marks={currentSelectedMatrial?.optionsForNumberOfAnchorsPerMetreSquare ?? defaultMarks}
        min={currentSelectedMatrial?.optionsForNumberOfAnchorsPerMetreSquare[0] ?? 0}
        max={currentSelectedMatrial?.optionsForNumberOfAnchorsPerMetreSquare[currentSelectedMatrial.optionsForNumberOfAnchorsPerMetreSquare.length - 1] ?? 10}
        defaultValue={currentSelectedMatrial?.defaultNumberOfAnchorsPerMetreSquare ?? 0}
        units={`${t('calculation-ui.anchor-per')} m²`}
        isValueFixed={false} />

      <ThicknessSlider
        labelKey='diameter-label'
        handleChange={handleDiameterChange}
        selectedThickness={diameterOfAnchorsMillimetres}
        disabled={!currentSelectedMatrial}
        marks={currentSelectedMatrial?.optionsForDiameterOfAnchorsMillimetres ?? defaultMarks}
        min={currentSelectedMatrial?.optionsForDiameterOfAnchorsMillimetres[0] ?? 0}
        max={currentSelectedMatrial?.optionsForDiameterOfAnchorsMillimetres[currentSelectedMatrial.optionsForDiameterOfAnchorsMillimetres.length - 1] ?? 10}
        defaultValue={currentSelectedMatrial?.defaultDiameterOfAnchorsMillimetres ?? 0}
        units='mm'
        isValueFixed={false} />

      {
        optionsForAnchorPenetrationMillimetres.length ?
          <ThicknessSlider
            labelKey='penetration-label'
            handleChange={handlePenetrationChange}
            selectedThickness={anchorPenetrationDepthMillimetres}
            disabled={!currentSelectedMatrial}
            marks={optionsForAnchorPenetrationMillimetres ?? defaultMarks}
            min={optionsForAnchorPenetrationMillimetres[0]}
            max={optionsForAnchorPenetrationMillimetres[optionsForAnchorPenetrationMillimetres.length - 1] ?? 100}
            defaultValue={currentSelectedMatrial?.defaultAnchorPenetrationMillimetres ?? 0}
            units='mm'
            isValueFixed={false} />
          : null}
    </div>
  );

}
