import React from 'react';
import styles from '../ChooseCountry/ChooseCountry.module.scss';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getLinks, LinkData } from '../../common/links';

declare const CookieControl: any;

export default function footerLinks() {
  const links = getLinks();
  const linkDatas: LinkData[] = [
    { link: links.cookiePolicy, i18nKey: 'choose-country.cookie-policy', defaultName: 'Cookie Policy & Control' },
    { link: links.privacyPolicy, i18nKey: 'choose-country.privacy-policy', defaultName: 'Website Privacy Policy' },
    { link: links.marketingConsent, i18nKey: 'choose-country.marketing-consent', defaultName: 'Direct Marketing Consent Notice' },
    { link: links.termsAndConditions, i18nKey: 'choose-country.terms-and-conditions', defaultName: 'Terms & Conditions of Website Use' },
  ];
  return (
    <>
      <div style={{ width: '100%', paddingBottom: '5px' }}>
        <div style={{ float: 'right' }}>&copy; Kingspan Insulation</div>
      </div>
      <div className={styles.footerLinks}>
        <Grid container spacing={2}>
          {linkDatas.map((linkData, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <a href={linkData.link} target="_blank" rel="noopener noreferrer  ">
                <Trans i18nKey={linkData.i18nKey}>{linkData.defaultName}</Trans>
              </a>
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <a href="#bottom" onClick={() => CookieControl.open()}>
              Cookie Settings
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
