import React from 'react';
import { Grid, Button, makeStyles, Theme, createStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { CalculationStep } from '../CalculationPage/calculation-page.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidthButton: {
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  })
);

export type CalculationWizardControlsProps = {
  activeStepIndex: number;
  steps: CalculationStep[];
  handleBackClick: () => void;
  handleNextClick: () => void;
};

export default function CalculationWizardControls(props: CalculationWizardControlsProps) {

  const { activeStepIndex, steps, handleBackClick, handleNextClick } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const isLastStep = () => activeStepIndex === steps.length - 1;

  return (
    <Grid container direction={isMobileView ? 'column-reverse' : undefined}>
      <Grid item xs="auto" sm="auto" md={1} />
      <Grid item xs={12} sm="auto">
        <Button onClick={handleBackClick} className={isMobileView ? classes.fullWidthButton : ''} data-qa-id="buttonOnTheLeft" startIcon={<KeyboardArrowLeftIcon />}>
          {activeStepIndex > 0 ? steps[activeStepIndex - 1]?.renderLabel() : t('calculation-ui.back-button')}
        </Button>
      </Grid>
      <Grid item xs/>
      <Grid item xs={12} sm="auto">
        <Button variant="contained"
                color="primary"
                className={isMobileView ? classes.fullWidthButton : ''}
                onClick={handleNextClick}
                data-qa-id="buttonOnTheRight"
                endIcon={!isLastStep() && <KeyboardArrowRightIcon />}>
          {steps[activeStepIndex + 1]?.renderLabel()}
        </Button>
      </Grid>
      <Grid item xs="auto" sm="auto" md={1} />
    </Grid>
  );
}
