import {
  SetActiveConstructionLayer,
  SET_ACTIVE_CONSTRUCTION_LAYER,

  SetActiveConstructionType,
  SET_ACTIVE_CONSTRUCTION_TYPE,

  SetCountries,
  SET_COUNTRIES,

  SetSelectedCountry,
  SET_SELECTED_COUNTRY,

  SetSelectedCulture,
  SET_SELECTED_CULTURE,

  SetGTMContainer,
  SET_GTM_CONTAINER,
  SetProjectConfig,
  SET_PROJECT_CONFIG,
  SetCounties,
  SET_COUNTIES,
  SetFieldData,
  SET_FIELD_DATA

}
from './component-state.action-types';
import { Country, ProjectConfig,County,FieldData } from '../../types/domain/construction-data.types';

export const setActiveConstructionType = (constructionType: string): SetActiveConstructionType => ({
  type: SET_ACTIVE_CONSTRUCTION_TYPE,
  payload: constructionType,
});

export const setActiveConstructionLayer = (constructionLayerId: number): SetActiveConstructionLayer => ({
  type: SET_ACTIVE_CONSTRUCTION_LAYER,
  payload: constructionLayerId,
});

export const setCountries = (countries: Country[] | null): SetCountries => ({
  type: SET_COUNTRIES,
  payload: countries,
});

export const setSelectedCountry = (country: Country | null): SetSelectedCountry => ({
  type: SET_SELECTED_COUNTRY,
  payload: country,
});

export const setSelectedCulture = (selectedCulture: string): SetSelectedCulture => ({
  type: SET_SELECTED_CULTURE,
  payload: selectedCulture,
});

export const setGTMContainer = (): SetGTMContainer => ({
  type: SET_GTM_CONTAINER
});

export const setProjectConfig = (config: ProjectConfig | null): SetProjectConfig => ({
  type: SET_PROJECT_CONFIG,
  payload: config
});

export const setCounties = (counties: County[] | null): SetCounties => ({
  type: SET_COUNTIES,
  payload: counties
});

export const setFieldData = (fieldData: FieldData[] | null) : SetFieldData => ({
  type: SET_FIELD_DATA,
  payload: fieldData
});

