import { StoreModel } from '../store.model';
import { Calculation } from '../../types/domain/calculation-data.types';
import compareDesc from 'date-fns/compareDesc';

export const calculationArraySelector = (store: StoreModel) : Calculation[] => 
    Object.values(store.calculations.calculations).sort((a, b) => compareDesc(a.updatedOn, b.updatedOn));

export const calculationSelector  = (calculationId: string) => (store: StoreModel) : Calculation | undefined =>
    store.calculations.calculations[calculationId];

export const deletingCalculationsSelector = (store: StoreModel): string[] => store.calculations.deletingCalculations;
