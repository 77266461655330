import * as actionTypes from './current-calculation-data.action-types';
import { CalculationDataModel } from '../store.model';
import { CalculationLayer } from '../../types/domain/calculation-data.types';

export const initialState: CalculationDataModel = {
  calculationLayers: {},
};

export const currentCalculationDataReducer = (
  state: CalculationDataModel = initialState,
  action: actionTypes.CurrentCalculationDataActionTypes,
): CalculationDataModel => {
  switch (action.type) {
    case actionTypes.SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload,
      };
    case actionTypes.SET_ADDITIONAL_PARAMETERS:
      return {
        ...state,
        additionalParameters: action.payload,
      };
    case actionTypes.SET_AIR_CAVITY_GRADING:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, false)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)],
            airCavityGrading: action.payload.airCavityGrading
          }
        }
      };
    case actionTypes.SET_MATERIAL:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)],
            material: action.payload.material
          }
        }
      };
    case actionTypes.SET_THICKNESS:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)],
            thicknessMillimetres: action.payload.thicknessMillimetres
          }
        }
      };
    case actionTypes.SET_ANCHOR_DIAMETER:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId)],
            diameterOfAnchorsMillimetres: action.payload.diameterOfAnchorsMillimetres
          }
        }
      };
    case actionTypes.SET_ANCHOR_PER_SQUARE_METRE:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)],
            numberOfAnchorsPerMetreSquare: action.payload.numberOfAnchorsPerMetreSquare
          }
        }
      };
    case actionTypes.SET_ANCHOR_PENETRATION:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)],
            anchorPenetrationDepthMillimetres: action.payload.anchorPenetrationDepthMillimetres
          }
        }
      };
    case actionTypes.SET_NET_AREA_PER_METRE_SQUARE:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)],
            netAreaOfAnchorsPerMetreSquareMillimetresSqrd: action.payload.netAreaOfAnchorsPerMetreSquareMillimetresSqrd
          }
        }
      };
    case actionTypes.SET_WOOD_PERCENTAGE:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, action.payload.isLinkedLayer)],
            thicknessMillimetres: action.payload.percentage
          }
        }
      };
    case actionTypes.SET_CALCULATED_LAYER_VALUE:
      const airLayerThickness = Math.max(0, (state.calculationLayers[getCalculationLayerKey(action.payload.bridgingLayer.constructionLayerId)].thicknessMillimetres)
        - ((action.payload?.insulationLayer?.constructionLayerId) ? state.calculationLayers[getCalculationLayerKey(action.payload.insulationLayer.constructionLayerId)].thicknessMillimetres : 0));

      const noAirLayerGrading = action.payload.airCavityLayer.airCavityGradings.find(a => a.airCavityGradingId === 'no-air-layer');
      const currentGrading = state.calculationLayers[getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)].airCavityGrading;

      const airCavityGrading = airLayerThickness <= 0
        ? noAirLayerGrading ?? currentGrading
        : currentGrading?.airCavityGradingId === noAirLayerGrading?.airCavityGradingId
          ? action.payload.airCavityLayer.airCavityGradings.find(a => a.airCavityGradingId === state.calculationLayers[getCalculationLayerKey(action.payload.insulationLayer.constructionLayerId)].material?.defaultAirCavityGradingId)
          : currentGrading;

      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)]: {
            ...state.calculationLayers[getCalculationLayerKey(action.payload.airCavityLayer.constructionLayerId)],
            thicknessMillimetres: airLayerThickness,
            airCavityGrading: airCavityGrading,
          }
        }
      };
    case actionTypes.CREATE_LINKED_LAYER:
      return {
        ...state,
        calculationLayers: {
          ...state.calculationLayers,
          [getCalculationLayerKey(action.payload.calculationLayer.constructionLayerId, true)]: {
            ...action.payload.calculationLayer
          }
        }
      };
    case actionTypes.REMOVE_LINKED_LAYER:

      if (!state.calculationLayers[getCalculationLayerKey(action.payload.constructionLayerId, true)]) {
        return state;
      }


      const cl = Object.keys(state.calculationLayers)
        .filter(key => key !== getCalculationLayerKey(action.payload.constructionLayerId, true))
        .reduce((result, key) => ({
          ...result,
          [key]: state.calculationLayers[key],
        }), {}) as { [key: string]: CalculationLayer };

      return {
        ...state,
        calculationLayers: {
          ...cl
        }
      };

    case actionTypes.FETCH_CALCULATION_RESULTS_SUCCESS:
      return {
        ...state,
        calculationResult: action.payload.calculationResult,
        calculationId: action.payload.calculationId,
        calculationAccessCode: action.payload.calculationAccessCode
      };
    case actionTypes.NEW_CALCULATION_STARTED:
      return {
        ...state,
        calculationLayers: action.payload.calculationLayers
      };
    case actionTypes.EDIT_CALCULATION_STARTED:
      return {
        ...state,
        calculationLayers: action.payload.calculationLayers,
        calculationResult: action.payload.calculationResult,
        projectDetails: action.payload.projectDetails,
        additionalParameters: action.payload.additionalParameters,
        calculationAccessCode: action.payload.calculationAccessCode,
        calculationId: action.payload.calculationId,
      };
    case actionTypes.COPY_CALCULATION_STARTED:
      return {
        ...state,
        calculationLayers: action.payload.calculationLayers,
        projectDetails: action.payload.projectDetails,
        additionalParameters: action.payload.additionalParameters,
      };
    case actionTypes.CLEAR_CURRENT_CALCULATION:
      return initialState;
    default:
      return state;
  }
};

/* Might be a better place for this function */
export const getCalculationLayerKey = (constructionLayerId: number, isLinkedLayer: boolean = false) => constructionLayerId.toString() + (isLinkedLayer ? '.linked' : '');
