import { StoreModel } from '../store/store.model';
import { isEmpty } from 'lodash';

export const buildRoute = (store: StoreModel, relativeRoute: string): string => {
  const countryId = store.componentState.selectedCountry?.countryId ?? '';
  return buildRouteWithCountry(countryId, relativeRoute);
};

export const buildRouteWithCountry = (countryId: string, route: string): string => {
  if (isEmpty(countryId)) {
    return '/';
  }

  return `/${countryId?.toLowerCase()}${route.startsWith('/') ? '' : '/'}${route}`;
};
