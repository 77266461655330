import { Material, AirCavityGrading, ConstructionLayer } from '../../types/domain/construction-data.types';
import { CalculationLayer, CalculationResult, ProjectDetails, Calculation, AdditionalParameters } from '../../types/domain/calculation-data.types';

export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';
export const SET_ADDITIONAL_PARAMETERS = 'SET_ADDITIONAL_PARAMETERS';
export const SET_MATERIAL = 'SET_MATERIAL';
export const SET_AIR_CAVITY_GRADING = 'SET_AIR_CAVITY_GRADING';
export const SET_THICKNESS = 'SET_THICKNESS';
export const SET_ANCHOR_PER_SQUARE_METRE = 'SET_ANCHOR_PER_SQUARE_METRE';
export const SET_ANCHOR_DIAMETER = 'SET_ANCHOR_DIAMETER';
export const SET_ANCHOR_PENETRATION = 'SET_ANCHOR_PENETRATION';
export const SET_NET_AREA_PER_METRE_SQUARE = '';
export const SET_WOOD_PERCENTAGE = 'SET_WOOD_PERCENTAGE';
export const SET_CALCULATED_LAYER_VALUE = 'SET_CALCULATED_LAYER_VALUE';
export const NEW_CALCULATION_STARTED = 'NEW_CALCULATION_STARTED';
export const EDIT_CALCULATION_STARTED = 'EDIT_CALCULATION_STARTED';
export const COPY_CALCULATION_STARTED = 'COPY_CALCULATION_STARTED';
export const CREATE_LINKED_LAYER = 'CREATE_LINKED_LAYER';
export const REMOVE_LINKED_LAYER = 'REMOVE_LINKED_LAYER';

export const CLEAR_CURRENT_CALCULATION = 'CLEAR_CURRENT_CALCULATION';

export const FETCH_CALCULATION_RESULTS_ATTEMPT = 'FETCH_CALCULATION_RESULTS_ATTEMPT';
export const FETCH_CALCULATION_RESULTS_SUCCESS = 'FETCH_CALCULATION_RESULTS_SUCCESS';
export const FETCH_CALCULATION_RESULTS_CLIENT_ERROR = 'FETCH_CALCULATION_RESULTS_CLIENT_ERROR';
export const FETCH_CALCULATION_RESULTS_FAILURE = 'FETCH_CALCULATION_RESULTS_FAILURE';

export interface SetProjectDetails {
  type: typeof SET_PROJECT_DETAILS;
  payload: ProjectDetails;
}

export interface SetAdditionalParameters {
  type: typeof SET_ADDITIONAL_PARAMETERS;
  payload: AdditionalParameters;
}

export interface SetAirCavityGrading {
  type: typeof SET_AIR_CAVITY_GRADING;
  payload: {
    constructionLayerId: number,
    airCavityGrading: AirCavityGrading,
  }
}

export interface SetMaterial {
  type: typeof SET_MATERIAL;
  payload: {
    constructionLayerId: number,
    material: Material,
    isLinkedLayer: boolean,
  }
}

export interface SetThickness {
  type: typeof SET_THICKNESS;
  payload: {
    constructionLayerId: number,
    thicknessMillimetres: number,
    isLinkedLayer: boolean,
  }
}

export interface SetAnchorDiameter {
  type: typeof SET_ANCHOR_DIAMETER;
  payload: {
    constructionLayerId: number,
    diameterOfAnchorsMillimetres: number,
    isLinkedLayer: boolean,
  }
}

export interface SetAnchorAmountPerSquareMetre {
  type: typeof SET_ANCHOR_PER_SQUARE_METRE;
  payload: {
    constructionLayerId: number,
    numberOfAnchorsPerMetreSquare: number,
    isLinkedLayer: boolean,
  }
}

export interface SetNetAreaPerMetreSquare {
  type: typeof SET_NET_AREA_PER_METRE_SQUARE;
  payload: {
    constructionLayerId: number,
    netAreaOfAnchorsPerMetreSquareMillimetresSqrd: number,
    isLinkedLayer: boolean,
  }
}

export interface SetAnchorPenetration {
  type: typeof SET_ANCHOR_PENETRATION;
  payload: {
    constructionLayerId: number,
    anchorPenetrationDepthMillimetres: number | undefined,
    isLinkedLayer: boolean,
  }
}

export interface SetWoodPercentage {
  type: typeof SET_WOOD_PERCENTAGE;
  payload: {
    constructionLayerId: number,
    percentage: number,
    isLinkedLayer: boolean,
  }
}

export interface SetCalculatedLayerValue {
  type: typeof SET_CALCULATED_LAYER_VALUE;
  payload: {
    bridgingLayer: ConstructionLayer,
    insulationLayer: ConstructionLayer,
    airCavityLayer: ConstructionLayer,
  }
}
export interface NewCalculcationStarted {
  type: typeof NEW_CALCULATION_STARTED;
  payload: {
    constructionTypeId: string,
    calculationLayers: { [key: string]: CalculationLayer }
  }
}

export interface EditCalculcationStarted {
  type: typeof EDIT_CALCULATION_STARTED;
  payload: {
    calculationId: string,
    calculationAccessCode: string,
    constructionTypeId: string,
    calculationLayers: { [key: string]: CalculationLayer },
    projectDetails: ProjectDetails,
    calculationResult: CalculationResult,
    additionalParameters: AdditionalParameters,
  }
}

export interface CopyCalculcationStarted {
  type: typeof COPY_CALCULATION_STARTED;
  payload: {
    constructionTypeId: string,
    calculationLayers: { [key: string]: CalculationLayer },
    projectDetails: ProjectDetails,
    additionalParameters: AdditionalParameters,
  }
}

export interface CreateLinkedLayer {
  type: typeof CREATE_LINKED_LAYER;
  payload: {
    calculationLayer: CalculationLayer,
  }
}

export interface RemoveLinkedLayer {
  type: typeof REMOVE_LINKED_LAYER;
  payload: {
    constructionLayerId: number,
  }
}

export interface ClearCurrentCalculation {
  type: typeof CLEAR_CURRENT_CALCULATION;
}

export interface FetchCalculationResultsAttempt {
  type: typeof FETCH_CALCULATION_RESULTS_ATTEMPT;
}

export interface FetchCalculationResultsSuccess {
  type: typeof FETCH_CALCULATION_RESULTS_SUCCESS;
  payload: Calculation;
}

export interface FetchCalculationResultsClientError {
  type: typeof FETCH_CALCULATION_RESULTS_CLIENT_ERROR;
}

export interface FetchCalculationResultsFailure {
  type: typeof FETCH_CALCULATION_RESULTS_FAILURE;
  error: string;
}


export type CurrentCalculationDataActionTypes =
  | SetProjectDetails
  | SetAdditionalParameters
  | SetAirCavityGrading
  | SetMaterial
  | SetThickness
  | SetAnchorDiameter
  | SetAnchorAmountPerSquareMetre
  | SetAnchorPenetration
  | SetNetAreaPerMetreSquare
  | SetWoodPercentage
  | NewCalculcationStarted
  | EditCalculcationStarted
  | CopyCalculcationStarted
  | SetCalculatedLayerValue
  | CreateLinkedLayer
  | RemoveLinkedLayer
  | ClearCurrentCalculation
  | FetchCalculationResultsAttempt
  | FetchCalculationResultsSuccess
  | FetchCalculationResultsClientError
  | FetchCalculationResultsFailure;
