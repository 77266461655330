import {
  ConstructionType, ConstructionLayer,
} from '../../types/domain/construction-data.types';

export const FETCH_CONSTRUCTION_TYPES_ATTEMPT = '[Construction Data] Fetch Construction Types Attempt';
export const FETCH_CONSTRUCTION_TYPES_SUCCESS = '[Construction Data] Fetch Construction Types Success';
export const FETCH_CONSTRUCTION_TYPES_FAILURE = '[Construction Data] Fetch Construction Types Failure';

export const FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS = '[Construction Data] Fetch Construction Type Names Success';

export const FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT = '[Construction Data] Fetch Construction Layers for Type Attempt';
export const FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS = '[Construction Data] Fetch Construction Layers for Type Success';
export const FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE = '[Construction Data] Fetch Construction Layers for Type Failure';

// Construction Types Fetch

export interface FetchConstructionTypesAttempt {
  type: typeof FETCH_CONSTRUCTION_TYPES_ATTEMPT;
}

export interface FetchConstructionTypesSuccess {
  type: typeof FETCH_CONSTRUCTION_TYPES_SUCCESS;
  payload: ConstructionType[];
}

export interface FetchConstructionTypesFailure {
  type: typeof FETCH_CONSTRUCTION_TYPES_FAILURE;
  error: string;
}

export interface FetchConstructionTypeNamesSuccess {
  type: typeof FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS;
  payload: ConstructionType[];
}

// Construction Layers for Type Fetch

export interface FetchConstructionLayersForTypeAttempt {
  type: typeof FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT;
}

export interface FetchConstructionLayersForTypeSuccess {
  type: typeof FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS;
  payload: {
    constructionType: string;
    constructionTypeNameLocalized: string;
    layers: ConstructionLayer[],
  };
}

export interface FetchConstructionLayersForTypeFailure {
  type: typeof FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE;
  error: string;
}


export type ConstructionDataActionTypes =
  | FetchConstructionTypesAttempt
  | FetchConstructionTypesSuccess
  | FetchConstructionTypesFailure
  | FetchConstructionTypeNamesSuccess
  | FetchConstructionLayersForTypeAttempt
  | FetchConstructionLayersForTypeSuccess
  | FetchConstructionLayersForTypeFailure;
