import React from 'react';
import { Button, Menu, MenuItem, Grid } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export type CultureSelectDesktopProps = {
  culture: string;
  supportedCultures: string[];
  handleCultureSelection: (culture: string) => void;

  anchorElement: null | HTMLElement;
  handleMenuButtonClick: (event: any) => void;
  handleClose: () => void;
}

export default function CultureSelectDesktop(props: CultureSelectDesktopProps) {

  const { t } = useTranslation();

  const { 
    culture, supportedCultures, handleCultureSelection,
    anchorElement, handleMenuButtonClick, handleClose,
  } = props;

  if (isEmpty(supportedCultures)) {
    return null;
  }

  return (
    <>
      <Button aria-controls="culture-menu" aria-haspopup="true" onClick={handleMenuButtonClick}>
        <Grid container spacing={2} data-qa-id="cultureSelect">
          <Grid item>
            {t(`navigation-ui.${culture}`)}
          </Grid>
          <Grid item>
            <PublicIcon />
          </Grid>
        </Grid>
      </Button>
      <Menu
        id="culture-menu"
        anchorEl={anchorElement}
        keepMounted
        open={!isEmpty(anchorElement)}
        onClose={handleClose}
      >
        {supportedCultures.map(supportedCulture =>
          <MenuItem key={supportedCulture} onClick={() => handleCultureSelection(supportedCulture)} data-qa-id={`cultureSelectOption-${supportedCulture}`}>
            {t(`navigation-ui.${supportedCulture}`)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}