import { ProjectDetails, CalculationResult, CalculationLayer, Calculation } from '../types/domain/calculation-data.types';
import { getCalculationLayerKey } from '../store/current-calculation-data/current-calculation-data.reducer';
import { parseISO } from 'date-fns';

type ApiCalculation = {
  calculationLayers: CalculationLayer[];
  calculationResult: CalculationResult;
  projectDetails?: ProjectDetails;
  createdOn: string;
  updatedOn: string;
  constructionTypeId: string;
  countryId: string;
  woodPercentage: number | null;
};

export const mapApiResponseArrayToCalculcationObjectCollection = (data: ApiCalculation[]): { [key: string]: Calculation } => {
  if (data.length) {
    return (data as ApiCalculation[])
      .map(mapApiResponseToCalculcation)
      .reduce((obj, item) => {
        return {
          ...obj,
          [item.calculationId]: item
        };
      }, {}) as { [key: string]: Calculation };
  }
  return {};
};

export const mapApiResponseToCalculcation = (calc: ApiCalculation): Calculation => {
  const { calculationLayers, projectDetails, ...result } = calc;

  return {
    ...result,
    projectDetails: {
      ...projectDetails,
      startDate: projectDetails?.startDate ? parseISO(projectDetails?.startDate.toString()) : undefined
    },
    createdOn: parseISO(result.createdOn),
    updatedOn: parseISO(result.updatedOn),
    calculationLayers: calculationLayers.reduce((obj, item) => {
      return {
        ...obj,
        [getCalculationLayerKey(item.constructionLayerId, item.material?.isLinkedMaterial ?? false)]: item
      };
    }, {})
  } as Calculation;
};
