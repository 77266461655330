
import { AnyAction, Store } from 'redux';
import { AuthenticationActionCreators, IAccountInfo, AuthenticationActions } from 'react-aad-msal';
import { debounce } from 'lodash';
import { fetchUser } from '../account/account.action-creators';

export const authenticationActionCreatorOverrides = (store: Store) => {

  console.log('authenticationActionCreatorOverrides Loaded');

  const debouncedFetchUser = debounce((data: IAccountInfo) => {
    console.log('debouncedFetchUser');
    fetchUser(data)(store.dispatch);
  }, 250);

  AuthenticationActionCreators.loginSuccessful = (data: IAccountInfo): AnyAction => {
    console.log('onLoginSuccessful');
    debouncedFetchUser(data);
    return {
      payload: data,
      type: AuthenticationActions.LoginSuccess,
    };
  };
};