import React from 'react';
import AzureAD, { IAzureADFunctionProps } from 'react-aad-msal';
import authProviderFactory from '../../authentication/authProvider';

export default function PasswordReset() {

  return (
    <AzureAD provider={authProviderFactory()} >
      {({ logout, accountInfo }: IAzureADFunctionProps) => {
        setTimeout(() => {
          if (accountInfo) {
            logout();
          } else {
            document.location.href = '/';
          }
        }, accountInfo ? 2000 : 500);
        return (
          accountInfo
            ? <span>Signing out. If you changed your password, please sign in with your new password.</span>
            : <span>Redirecting...</span>
        );
      }}
    </AzureAD>
  );
}
