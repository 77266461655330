import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { countryIdSelector } from '../../../store/component-state/component-state.selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        address: {
            fontStyle: 'normal',
        },
    })
);

export function Address() {

    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);

    return (
        <>
            <div>
                <Typography variant="h6">
                    {parse(t(`by-country.${countryId}.footer.address-label`))}
                </Typography>
            </div>
            <address className={theme.address}>
                <div>
                    <Typography variant="body1">
                        {parse(t(`by-country.${countryId}.footer.address.line1`))}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1">
                        {parse(t(`by-country.${countryId}.footer.address.line2`))}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1">
                        {parse(t(`by-country.${countryId}.footer.address.line3`))}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body1">
                        {parse(t(`by-country.${countryId}.footer.address.line4`))}
                    </Typography>
                </div>
            </address>
        </>
    );
}
