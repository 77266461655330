import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ConstructionLayer, Material, LayerType } from '../../types/domain/construction-data.types';
import ThicknessSlider from './ThicknessSlider';
import NoSlider from './NoSlider';
import { setThickness } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';

type MaterialParametersAuxProps = {
  layer: ConstructionLayer,
  currentSelectedThickness: number,
  currentSelectedMaterial: Material | undefined,
  isLinkedMaterial: boolean,
}

export default function MaterialParametersAux(props: MaterialParametersAuxProps) {
  const { isLinkedMaterial, layer, currentSelectedThickness, currentSelectedMaterial } = props;
  const dispatch = useDispatch();
  const isFixedLayer: boolean = layer.isWithinBridgingFrame && layer.layerType === LayerType.AirCavity;

  const handleChange = (value: number) => {
    dispatch(setMaterialParameter(setThickness, layer, value, isLinkedMaterial));
  };

  if (currentSelectedMaterial && currentSelectedMaterial.optionsForThicknessMillimetres.length > 1) {
    return (
      <ThicknessSlider
        labelKey='thickness-label'
        handleChange={handleChange}
        selectedThickness={currentSelectedThickness}
        disabled={!currentSelectedMaterial || isFixedLayer}
        marks={currentSelectedMaterial?.optionsForThicknessMillimetres ?? []}
        min={currentSelectedMaterial?.optionsForThicknessMillimetres[0] ?? 0}
        max={currentSelectedMaterial?.optionsForThicknessMillimetres[currentSelectedMaterial.optionsForThicknessMillimetres.length - 1] ?? 100}
        defaultValue={currentSelectedMaterial?.defaultThicknessMillimetres ?? 0}
        units='mm'
        isValueFixed={isFixedLayer}
      />
    );
  }
  else if (currentSelectedMaterial && currentSelectedMaterial.optionsForThicknessMillimetres.length === 1 && currentSelectedMaterial.optionsForThicknessMillimetres[0] !== 0) {
    return (
      <NoSlider
        labelKey='thickness-label'
        selectedThickness={currentSelectedThickness}
        units='mm'
      />
    );
  }
  else {
    return (
      <Box marginTop={4}>
      </Box>
    );
  }
}
