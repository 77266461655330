import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ConstructionLayer, Material } from '../../types/domain/construction-data.types';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setMaterial } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { CalculationLayerProps } from './CalculationLayer';

export default function MaterialDropDown(props: CalculationLayerProps) {
  const { isLinkedMaterial } = props;
  const dispatch = useDispatch();
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const currentSelectedMaterial: Material | undefined = useSelector(currentSelectedMaterialSelector(isLinkedMaterial));
  const parentSelectedMaterial: Material | undefined = useSelector(currentSelectedMaterialSelector(false));

  const materials = isLinkedMaterial ? parentSelectedMaterial!.linkedMaterials : layer.materials;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setMaterial(layer, materials.filter(m => m.materialId === (event.target.value as string))[0]));
  };

  return (
    <div className={'marginTop20'}>
      <Typography gutterBottom data-qa-id="materialSelectLabel">
        <Trans i18nKey="calculation-ui.material-label">Material</Trans>
      </Typography>

      <Select fullWidth data-qa-id="materialSelect" variant="outlined" value={currentSelectedMaterial?.materialId ?? ''} onChange={handleChange}>
        {materials.map(i => (
          <MenuItem key={i.materialId} value={i.materialId} data-qa-id={`${i.name}-dropdownvalue`}>
            {i.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
