import { StoreModel } from '../store.model';
import { ConstructionLayer, ConstructionType, Country, County, FieldData, ProjectConfig } from '../../types/domain/construction-data.types';

export const activeConstructionLayerSelector = (store: StoreModel): ConstructionLayer =>
  store.constructionData.layers[store.componentState.activeConstructionTypeId ?? '']
    ?.filter(l => l.constructionLayerId === store.componentState.activeConstructionLayerId)[0];

export const activeConstructionTypeIdSelector = (store: StoreModel): string => store.componentState.activeConstructionTypeId;

export const activeConstructionTypeSelector = (store: StoreModel): ConstructionType | undefined =>
  store.constructionData.types.find(ct => ct.id === store.componentState.activeConstructionTypeId);

export const countriesSelector = (store: StoreModel): Country[] => store.componentState.countries ?? [];

export const isLoadingCountriesSelector = (store: StoreModel): boolean => store.componentState.countries === null;

export const countrySelector = (store: StoreModel): Country | null => store.componentState.selectedCountry;

export const countryIdSelector = (store: StoreModel): string => (store.componentState.selectedCountry?.countryId ?? '').toLowerCase();

export const calculationResultLoadingStateSelector = (store: StoreModel): boolean => store.componentState.isCalculationResultLoading;

export const isLoadingSelector = (store: StoreModel): boolean => store.componentState.isLoading;

export const isLoadingConstructionTypesSelector = (store: StoreModel): boolean => store.componentState.isLoadingConstructionTypes;

export const errorSelector = (store: StoreModel): string | null => store.componentState.error;

export const calculationResultErrorSelector = (store: StoreModel): string | null => store.componentState.calculationResultError;

export const selectedCultureSelector = (store: StoreModel): string => store.componentState.selectedCulture;

export const downloadingCalculationIdSelector = (store: StoreModel): string | null => store.componentState.downloadingCalculationId;

export const getIsGtmContainerSet = (store: StoreModel): boolean => store.componentState.isGtmContainerSet;

export const projectConfigSelector = (store: StoreModel) : ProjectConfig | null => store.componentState.projectConfig;

export const countiesSelector = (store: StoreModel) : County[] => store.componentState.counties ?? [];

export const fieldDataSelector = (store: StoreModel) : FieldData[] => store.componentState.fieldData ?? [];
