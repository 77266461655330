import React from 'react';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentAnchorPropertiesSelector, currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionLayer, Material } from '../../types/domain/construction-data.types';
import ThicknessSlider from './ThicknessSlider';
import { setNetAreaPerMetreSquare } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';

export default function AnchorParameters() {
  const dispatch = useDispatch();
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const { netAreaOfAnchorsPerMetreSquareMillimetresSqrd } = useSelector(currentAnchorPropertiesSelector);
  const currentSelectedMatrial: Material | undefined = useSelector(currentSelectedMaterialSelector(false));

  const handleChange = (value: number) => {
    dispatch(setMaterialParameter(setNetAreaPerMetreSquare, layer, value, false));
  };

  const areaMarks = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 180, 200, 220, 250, 280, 300, 320, 350, 380, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000];

  return (
    <div>
      <ThicknessSlider
        labelKey='area-label'
        handleChange={handleChange}
        selectedThickness={netAreaOfAnchorsPerMetreSquareMillimetresSqrd}
        disabled={!currentSelectedMatrial}
        marks={areaMarks}
        min={areaMarks[0]}
        max={areaMarks[areaMarks.length - 1]}
        defaultValue={50}
        units='mm&sup2;'
        isValueFixed={false} />
    </div>
  );

}
