import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Avatar } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

export type CultureSelectMobileProps = {
  culture: string;
  supportedCultures: string[];
  handleCultureSelection: (culture: string) => void;

  anchorElement: null | HTMLElement;
  handleMenuButtonClick: (event: any) => void;
  handleClose: () => void;
}

export default function CultureSelectMobile(props: CultureSelectMobileProps) {

  const { t } = useTranslation();

  const { 
    culture, supportedCultures, handleCultureSelection,
    anchorElement, handleMenuButtonClick, handleClose,
  } = props;

  if (isEmpty(supportedCultures)) {
    return null;
  }

  return (
    <>
      <ListItem button aria-controls="culture-menu" aria-haspopup="true" onClick={handleMenuButtonClick}>
        <ListItemIcon><Avatar><PublicIcon /></Avatar></ListItemIcon>
        <ListItemText primary={t(`navigation-ui.${culture}`)}></ListItemText>
      </ListItem>
      <Menu
        id="culture-menu"
        anchorEl={anchorElement}
        keepMounted
        open={!isEmpty(anchorElement)}
        onClose={handleClose}
      >
        {supportedCultures.map(supportedCulture =>
          <MenuItem key={supportedCulture} onClick={() => handleCultureSelection(supportedCulture)}>
            {t(`navigation-ui.${supportedCulture}`)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}