import React from 'react';
import { User } from '../../../types/domain/account.types';
import { makeStyles, Theme, createStyles, Grid, Avatar, Link, Box, Divider } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from '../../../types/domain/navigation-item.types';
import CultureSelect from '../CultureSelect/CultureSelect';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    signInLinkContainer: {
      marginRight: theme.spacing(2),
    },
    signedInAvatar: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    signedInWelcome: {
      marginRight: theme.spacing(2),
    },
    link: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
  })
));

export type NavigationDesktopProps = {
  isAuthenticated: boolean;
  user: User | undefined;
  navigationItems: NavigationItem[];
  handleSignInClick: () => void;
  handleSignOutClick: () => void;
};

export default function NavigationDesktop(props: NavigationDesktopProps) {

  const { isAuthenticated, user, navigationItems, handleSignInClick, handleSignOutClick } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item>
          <Box marginBottom={2}>
            <CultureSelect />
            <Divider />
          </Box>
        </Grid>
      </Grid>

      <Grid container justify="space-between" alignItems="center" spacing={4}>

        {navigationItems.map(navigationItem => {
          if (navigationItem.show) {
            return (
              <Grid item key={navigationItem.id} data-qa-id={`${navigationItem.translationKey}-tab`}>
                <Link component="button" variant="body2">
                  <RouterLink to={navigationItem.route} className={classes.link}>
                    {t(navigationItem.translationKey)}
                  </RouterLink>
                </Link>
              </Grid>
            );
          }
          return null;
        })}

        <Grid item>
          {isAuthenticated
            ? <Grid item container alignItems="center" onClick={handleSignOutClick}>
              <Grid className={classes.signedInWelcome}>
                <Box data-qa-id="greeting">{t('navigation-ui.welcome')}, {getUserName(user)}</Box>
                <Box style={{ float: 'right' }}>
                  <Link component="button" variant="body2">
                    {t('navigation-ui.sign-out')}
                  </Link>
                </Box>
              </Grid>
              <Grid>
                <Avatar src="not-valid.jpg" className={classes.signedInAvatar}>{getInitials(user)}</Avatar>
              </Grid>
            </Grid>
            : <Grid item container alignItems="center" onClick={handleSignInClick}>
              <Grid className={classes.signInLinkContainer}>
                <Link data-qa-id="signInButton" component="button" variant="body2">{t('navigation-ui.sign-in')}</Link>
              </Grid>
              <Grid>
                <Avatar src="not-valid.jpg"></Avatar>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
}

const getInitials = (user: User | undefined): string => {
  const name = getUserName(user);

  return name === '' ? name : name[0];
};

const getUserName = (user: User | undefined): string => {
  if (user === undefined) {
    return '';
  }

  return `${user.givenName} ${user.familyName}`;
};

