import React from 'react';
import MaterialDropDown from './MaterialDropDown';
import MaterialParameters from './MaterialParameters';
import AnchorParameters from './AnchorParameters';
import AnchorPreCalculatedParameters from './AnchorPreCalculatedParameters';
import WoodPercentage from './WoodPercentage';
import AirCavity from './AirCavity';
import { ConstructionLayer, LayerType, Material } from '../../types/domain/construction-data.types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedMaterialSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LayerName from '../Calculation/LayerName';

function renderControls(layer: ConstructionLayer, selectedMaterial: Material | undefined, isLinkedMaterial: boolean) {
  switch (layer.layerType) {
    case LayerType.Anchors:
      return (<>
        <MaterialDropDown isLinkedMaterial={isLinkedMaterial} />
        <AnchorParameters />
      </>);
    case LayerType.AnchorsPreCalculated:
      return (<>
        <MaterialDropDown isLinkedMaterial={isLinkedMaterial} />
        <AnchorPreCalculatedParameters />
      </>);
    case LayerType.WoodPercentage:
      return (
        <WoodPercentage />
      );
    case LayerType.AirCavity:
      return (
        <AirCavity />
      );
    default:
      return (<>
        <MaterialDropDown isLinkedMaterial={isLinkedMaterial} />
        {(selectedMaterial?.optionsForThicknessMillimetres?.length || layer.isWithinBridgingFrame) && <MaterialParameters isLinkedMaterial={isLinkedMaterial} />}
      </>);
  }
}

function renderLayerHeader(layer: ConstructionLayer, layerTooltipText: string) {
  switch (layer.layerType) {
    case LayerType.Anchors:
    case LayerType.AnchorsPreCalculated:
    case LayerType.WoodPercentage:
      return (
        <Tooltip title={layerTooltipText} aria-label={layerTooltipText} enterTouchDelay={10}>
          <div className={'stepTitle stepTitleTooltip'} data-qa-id="layerTitle">
            <LayerName layer={layer} />
            <InfoIcon className={'tooltipIcon'}/>
          </div>
        </Tooltip>
      );
    default:
      return (
        <div className={'stepTitle'} data-qa-id="layerTitle">
          <LayerName layer={layer} />
        </div>
      );
  }
}

  export type CalculationLayerProps = {
    isLinkedMaterial: boolean
  }

  export default function CalculationLayer() {
    const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
    const currentSelectedMaterial: Material | undefined = useSelector(currentSelectedMaterialSelector(false));

    const { t } = useTranslation();

    const layerTooltipText = t(`layer-tooltip.${layer.layerType.toLowerCase()}`);

    return layer ? (
      <div style={{ maxWidth: '400px' }}>
        {renderLayerHeader(layer, layerTooltipText)}
        {renderControls(layer, currentSelectedMaterial, false)}
        {
          currentSelectedMaterial && currentSelectedMaterial.linkedMaterials.length > 0 && renderControls(layer, currentSelectedMaterial, true)
        }
      </div>
    ) : null;
  }
