import { Box, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { gt, gte } from 'lodash';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PerimeterAndArea.module.scss';

export interface PerimeterAndAreaProps {
  perimeter: number;
  setPerimeter: React.Dispatch<React.SetStateAction<number>>;
  area: number;
  setArea: React.Dispatch<React.SetStateAction<number>>;
}

export default function PerimeterAndArea(props: PerimeterAndAreaProps) {

  const { t } = useTranslation();
  const maxPerimeter: number = 100000000;
  const maxArea: number = 100000000;
  const defaultMinimum: number = 1.00;

  function updatePerimeter(value: string): void {
    let perimeterValue: number = Number(value);

    if (Number.isNaN(perimeterValue) || perimeterValue <= 0) {
      perimeterValue = defaultMinimum;
    }

    if (gt(perimeterValue, maxPerimeter)) {
      perimeterValue = maxPerimeter;
    }

    props.setPerimeter(perimeterValue);
  }

  function updateArea(value: string): void {
    let areaValue: number = Number(value);

    if (Number.isNaN(areaValue) || areaValue <= 0) {
      areaValue = defaultMinimum;
    }

    if (gt(areaValue, maxArea)) {
      areaValue = maxArea;
    }

    props.setArea(areaValue);
  }

  function calcRatio(): number {
    if (props.area && props.perimeter) {
      return props.perimeter / props.area;
    }
    return 0;
  }

  function getHelperText(key: string, value: number, maxValue: number): string {
    let helperText = gte(value, maxValue)
      ? t(`additional-parameters.perimeter-and-area.${key}-exceeds-max`, { maxPerimeter: maxPerimeter })
      : '';
    return helperText;
  };

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={2}>
        <InputLabel htmlFor="pa-perimeter" className={styles.paLabel}>
          {t('additional-parameters.perimeter-and-area.perimeter')}
        </InputLabel>

        <TextField
          id="pa-perimeter"
          className={styles.paTextFieldShort}
          type="number"
          inputProps={{ inputMode: 'numeric', min: { defaultMinimum }, max: { maxPerimeter } }}
          value={props.perimeter}
          data-qa-id="qa-pa-perimeter"
          variant="outlined"
          error={gt(props.perimeter, maxPerimeter)}
          helperText={getHelperText('perimeter', props.perimeter, maxPerimeter)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => updatePerimeter(event.target.value)}
        />
      </Grid>

      <Grid item xs={1} className={styles.paUnits}>
        <Typography>{t('additional-parameters.perimeter-and-area.perimeter-units')}</Typography>
      </Grid>

      <Grid item xs={2}>
        <InputLabel htmlFor="pa-area" className={`${styles.paLabel} ${styles.paLabelShort}`}>
          {t('additional-parameters.perimeter-and-area.area')}
        </InputLabel>

        <TextField
          id="pa-area"
          className={styles.paTextFieldShort}
          type="number"
          inputProps={{ inputMode: 'numeric', min: { defaultMinimum }, max: { maxArea } }}
          value={props.area}
          data-qa-id="qa-pa-area"
          variant="outlined"
          error={gt(props.area, maxArea)}
          helperText={getHelperText('area', props.area, maxArea)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => updateArea(event.target.value)}
        />
      </Grid>

      <Grid item xs={1} className={styles.paUnits}>
        <Typography>{t('additional-parameters.perimeter-and-area.area-units')}</Typography>
      </Grid>

      <Grid item xs={4}>
        <InputLabel htmlFor="pa-ratio" className={`${styles.paLabel} ${styles.paLabelShort}`}>
          {t('additional-parameters.perimeter-and-area.pa-ratio')}
        </InputLabel>

        <Box className={styles.paRatio}>
          <Typography id="pa-ratio" data-qa-id="qa-pa-ratio">= {calcRatio().toFixed(3)}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
