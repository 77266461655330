import React from 'react';
import { useSelector } from 'react-redux';
import { CalculationResult, ProjectDetails } from '../../types/domain/calculation-data.types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import { getAppConfig } from '../../config/config';
import ThicknessComparison from './ThicknessComparison';
import RcValue from '../RcValue/RcValue';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';
import { constructionTypeNameSelector } from '../../store/construction-data/construction-data.selectors';
import { currentCalculationDataSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { ConstructionLayer, LayerType, Material } from '../../types/domain/construction-data.types';
import LayerName from '../Calculation/LayerName';
import styles from './CalculationSummary.module.scss';
import { getCalculationLayerKey } from '../../store/current-calculation-data/current-calculation-data.reducer';
import MaterialParametersAux from '../CalculationLayer/MaterialParametersAux';

export type CalculationSummaryProps = {
  results: CalculationResult | undefined,
  constructionTypeId: string,
  projectDetails?: ProjectDetails | null,
  layers: ConstructionLayer[];
};

const useStyle = makeStyles((theme: Theme) => (
  createStyles({
    media: {
      height: 270,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    summaryContainer: {
      padding: theme.spacing(2),
    },
    summary: {
      marginBottom: 24,
    },
    layer: {
      marginBottom: 12,
    },
    link: {
      color: 'inherit',
    },
    panelsContainer: {
      marginTop: '0.5em',
      marginBottom: '1em',
    },
    panel: {
      padding: theme.spacing(2),
      backgroundColor: '#E0E0E0',
      borderRadius: '0.75em',
    },
    icon: {
      verticalAlign: 'bottom'
    },
    compliant: {
      color: 'green'
    },
    nonCompliant: {
      color: 'red'
    }
  })
));

function getAvailableMaterials(constructionLayer: ConstructionLayer | null | undefined) {
  let materials = [...constructionLayer?.materials ?? []];
  if (constructionLayer?.materials) {
    constructionLayer?.materials.forEach(material => {
      materials = [...materials, ...material.linkedMaterials ?? []];
    });
  }
  return materials;
}

export const hasThicknessOptions = (material: Material | null | undefined): boolean => (
    material !== null && material !== undefined &&
    !isEmpty(material.optionsForThicknessMillimetres) &&
    material.optionsForThicknessMillimetres.some(x => x > 0)
);

export default function CalculationSummary(props: CalculationSummaryProps) {

  const classes = useStyle();
  const apiUrl = getAppConfig()?.apiUrl;

  const countryId = useSelector(countryIdSelector);
  const constructionTypeId = isEmpty(props.constructionTypeId) ? 'cavity-wall' : props.constructionTypeId;
  const constructionTypeName = useSelector(constructionTypeNameSelector(constructionTypeId));
  const currentCalculationData = useSelector(currentCalculationDataSelector);

  const thicknessComparison = props.results?.thicknessComparison;

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={6} md={4}>
        <Box marginLeft={2} marginTop={2} className="calculationTitle">
          {constructionTypeName}
        </Box>
        <Box marginLeft={2} marginBottom={2} className="calculationSubTitle">{props.projectDetails?.name}</Box>
        <Box marginBottom={3}>
          <img
            data-qa-id="construction-type-image"
            style={{ maxWidth: '100%' }}
            src={`${apiUrl}/content/build-up-images/${countryId.toUpperCase()}/${countryId.toUpperCase()}-${constructionTypeId}.jpg`}
            alt={constructionTypeName} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.summaryContainer}>
        <div className={classes.summary} data-qa-id="ConstructionSummary">
          <strong>
            <Trans i18nKey="calculation-summary-ui.construction-summary-text">Construction Summary</Trans>
          </strong>
        </div>

        <ul className={styles.layerList}>
          {props.results?.calculationLayers?.map(resultLayer => {
            const constructionLayer = props.layers.find(layer => layer.name === resultLayer.layerName);
            const selectedMaterial = getAvailableMaterials(constructionLayer).find(material => material.materialId === resultLayer.materialId);
            const calculationLayerKey = getCalculationLayerKey(constructionLayer?.constructionLayerId ?? -1, selectedMaterial?.isLinkedMaterial);
            return {
              ...resultLayer,
              constructionLayer,
              selectedMaterial,
              calculationLayer: currentCalculationData.calculationLayers[calculationLayerKey]
            };
          }).map(layer => (
            <li key={`${layer.layerName}-${layer.materialId ?? layer.materialName}`}>
              <LayerName layer={layer.constructionLayer} />
              <div className={styles.layerProperties}>
                <Trans i18nKey={`results-layer.${layer.materialName}`}>{layer.materialName}</Trans>
                {layer.constructionLayer?.layerType === LayerType.Insulation &&
                  hasThicknessOptions(layer.selectedMaterial) &&
                  <div className={styles.layerPropertiesChevron}>
                    <Box marginTop={-4} marginLeft={2} marginRight={1}>
                      <MaterialParametersAux
                        layer={layer.constructionLayer}
                        currentSelectedThickness={layer.calculationLayer.thicknessMillimetres}
                        currentSelectedMaterial={layer.selectedMaterial}
                        isLinkedMaterial={layer.selectedMaterial?.isLinkedMaterial ?? false}
                      />
                    </Box>
                  </div>}
              </div>
            </li>
          ))}
        </ul>

        <div data-qa-id="total-thickness">
          <strong>
            <Trans i18nKey="calculation-summary-ui.total-thickness-text">Total thickness</Trans>
          </strong>&nbsp;{props.results?.totalThicknessMillimetres ?? '--'} mm
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Grid className={classes.panelsContainer} container spacing={2}>
          <Grid item xs={12} sm={6} md={12}>
            <RcValue />
          </Grid>
          {
            props.results?.isBengCompliant != null &&
            <Grid item xs={12} sm={6} md={12} data-qa-id="beng-indicator">
              <Box className={classes.panel}>
                <Typography align="center" variant="h6" component="h2">
                  BENG {
                    props.results.isBengCompliant ?
                      <CheckCircleIcon color="primary" className={`${classes.icon} ${classes.compliant}`} data-qa-id="beng-indicator-green" /> :
                      <CancelIcon color="primary" className={`${classes.icon} ${classes.nonCompliant}`} data-qa-id="beng-indicator-red" />
                  }
                </Typography>
              </Box>
            </Grid>
          }
          {
            thicknessComparison &&
            <Grid item xs={12} sm={6} md={12}>
              <Box className={classes.panel}>
                <ThicknessComparison thicknessComparison={thicknessComparison} />
              </Box>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>

  );
}
