import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, makeStyles, Theme, createStyles, Paper, CircularProgress, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import FormatNumber from '../FormatNumber/FormatNumber';
import { MyCalculationsProps } from './MyCalculations';
import { CalculationResultType } from '../../types/domain/calculation-data.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      fontSize: '0.9em',
      '& td': {
        padding: '16px 0px 16px 4px',
      },
      '& th': {
        padding: '16px 0px 16px 4px',
      },
      '& .actionButtons': {
        padding: '16px 0px',
      },
    },
    button: {
      margin: `${theme.spacing(1)}px 2px`,
    },
    progressContainer: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    buttonWrapper: {
      position: 'relative',
      float: 'left',
    },
    buttonProgress: {
      color: theme.palette.info.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default function MyCalculationsDesktop(props: MyCalculationsProps) {
  const { calculations, country, countryId, handleDownloadClick, handleDirectDownloadClick, handleDeleteClick, isDeleting, isDownloading, isCalculationDownloading } = props;

  const history = useHistory();
  const classes = useStyles();

  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans i18nKey="my-calculations-ui.table.project-name">Project Name</Trans>
            </TableCell>
            <TableCell>
              <Trans i18nKey="my-calculations-ui.table.construction-type">Type of Construction</Trans>
            </TableCell>
            <TableCell>
              {
                country?.calculationResultType === CalculationResultType.RValue
                  ? <Trans i18nKey="my-calculations-ui.table.rvalue">R Value ()</Trans>
                  : <Trans i18nKey="my-calculations-ui.table.uvalue">U Value ()</Trans>
              }
            </TableCell>
            {!isTabletView && (
              <TableCell>
                <Trans i18nKey="my-calculations-ui.table.startdate">Start Date</Trans>
              </TableCell>
            )}
            {!isTabletView && (
              <TableCell>
                <Trans i18nKey="my-calculations-ui.table.last-change">Date of last Change</Trans>
              </TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calculations.map(calc => (
            <TableRow key={calc.calculationId}>
              <TableCell component="th" scope="row">
                {calc.projectDetails.name}
              </TableCell>
              <TableCell>
                {calc.constructionTypeNameLocalized}
              </TableCell>
              <TableCell>
                <FormatNumber toFormat={calc.calculationResult.resultValue} /> {calc.calculationResult.resultUnits}
              </TableCell>
              {!isTabletView && <TableCell>{calc.projectDetails.startDate ? format(calc.projectDetails.startDate, 'dd-MM-yyyy') : '-'}</TableCell>}
              {!isTabletView && <TableCell>{calc.updatedOn ? format(calc.updatedOn, 'dd-MM-yyyy HH:mm:ss') : '-'}</TableCell>}
              <TableCell className={'actionButtons'}>
                <div className={classes.buttonWrapper}>
                  <Button color="primary" className={classes.button} startIcon={<FileCopyIcon />} onClick={() => history.push(buildRouteWithCountry(countryId, `/calculation/${calc.constructionTypeId}/${calc.calculationId}?copy`))}>
                    <Trans i18nKey="my-calculations-ui.table.buttons.copy">Copy</Trans>
                  </Button>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button color="primary" className={classes.button} startIcon={<EditIcon />} onClick={() => history.push(buildRouteWithCountry(countryId, `/calculation/${calc.constructionTypeId}/${calc.calculationId}`))}>
                    <Trans i18nKey="my-calculations-ui.table.buttons.edit">Edit</Trans>
                  </Button>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button color="primary" className={classes.button} startIcon={<EmailIcon />} onClick={() => handleDownloadClick(calc.calculationId, calc.calculationAccessCode)} disabled={isDownloading()}>
                    <Trans i18nKey="my-calculations-ui.table.buttons.download">Download</Trans>
                  </Button>
                  {isCalculationDownloading(calc.calculationId) && <CircularProgress size={24} className={`${classes.buttonProgress}`} />}
                </div>
                <div className={classes.buttonWrapper}>
                  <Button color="primary" className={classes.button} startIcon={<GetAppIcon />} onClick={() => handleDirectDownloadClick(calc.calculationId, calc.calculationAccessCode)} disabled={isDownloading()}>
                    <Trans i18nKey="my-calculations-ui.table.buttons.direct-download">View</Trans>
                  </Button>
                  {isCalculationDownloading(calc.calculationId) && <CircularProgress size={24} className={`${classes.buttonProgress}`} />}
                </div>
                <div className={classes.buttonWrapper}>
                  <Button color="primary" className={classes.button} startIcon={<DeleteIcon />} onClick={() => handleDeleteClick(calc.calculationId)} disabled={isDeleting(calc.calculationId)}>
                    <Trans i18nKey="my-calculations-ui.table.buttons.delete">Delete</Trans>
                  </Button>
                  {isDeleting(calc.calculationId) && <CircularProgress size={24} className={`${classes.buttonProgress}`} />}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
