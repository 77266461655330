import { useState, useEffect } from 'react';
import { getAppConfig } from '../../../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { countryIdSelector, selectedCultureSelector } from '../../../store/component-state/component-state.selectors';
import { setSelectedCulture } from '../../../store/component-state/component-state.actions';
import { changeAuthProviderLocale } from '../../../authentication/authProvider';
import { setPreviouslySelectedCulture } from '../../../helpers/memory-helper';
import i18n from '../../../i18n';
import { isEmpty } from 'lodash';

export default () => {

  const supportedCulturesPerCountry = getAppConfig()?.supportedCulturesPerCountry ?? {};

  const countryId = useSelector(countryIdSelector);
  const selectedCulture = useSelector(selectedCultureSelector);

  const [supportedCultures, setSupportedCultures] = useState<string[]>([]);

  const dispatch = useDispatch();

  // As the country changes, load all the supported cultures for that country from the appConfig.
  // Also, handle any change in selected culture, to keep the various other components in sync.
  // But also ensure the selected culture is supported, and if not change back to default of en-GB.
  useEffect(() => {
    if (isEmpty(countryId)) {
      return;
    }

    const newSupportedCultures = supportedCulturesPerCountry[countryId?.toUpperCase()] ?? [];

    setSupportedCultures(newSupportedCultures);

    const isValidCulture = selectedCulture === 'en-GB' || newSupportedCultures.some(supportedCulture => supportedCulture === selectedCulture);

    if (!isValidCulture) {
      console.info(`Selected culture ${selectedCulture} is not valid for country ${countryId}, so changing it to en-GB...`);
      dispatch(setSelectedCulture('en-GB'));
    } else if (!isEmpty(selectedCulture) && i18n.language !== selectedCulture) {
      console.info(`Selected culture set to: ${selectedCulture}`);

      // Change the internationalisation language to use the selected culture
      i18n.changeLanguage(selectedCulture);

      // Change the authentication provider's locale to the selected culture
      changeAuthProviderLocale(selectedCulture);

      // Remember the selected culture, for when user's return back to the site.
      // Whether that is by an Azure B2C full page refresh, or the user visting again sometime in the future.
      setPreviouslySelectedCulture(selectedCulture);
    }
  }, [selectedCulture, supportedCulturesPerCountry, countryId, dispatch]);

  return {
    supportedCultures,
    culture: selectedCulture,
    setCulture: (newCulture: string) => dispatch(setSelectedCulture(newCulture)),
  };
};
