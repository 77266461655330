import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import { constructionDataReducer } from './construction-data/construction-data.reducer';
import { currentCalculationDataReducer } from './current-calculation-data/current-calculation-data.reducer';
import { componentStateReducer } from './component-state/component-state.reducer';
import { authenticationReducer } from './authentication/authentication.reducer';
import { accountReducer } from './account/account.reducer';
import { calculationsReducer } from './calculations/calculations.reducer';
import { newsItemReducer } from './news-item/news-item.reducer';

const middleware: any[] = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant());
}

export const store = createStore(
  combineReducers({
    componentState: componentStateReducer,
    constructionData: constructionDataReducer,
    currentCalculationData: currentCalculationDataReducer,
    authenticationState: authenticationReducer,
    accountData: accountReducer,
    calculations: calculationsReducer,
    newsItem: newsItemReducer,
  }),
  composeWithDevTools(applyMiddleware(...middleware))
);
