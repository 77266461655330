import React, { useState } from 'react';
import { Grid, Typography, Slider, Mark, Select, MenuItem, makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import { Trans } from 'react-i18next';

const getMarks = (thicknesses: number[]): Mark[] => {
  return thicknesses.map(t => { return { value: t }; });
};

export type ThicknessSliderProps = {
  labelKey: string,
  handleChange: Function,
  selectedThickness: number,
  disabled: boolean,
  min: number,
  max: number,
  marks: number[],
  defaultValue: number,
  units: string,
  isValueFixed: boolean,
}

const useStyle = makeStyles((theme: Theme) => (
  createStyles({
    depthDropdownContainer: {
      marginTop: 19,
    },
  })
));

export default function ThicknessSlider(props: ThicknessSliderProps) {
  const {
    labelKey,
    handleChange,
    selectedThickness,
    disabled,
    min,
    max,
    marks,
    defaultValue,
    units,
    isValueFixed
  } = props;

  const [value, setValue] = useState<number>(selectedThickness);

  const handleChangeInternal = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleDropdownChange = (event: any) => {
    setValue(event.target.value as number);
    handleChange(event.target.value as number);
  };

  const classes = useStyle();

  React.useEffect(() => {
    setValue(selectedThickness);
  }, [selectedThickness]);

  return (
    <Box marginTop={4}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Typography id="discrete-slider" gutterBottom data-qa-id="depthSliderLabel">
            <Trans i18nKey={`calculation-ui.${labelKey}`}>Thickness</Trans>
          </Typography>
          <Slider
            onChange={handleChangeInternal}
            onChangeCommitted={() => handleChange(value)}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={null}
            marks={getMarks(marks)}
            value={value ?? defaultValue}
            data-qa-id="depthSlider"
            min={min}
            max={max}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4} data-qa-id="inputContainer" className={classes.depthDropdownContainer}>
          {isValueFixed ?
            <Typography variant="h3">
              {value ?? defaultValue}
            </Typography>
            :
            <Select
              value={value ?? defaultValue}
              onChange={handleDropdownChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              disabled={disabled}
              data-qa-id={`${labelKey}-depthDropdown`}
            >
              {
                marks.map((mark: number, index: number) => (
                  <MenuItem value={mark} key={index} data-qa-id={`${mark}-dropdownvalue`}>{`${mark} ${units}`}</MenuItem>
                ))
              }
            </Select>
          }
        </Grid>
      </Grid>
    </Box>
  );
}
