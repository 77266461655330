import React from 'react';
import { Grid, Typography, Box, Button, makeStyles, createStyles, Theme, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { User } from '../../types/domain/account.types';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { uploadCompanyLogo } from '../../store/account/account.action-creators';
import { UploadCompanyLogoModel } from '../../types/application/upload-company-logo.model';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    companyLogo: {
      width: 250,
    }
  })
));

export type BusinessInformationProps = {
  user: User;
  uploadCompanyLogoState: UploadCompanyLogoModel;
};

export default function BusinessInformation(props: BusinessInformationProps) {

  const dispatch = useDispatch();

  const { user, uploadCompanyLogoState } = props;
  const { isLoading, error, response } = uploadCompanyLogoState;
  const uploadSuccessful = response?.ok;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleChooseFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      dispatch(uploadCompanyLogo(event.target.files[0], event.target.files[0].name));
      // Clear out the HTML input control's selected file, allowing the user to select a new file, or even reselect the same file.
      // This does not clear out the blob reference we have, and as long as the file doesn't change on disk before commiting, the commit changes still works.
      event.target.value = null;
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h4" component="h4">
        <Trans i18nKey='account-ui.business-info'>
          Business Information
        </Trans>
      </Typography>

      <List dense={false}>
        <ListItem>
          <ListItemText
            primary={
              <>
                {t('account-ui.titles.company-logo')}
                <Box my={4}>
                  {user.companyLogoBase64 ? (
                    <img src={user.companyLogoBase64} alt="Business logo" className={classes.companyLogo} />
                  ) : (
                      <Typography variant="caption" color="textSecondary" gutterBottom>
                        <Trans i18nKey='account-ui.no-logo-provided'>
                          No logo provided.
                        </Trans>
                      </Typography>
                    )}
                </Box>

                {isLoading && <Box m={2}><CircularProgress /></Box>}
                {error && <Box my={2}><Alert severity="error" data-qa-id="errorMessage">Something went wrong when trying to upload your image, please try again.</Alert></Box>}
                {uploadSuccessful && <Box my={2}><Alert severity="success" data-qa-id="sucessMessage">Your image was uploaded successfully.</Alert></Box>}

                <Button
                  variant="outlined"
                  color="secondary"
                  component="label" disabled={isLoading}
                  data-qa-id="upload-company-logo-btn"
                >
                  <Trans i18nKey="account-ui.buttons.upload-logo"></Trans>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleChooseFileChange}
                    accept="image/*" />
                </Button>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.company-name')} secondary={user.companyName} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.street-address')} secondary={user.streetAddress} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.city')} secondary={user.city} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.postal-code')} secondary={user.postalCode} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.country')} secondary={user.country} />
        </ListItem>
      </List>

    </Grid>
  );
}
