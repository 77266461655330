import React, { useState, useEffect } from 'react';
import { Calculation, CalculationResultType } from '../../types/domain/calculation-data.types';
import { List, ListSubheader, ListItem, ListItemText, Collapse, makeStyles, Theme, createStyles, Box, ListItemSecondaryAction, IconButton, Button } from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { groupBy, isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import FormatNumber from '../FormatNumber/FormatNumber';

import { Link } from 'react-router-dom';
import { MyCalculationsProps } from './MyCalculations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

export default function MyCalculationsMobile(props: MyCalculationsProps) {
  const { calculations, countryId, handleDownloadClick, handleDirectDownloadClick, handleDeleteClick, isDeleting } = props;

  const classes = useStyles();
  const history = useHistory();
  const [displayableCalculations, setDisplayableCalculations] = useState<{ [index: string]: Calculation[] }>({});
  const [opens, setOpens] = useState<{ [index: string]: { open: boolean } }>({});

  useEffect(() => {
    const result = groupBy(calculations, calculation => calculation.projectDetails.name);
    setDisplayableCalculations(result);
  }, [calculations]);

  useEffect(() => {
    const result = Object.keys(displayableCalculations).reduce(
      (acc, projectName) => ({
        ...acc,
        [projectName]: {
          open: false,
        },
      }),
      {} as { [index: string]: { open: boolean } }
    );
    setOpens(result);
  }, [displayableCalculations]);

  const handleItemClick = (projectName: any) => {
    setOpens({
      ...opens,
      [projectName]: {
        // @ts-ignore
        open: !opens[projectName].open,
      },
    });
  };

  const getProjectStartDate = (projectName: string): string => {
    // @ts-ignore
    if (isEmpty(displayableCalculations[projectName])) {
      return '';
    }
    // @ts-ignore
    return format(displayableCalculations[projectName][0].projectDetails.startDate, 'dd-MM-yyyy');
  };

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" data-qa-id="my-calculation-tab">
            My calculations
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem>
          <Button variant="outlined" color="secondary" component={Link} to={buildRouteWithCountry(countryId, '')}>
            <Trans i18nKey="my-calculations-ui.new-calculation">New Calculation</Trans>
          </Button>
        </ListItem>

        {Object.keys(displayableCalculations).map((projectName, index) => (
          <Box key={index} marginBottom={2}>
            <ListItem button divider onClick={() => handleItemClick(projectName)}>
              <ListItemText primary={projectName} secondary={getProjectStartDate(projectName)} />
              {/* @ts-ignore  */}
              {opens[projectName]?.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={
                // @ts-ignore
                opens[projectName]?.open
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {// @ts-ignore
                displayableCalculations[projectName].map((calculation: Calculation, index: number) => (
                  <Box key={index}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        primary={calculation.constructionTypeNameLocalized}
                        secondary={
                          <span>
                            {
                              calculation.calculationResult.resultType === CalculationResultType.RValue
                                ? <Trans i18nKey="my-calculations-ui.table.rvalue">R Value ()</Trans>
                                : <Trans i18nKey="my-calculations-ui.table.uvalue">U Value ()</Trans>
                            }:{' '}
                            <FormatNumber toFormat={calculation.calculationResult.resultValue} /> {calculation.calculationResult.resultUnits}
                          </span>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="copy" onClick={() => history.push(buildRouteWithCountry(countryId, `/calculation/${calculation.constructionTypeId}/${calculation.calculationId}?copy`))}>
                          <FileCopyIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="edit" onClick={() => history.push(buildRouteWithCountry(countryId, `/calculation/${calculation.constructionTypeId}/${calculation.calculationId}`))}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="download" onClick={() => handleDownloadClick(calculation.calculationId, calculation.calculationAccessCode)}>
                          <EmailIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="download" onClick={() => handleDirectDownloadClick(calculation.calculationId, calculation.calculationAccessCode)}>
                          <GetAppIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" disabled={isDeleting(calculation.calculationId)} onClick={() => handleDeleteClick(calculation.calculationId)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Box>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </List>
    </div>
  );
}
