import axios from 'axios';
import { IAccountInfo } from 'react-aad-msal';
import { fetchUserAttempt, fetchUserSuccess, fetchUserFailure, uploadCompanyLogoSuccess, uploadCompanyLogoBadRequest, uploadCompanyLogoFailure, uploadCompanyLogoAttempt, recordGDPRConsentSuccess, recordGDPRConsentFailure, recordGDPRConsentAttempt } from './account.actions';
import { User } from '../../types/domain/account.types';
import { AnyAction } from 'redux';
import { ResponseCode } from '../../types/application/response-code.types';

export const fetchUser = (accountInfo: IAccountInfo) => (

  async (dispatch: (action: AnyAction) => void) => {

    dispatch(fetchUserAttempt());

    try {

      const { data, status } = await axios.post('/api/user', {}, {
        withCredentials: true,
      });

      const isNewUser = status === ResponseCode.CREATED;

      dispatch(fetchUserSuccess(data as User, isNewUser));
    } catch (error) {
      dispatch(fetchUserFailure(error));
    }

  }
);

export const recordGDPRConsent = (consentGiven: boolean) => (
  async (dispatch: (action: AnyAction) => void) => {

    dispatch(recordGDPRConsentAttempt());

    try {
      const { data } = await axios.post('api/user/record-gdpr-consent', consentGiven, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      dispatch(recordGDPRConsentSuccess());
      dispatch(fetchUserSuccess(data as User));

    } catch (error) {
      dispatch(recordGDPRConsentFailure());
    }
  }
);

export const uploadCompanyLogo = (file: any, fileName: string) => (
  async (dispatch: (action: any) => void) => {

    dispatch(uploadCompanyLogoAttempt());

    try {
      const formData = new FormData();
      formData.append('companyLogoImage', file as string, fileName);

      const { data } = await axios.post('api/user/upload-company-logo', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      dispatch(uploadCompanyLogoSuccess({ ...data }));
      dispatch(fetchUserSuccess(data as User));

    } catch (error) {

      const { status, data } = error.response;

      if (status === ResponseCode.BAD_REQUEST) {
        dispatch(uploadCompanyLogoBadRequest({
          errorCode: data.errorCode,
          args: data.args,
          message: data.message,
        }));
        
        return;
      }

      dispatch(uploadCompanyLogoFailure(error.response));
    }
  }
);
