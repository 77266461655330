import axios from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { ComponentStateActionTypes } from './component-state.action-types';
import { StoreModel } from '../store.model';
import { setActiveConstructionLayer, setCountries, setProjectConfig, setCounties, setFieldData } from './component-state.actions';
import { Country, ProjectConfig, County, FieldData } from '../../types/domain/construction-data.types';

export const resetActiveConstructionLayerForCurrentCalculation = () => (
  async (dispatch: (action: ComponentStateActionTypes) => void, getState: () => StoreModel) => {
    dispatch(setActiveConstructionLayer(Object.values(getState().currentCalculationData.calculationLayers)[0].constructionLayerId));
  }
);

export const fetchCountries = () => async (dispatch: (action: ComponentStateActionTypes) => void) => {
  dispatch(setCountries(null));

  try {
    const showAllCountries = `${window.location.search}`.indexOf('preview=64b60e66-9735-4911-ad8f-b064aaa57526') > -1;
    const { data } = await axios.get<Country[]>(`/api/countries?all=${showAllCountries}`);

    dispatch(setCountries(data ?? []));
  } catch (error) {
    toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
      autoClose: false,
      closeOnClick: false,
    });
    dispatch(setCountries([]));
  }
};

export const fetchCounties = () => async (dispatch: (action: ComponentStateActionTypes) => void) => {
  dispatch(setCounties(null));

  try{
    const { data } = await axios.get<County[]>('/api/lookup/counties');

    dispatch(setCounties(data));
  } catch (error){
    toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
      autoClose: false,
      closeOnClick: false,
    });  }
};

export const fetchFieldData = () => async (dispatch: (action: ComponentStateActionTypes) => void) => {
  dispatch(setFieldData(null));

  try{
    const { data } = await axios.get<FieldData[]>('/api/lookup/fielddata');

    dispatch(setFieldData(data));
  } catch (error){
    toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
      autoClose: false,
      closeOnClick: false,
    });  }
};

export const fetchProjectConfig = (countryId: string) => async (dispatch: (action : ComponentStateActionTypes) => void) => {
  dispatch(setProjectConfig(null));

  try{
    const { data } = await axios.get<ProjectConfig>(`/api/lookup/projectdetailsconfig/${countryId}`);

    dispatch(setProjectConfig(data));
  } catch (error){
    toast.error(i18n.t('navigation-ui.fetch-countries-failed'), {
      autoClose: false,
      closeOnClick: false,
    });  }
};
