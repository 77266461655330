import React, { ReactNode, useEffect } from 'react';
import { Container } from '@material-ui/core';

import { Header } from '../Header/Header';
import ErrorBar from './ErrorBar';
import { Footer } from '../Footer/Footer';
import styles from './Layout.module.scss';
import { useSelector } from 'react-redux';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';

type Props = {
  children: ReactNode;
};

export function Layout({ children }: Props) {
  const countryId = useSelector(countryIdSelector);
  useEffect(() => {
    if (countryId === 'nl') {
      const script = document.createElement('script');
      script.src = '/live-chat.js';
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [countryId]);

  return (
    <Container disableGutters>
      <div className={styles.page}>
        <Container>
          <Header />
          <ErrorBar />

          <div data-qa-id="pageContent" className={styles.content}>
            {children}
          </div>
          <Footer />
        </Container>
      </div>
    </Container>
  );
}
