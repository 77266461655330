import { StoreModel, CalculationDataModel } from '../store.model';
import { Material, AirCavityGrading, LayerType } from '../../types/domain/construction-data.types';
import { CalculationResult, ProjectDetails, CalculationLayer, AdditionalParameters } from '../../types/domain/calculation-data.types';
import { getCalculationLayerKey } from './current-calculation-data.reducer';

export const currentCalculationResultSelector = (store: StoreModel): CalculationResult | undefined => store.currentCalculationData.calculationResult;

export const currentCalculationDataSelector = (store: StoreModel): CalculationDataModel => store.currentCalculationData;

export const currentSelectedMaterialSelector = (isLinkedMaterial: boolean) => (store: StoreModel): Material | undefined =>
  store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, isLinkedMaterial)]?.material;

export const currentSelectedAirCavityGradingSelector = (store: StoreModel): AirCavityGrading | undefined =>
  store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, false)]?.airCavityGrading;

export const currentSelectedThicknessSelector = (isLinkedMaterial: boolean) => (store: StoreModel): number =>
  store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId, isLinkedMaterial)]?.thicknessMillimetres;

export const previousInsulationCalculationLayerSelector = (store: StoreModel): CalculationLayer | undefined => {
  const constructionLayers = store.constructionData.layers[store.componentState.activeConstructionTypeId];
  const insluationLayerIndexes = constructionLayers
    .map((cl, index) => ({ layer: cl, index }))
    .filter(t => t.layer.layerType === LayerType.Insulation);

  let layerId = 0;
  if (insluationLayerIndexes.length === 1) {
    layerId = insluationLayerIndexes[0].layer?.constructionLayerId ?? 0;
  }
  else {
    layerId = insluationLayerIndexes
      .find(t => t.index < constructionLayers.indexOf(constructionLayers
        .filter(cl => cl.constructionLayerId === store.componentState.activeConstructionLayerId)[0]))?.layer?.constructionLayerId ?? 0;
  }

  return store.currentCalculationData.calculationLayers[getCalculationLayerKey(layerId)];
};

export const currentAnchorPropertiesSelector = (store: StoreModel): any => {
  const layer = store.currentCalculationData.calculationLayers[getCalculationLayerKey(store.componentState.activeConstructionLayerId)];
  return {
    numberOfAnchorsPerMetreSquare: layer?.numberOfAnchorsPerMetreSquare,
    diameterOfAnchorsMillimetres: layer?.diameterOfAnchorsMillimetres,
    anchorPenetrationDepthMillimetres: layer?.anchorPenetrationDepthMillimetres,
    netAreaOfAnchorsPerMetreSquareMillimetresSqrd: layer?.netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
  };
};

export const projectDetailsSelector = (store: StoreModel): ProjectDetails | undefined => store.currentCalculationData.projectDetails;

export const additionalParametersSelector = (store: StoreModel): AdditionalParameters | undefined => store.currentCalculationData.additionalParameters;
