import { Country, ProjectConfig, County, FieldData } from '../../types/domain/construction-data.types';

export const SET_ACTIVE_CONSTRUCTION_TYPE = '[Component State] Set Active Construction Type';
export const SET_ACTIVE_CONSTRUCTION_LAYER = '[Component State] Set Active Construction Layer';
export const SET_COUNTRIES = '[Component State] Set Countries';
export const SET_SELECTED_COUNTRY = '[Component State] Set Selected Country';
export const SET_SELECTED_CULTURE = '[Component State] Set Selected Culture';
export const SET_GTM_CONTAINER = '[Component State] Set GTM Container';
export const SET_PROJECT_CONFIG = '[Component State] Set Project Config';
export const SET_COUNTIES = '[Component State] Set Counties';
export const SET_FIELD_DATA = '[Component State] Set FieldData';

export interface SetActiveConstructionType {
  type: typeof SET_ACTIVE_CONSTRUCTION_TYPE;
  payload: string;
}

export interface SetActiveConstructionLayer {
  type: typeof SET_ACTIVE_CONSTRUCTION_LAYER;
  payload: number;
}

export interface SetCountries {
  type: typeof SET_COUNTRIES;
  payload: Country[] | null;
}

export interface SetSelectedCountry {
  type: typeof SET_SELECTED_COUNTRY;
  payload: Country | null;
}

export interface SetSelectedCulture {
  type: typeof SET_SELECTED_CULTURE;
  payload: string;
}

export interface SetGTMContainer {
  type: typeof SET_GTM_CONTAINER;
}

export interface SetProjectConfig{
  type: typeof SET_PROJECT_CONFIG;
  payload: ProjectConfig | null;

}

export interface SetCounties{
  type: typeof SET_COUNTIES;
  payload: County[] | null;
}

export interface SetFieldData{
  type: typeof SET_FIELD_DATA;
  payload: FieldData[] | null;
}

export type ComponentStateActionTypes =
  | SetActiveConstructionType
  | SetActiveConstructionLayer
  | SetCountries
  | SetSelectedCountry
  | SetSelectedCulture
  | SetGTMContainer
  | SetProjectConfig
  | SetCounties
  | SetFieldData;
