const softHyphenChar = String.fromCharCode(173);

/**
 *
 * @param layerName to suggest hyphens for
 * @returns A recombined layer name string with hyphen suggestions places in words that are long enough and they do not include hyphens already.
 */
export const insertSuggestedHyphenationIfRequired = (layerName: string) => {
  if (!navigator.userAgent.toLowerCase().includes('firefox/') && !navigator.userAgent.toLowerCase().includes('edg/')) {
    return layerName;
  }
  return insertSuggestedHyphenation(layerName);
};

export const insertSuggestedHyphenation = (layerName: string) => {
  return layerName
    .split(' ')
    .map(word => (word.length <= 8 || word.includes(softHyphenChar) || word.includes('-') ? word : `${word.slice(0, 8)}${softHyphenChar}${word.slice(8, word.length)}`))
    .join(' ');
};
