import axios from 'axios';
import {
  fetchConstructionTypesAttempt,
  fetchConstructionTypesFailure,
  fetchConstructionTypesSuccess,
  fetchConstructionTypeNamesSuccess,

  fetchConstructionLayersForTypeAttempt,
  fetchConstructionLayersForTypeFailure,
  fetchConstructionLayersForTypeSuccess,
} from './construction-data.actions';
import {
  startNewCalculation, startEditCalculation, startCopyCalculation
} from '../current-calculation-data/current-calculation-data.action-creators';
import { ConstructionLayer, ConstructionType, Complexity, LayerType } from '../../types/domain/construction-data.types';
import { setActiveConstructionLayer } from '../component-state/component-state.actions';
import { StoreModel } from '../store.model';
import { Calculation } from '../../types/domain/calculation-data.types';

export const fetchConstructionTypes = (countryId: string) => (

  // TODO: Typing needs to be defined
  async (dispatch: (action: any) => void) => {
    dispatch(fetchConstructionTypesAttempt());

    try {
      const { data } = await axios.get(`/api/constructionTypes/${countryId}`);

      dispatch(fetchConstructionTypesSuccess(data));
    } catch (error) {
      dispatch(fetchConstructionTypesFailure((error as any)?.message));
    }
  }
);

export const fetchConstructionTypeNames = (countryId: string) => (
  async (dispatch: (action: any) => void) => {
    try {
      const { data } = await axios.get(`/api/constructionTypes/${countryId}`);

      dispatch(fetchConstructionTypeNamesSuccess(data));
    } catch (error) {
      console.warn('fetchConstructionTypeNames error', error);
    }
  }
);

export const fetchConstructionLayersForType = (constructionTypeId: string, countryId: string, refetch?: boolean) => (

  // TODO: Typing needs to be defined
  async (dispatch: (action: any) => void, getState: () => StoreModel) => {
    dispatch(fetchConstructionLayersForTypeAttempt());

    try {
      const { data, headers } = await axios.get(`/api/constructionLayers/${constructionTypeId}/${countryId}`);
      const constructionTypeNameLocalized = headers['construction-type-name-localized'];

      const ct = getState().constructionData.types.filter(ct => ct.id === constructionTypeId)[0];

      const dataWithPseudoLayers = constructPseudoLayers(ct, data as ConstructionLayer[]);

      dispatch(fetchConstructionLayersForTypeSuccess(constructionTypeId, dataWithPseudoLayers, constructionTypeNameLocalized));

      if (refetch !== true) {
        dispatch(setActiveConstructionLayer((data as ConstructionLayer[])[0].constructionLayerId));
        await startNewCalculation(constructionTypeId, dataWithPseudoLayers)(dispatch, getState);
      }
    } catch (error) {
      console.warn('fetchConstructionLayersForType error', error);
      dispatch(fetchConstructionLayersForTypeFailure((error as any)?.message));
    }
  }
);

export const fetchConstructionLayersForCalculation = (calculation: Calculation, copy: boolean) => (
  async (dispatch: (action: any) => void, getState: () => StoreModel) => {
    dispatch(fetchConstructionLayersForTypeAttempt());

    try {
      const { data, headers } = await axios.get(`/api/constructionLayers/${calculation.constructionTypeId}/${calculation.countryId}`);
      const constructionTypeNameLocalized = headers['construction-type-name-localized'];

      const ct = getState().constructionData.types.filter(ct => ct.id === calculation.constructionTypeId)[0];

      const dataWithPseudoLayers = constructPseudoLayers(ct, data as ConstructionLayer[]);

      dispatch(fetchConstructionLayersForTypeSuccess(calculation.constructionTypeId, dataWithPseudoLayers, constructionTypeNameLocalized));

      dispatch(setActiveConstructionLayer((data as ConstructionLayer[])[0].constructionLayerId));
      if (copy) {
        await startCopyCalculation(calculation, dataWithPseudoLayers)(dispatch);
      } else {
        await startEditCalculation(calculation, dataWithPseudoLayers)(dispatch);
      }
    } catch (error) {
      console.warn('fetchConstructionLayersForCalculation error', error);
      dispatch(fetchConstructionLayersForTypeFailure(error as any));
    }

  }
);

const constructPseudoLayers = (constructionType: ConstructionType, layers: ConstructionLayer[]): ConstructionLayer[] => {
  if (!constructionType) {
    throw new Error('Invalid state: `constructionType` param is null. The `constructPseudoLayers` function requires the `constructionType` param');
  }

  if (constructionType.complexity === Complexity.Simple) {
    return layers;
  }

  return layers.reduce((obj: ConstructionLayer[], item: ConstructionLayer) => {

    if (item.layerType === LayerType.Bridging) {
      const pseudoLayer = {
        constructionLayerId: -9999,
        constructionTypeId: constructionType.id,
        name: 'wood-percentage',
        nameLocalized: 'Wood Percentage',
        materials: [],
        airCavityGradings: [],
        layerType: LayerType.WoodPercentage,
        isWithinBridgingFrame: false,
        isPseudoLayer: true
      };

      return [
        ...obj,
        item,
        pseudoLayer,
      ];
    }

    return [
      ...obj,
      item
    ];
  }, []) as ConstructionLayer[];
};
