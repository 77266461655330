import * as constructionDataActionTypes from '../construction-data/construction-data.action-types';
import * as componentStateActionTypes from './component-state.action-types';
import * as currentCalculationStateActionTypes from '../current-calculation-data/current-calculation-data.action-types';
import * as calculationStateActionTypes from '../calculations/calculations.action-types';
import { ComponentStateModel } from '../store.model';
import { getPreviouslySelectedCulture } from '../../helpers/memory-helper';

export const initialState: ComponentStateModel = {
  activeConstructionTypeId: '',
  activeConstructionLayerId: 0,
  countries: null,
  selectedCountry: null,
  isLoading: false,
  isLoadingConstructionTypes: false,
  isCalculationResultLoading: false,
  error: null,
  calculationResultError: null,
  selectedCulture: getPreviouslySelectedCulture() ?? navigator.language ?? 'en-GB',
  downloadingCalculationId: null,
  isGtmContainerSet: false,
  projectConfig: null,
  counties: null,
  fieldData: null
};

export const componentStateReducer = (
  state: ComponentStateModel = initialState,
  action:
    | constructionDataActionTypes.ConstructionDataActionTypes
    | componentStateActionTypes.ComponentStateActionTypes
    | currentCalculationStateActionTypes.CurrentCalculationDataActionTypes
    | calculationStateActionTypes.CalculationActionTypes
): ComponentStateModel => {
  switch (action.type) {
    // Page Loading State
    case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_ATTEMPT:
      return {
        ...state,
        isLoadingConstructionTypes: true,
        error: null,
      };
    case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT:
    case calculationStateActionTypes.FETCH_CALCULATIONS_ATTEMPT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_SUCCESS:
    case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS:
    case calculationStateActionTypes.FETCH_CALCULATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingConstructionTypes: false,
      };
    case constructionDataActionTypes.FETCH_CONSTRUCTION_TYPES_FAILURE:
    case constructionDataActionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE:
    case calculationStateActionTypes.FETCH_CALCULATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingConstructionTypes: false,
        error: action.error,
      };

    // Component State
    case componentStateActionTypes.SET_GTM_CONTAINER:
      return {
        ...state,
        isGtmContainerSet: true,
      };
    case componentStateActionTypes.SET_ACTIVE_CONSTRUCTION_TYPE:
      return {
        ...state,
        activeConstructionTypeId: action.payload,
      };
    case componentStateActionTypes.SET_ACTIVE_CONSTRUCTION_LAYER:
      return {
        ...state,
        activeConstructionLayerId: action.payload,
      };
    case componentStateActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case componentStateActionTypes.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    case componentStateActionTypes.SET_PROJECT_CONFIG:
      return {
        ...state,
        projectConfig: action.payload,
      };
    case componentStateActionTypes.SET_SELECTED_CULTURE:
      return {
        ...state,
        selectedCulture: action.payload,
      };
    case componentStateActionTypes.SET_COUNTIES:
      return {
        ...state,
        counties: action.payload,
      };
    case componentStateActionTypes.SET_FIELD_DATA:
      return {
        ...state,
        fieldData: action.payload,
      };

    // Component State
    case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_ATTEMPT:
      return {
        ...state,
        isCalculationResultLoading: true,
        calculationResultError: null,
      };
    case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_SUCCESS:
    case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_CLIENT_ERROR:
      return {
        ...state,
        isCalculationResultLoading: false,
      };
    case currentCalculationStateActionTypes.FETCH_CALCULATION_RESULTS_FAILURE:
      return {
        ...state,
        isCalculationResultLoading: false,
        calculationResultError: action.error,
      };

    // Calculcation Downloading State
    case calculationStateActionTypes.DOWNLOAD_CALCULATION_ATTEMPT:
    case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_ATTEMPT:
      return {
        ...state,
        downloadingCalculationId: action.payload,
        error: null,
      };
    case calculationStateActionTypes.DOWNLOAD_CALCULATION_SUCCESS:
    case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_SUCCESS:
      return {
        ...state,
        downloadingCalculationId: null,
      };
    case calculationStateActionTypes.DOWNLOAD_CALCULATION_FAILURE:
    case calculationStateActionTypes.DOWNLOAD_PDF_CALCULATION_FAILURE:
      return {
        ...state,
        downloadingCalculationId: null,
      };
    default:
      return state;
  }
};
