import { Calculation } from '../../types/domain/calculation-data.types';

export const FETCH_CALCULATIONS_ATTEMPT = '[Calculations] Fetch Calculcation Attempt';
export const FETCH_CALCULATIONS_SUCCESS = '[Calculations] Fetch Calculcation Success';
export const FETCH_CALCULATIONS_FAILURE = '[Calculations] Fetch Calculcation Failure';

export const DELETE_CALCULATION_ATTEMPT = '[Calculations] Delete Calculcation Attempt';
export const DELETE_CALCULATION_SUCCESS = '[Calculations] Delete Calculcation Success';
export const DELETE_CALCULATION_FAILURE = '[Calculations] Delete Calculcation Failure';

export const DOWNLOAD_CALCULATION_ATTEMPT = '[Calculations] Download Calculcation Attempt';
export const DOWNLOAD_CALCULATION_SUCCESS = '[Calculations] Download Calculcation Success';
export const DOWNLOAD_CALCULATION_FAILURE = '[Calculations] Download Calculcation Failure';

export const DOWNLOAD_PDF_CALCULATION_ATTEMPT = '[Calculations] Download PDF Calculcation Attempt';
export const DOWNLOAD_PDF_CALCULATION_SUCCESS = '[Calculations] Download PDF Calculcation Success';
export const DOWNLOAD_PDF_CALCULATION_FAILURE = '[Calculations] Download PDF Calculcation Failure';

// Calculations Fetch
export interface FetchCalculationsAttempt {
  type: typeof FETCH_CALCULATIONS_ATTEMPT;
}

export interface FetchCalculationsSuccess {
  type: typeof FETCH_CALCULATIONS_SUCCESS;
  payload: { [key: string]: Calculation };
}

export interface FetchCalculationsFailure {
  type: typeof FETCH_CALCULATIONS_FAILURE;
  error: any;
}

// Calculations Delete
export interface DeleteCalculationAttempt {
  type: typeof DELETE_CALCULATION_ATTEMPT;
  payload: string;
}

export interface DeleteCalculationSuccess {
  type: typeof DELETE_CALCULATION_SUCCESS;
  payload: string;
}

export interface DeleteCalculationFailure {
  type: typeof DELETE_CALCULATION_FAILURE;
  error: any;
  payload: string;
}

// Calculations Download
export interface DownloadCalculationAttempt {
  type: typeof DOWNLOAD_CALCULATION_ATTEMPT;
  payload: string;
}

export interface DownloadCalculationSuccess {
  type: typeof DOWNLOAD_CALCULATION_SUCCESS;
  payload: string;
}

export interface DownloadCalculationFailure {
  type: typeof DOWNLOAD_CALCULATION_FAILURE;
  error: any;
  payload: string;
}

// Direct Calculations PDF Download
export interface DownloadPdfCalculationAttempt {
  type: typeof DOWNLOAD_PDF_CALCULATION_ATTEMPT;
  payload: string;
}

export interface DownloadPdfCalculationSuccess {
  type: typeof DOWNLOAD_PDF_CALCULATION_SUCCESS;
  payload: string;
}

export interface DownloadPdfCalculationFailure {
  type: typeof DOWNLOAD_PDF_CALCULATION_FAILURE;
  error: any;
  payload: string;
}

export type CalculationActionTypes =
  | FetchCalculationsAttempt
  | FetchCalculationsSuccess
  | FetchCalculationsFailure
  | DeleteCalculationAttempt
  | DeleteCalculationSuccess
  | DeleteCalculationFailure
  | DownloadCalculationAttempt
  | DownloadCalculationSuccess
  | DownloadCalculationFailure
  | DownloadPdfCalculationAttempt
  | DownloadPdfCalculationSuccess
  | DownloadPdfCalculationFailure;
