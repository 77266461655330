import React, { useState } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import useSupportedCultures from './useSupportedCultures';
import CultureSelectMobile from './CultureSelect.mobile';
import CultureSelectDesktop from './CultureSelect.desktop';

export default function CultureSelect() {
  
  const { supportedCultures, culture, setCulture } = useSupportedCultures();

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const [ anchorElement, setAnchorElement ] = useState<null | HTMLElement>(null);
  
  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleCultureSelection = (culture: string) => {
    handleClose();
    setCulture(culture);
  };

  if (isMobile || isTablet) {
    return <CultureSelectMobile
      culture={culture} supportedCultures={supportedCultures} handleCultureSelection={handleCultureSelection}

      anchorElement={anchorElement}
      handleMenuButtonClick={handleMenuButtonClick}
      handleClose={handleClose}
    />;
  }

  return <CultureSelectDesktop
      culture={culture} supportedCultures={supportedCultures} handleCultureSelection={handleCultureSelection}

      anchorElement={anchorElement}
      handleMenuButtonClick={handleMenuButtonClick}
      handleClose={handleClose}
  />;
};
