import React from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Button, makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import { User } from '../../types/domain/account.types';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: 'inherit',
      textDecoration: 'inherit',
    },
  })
);

export type PersonalInformationProps = {
  user: User;
  handleSignOutClick: () => void;
};

export default function PersonalInformation(props: PersonalInformationProps) {
  const { user, handleSignOutClick } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h4" component="h4">
        <Trans i18nKey="account-ui.personal-info">Personal Information</Trans>
      </Typography>

      <List>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.name')} secondary={`${user.givenName} ${user.familyName}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.phone-number')} secondary={user.phone} />
        </ListItem>
        <ListItem>
          <ListItemText primary={t('account-ui.titles.email-address')} secondary={user.emailAddress} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <RouterLink to="/update-details" className={classes.link}>
                <Button variant="contained" color="primary">
                  <Trans i18nKey="account-ui.buttons.update-details">Update My Details and Consent</Trans>
                </Button>
              </RouterLink>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <RouterLink to="/reset-password" className={classes.link}>
                <Button variant="outlined" color="secondary">
                  <Trans i18nKey="account-ui.buttons.reset-password">Reset Password</Trans>
                </Button>
              </RouterLink>
            }
          />
        </ListItem>
        <ListItem>
          <Link component="button" variant="body2" onClick={handleSignOutClick}>
            <Trans i18nKey="account-ui.buttons.sign-out">Sign out</Trans>
          </Link>
        </ListItem>
      </List>
    </Grid>
  );
}
