import { Box, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { countryIdSelector } from '../../../store/component-state/component-state.selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1),
        },
        iconContainer: {
            justifyContent: 'center',
            display: 'flex',
        },
        contactInfo: {
            fontSize: theme.typography.caption.fontSize,
            paddingLeft: '5px',
        },
        techSupportContainer: {
            backgroundColor: theme.palette.grey[500],
            color: '#FFF',
        },
        techLink: {
            color: '#FFF',
        }
    })
);

export function TechSupportBar() {

    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);

    return (
        <>
            {countryId &&
                <div className={theme.techSupportContainer}>
                    <Grid className={theme.container} container justify="space-evenly" alignItems="center">
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Box p={1} >
                                <Typography variant="body1">
                                    {parse(t(`by-country.${countryId}.footer.tech-support.blurb`))}&nbsp;
                                    <a href={t(`by-country.${countryId}.footer.tech-support.link-value`)} className={theme.techLink}>
                                        {parse(t(`by-country.${countryId}.footer.tech-support.link-friendly`))}
                                    </a>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container justify="space-evenly" alignItems="center">
                                <Grid item className={theme.iconContainer}>
                                    <MailIcon />
                                </Grid>
                                <Grid item>
                                    <Typography className={theme.contactInfo}>
                                        <a href={'mailto:' + t(`by-country.${countryId}.footer.tech-support.email-value`)} className={theme.techLink}>
                                            {parse(t(`by-country.${countryId}.footer.tech-support.email-friendly`))}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify="space-evenly" alignItems="center">
                                <Grid item className={theme.iconContainer}>
                                    <PhoneIcon />
                                </Grid>
                                <Grid item>
                                    <Typography className={theme.contactInfo}>
                                        <a href={'tel:' + t(`by-country.${countryId}.footer.tech-support.phone-value`)} className={theme.techLink}>
                                            {parse(t(`by-country.${countryId}.footer.tech-support.phone-friendly`))}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    );
}