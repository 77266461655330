export const getPreviouslySelectedCountryId = (): string => {
  return window && window.localStorage
    ? window.localStorage.getItem('previouslySelectedCountryId') || ''
    : '';
};

export const setPreviouslySelectedCountryId = (countryId: string) => {
  if (window && window.localStorage) {
    window.localStorage.setItem('previouslySelectedCountryId', countryId);
  }
};

export const getPreviouslySelectedCulture = (): string | null => {
  const culture = window && window.localStorage
    ? window.localStorage.getItem('previouslySelectedCulture') || ''
    : '';

  return culture !== '' ? culture : null;
};

export const setPreviouslySelectedCulture = (culture: string) => {
  if (window && window.localStorage) {
    window.localStorage.setItem('previouslySelectedCulture', culture);
  }
};
