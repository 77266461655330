import { useEffect } from 'react';
import React from 'react';
import { useRouteMatch, RouteProps, Route, useHistory } from 'react-router-dom';
import { setGTMContainer, setSelectedCountry } from '../../store/component-state/component-state.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAppConfig } from '../../config/config';
import { setPreviouslySelectedCountryId } from '../../helpers/memory-helper';
import TagManager from 'react-gtm-module';
import { countriesSelector, getIsGtmContainerSet } from '../../store/component-state/component-state.selectors';
import { Dispatch } from 'redux';
import { fetchProjectConfig } from '../../store/component-state/component-state.action-creators';

export default function CountryRoute(props: RouteProps) {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:countryId');
  const history = useHistory();
  const countryId = (match?.params as any)?.countryId;
  const countries = useSelector(countriesSelector);
  const isGtmContainerSet = useSelector(getIsGtmContainerSet);

  function getCookies(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const cookiesArray = document.cookie.split(';');

    cookiesArray.forEach(cookie => {
      const [key, value] = cookie.split('=').map(c => c.trim());
      if (key && value) {
        cookies[decodeURIComponent(key)] = decodeURIComponent(value);
      }
    });

    return cookies;
  }

  const getIsAnalyticalCookiesAccepted = (): boolean => {
    const cookieControl = getCookies()['CookieControl'];
    if (!cookieControl) return false;

    try {
      const { optionalCookies } = JSON.parse(cookieControl);
      return optionalCookies?.AnalyticalCookies === 'accepted';
    } catch {
      return false;
    }
  };

  const isAnalyticalCookiesAccepted = getIsAnalyticalCookiesAccepted();

  useEffect(() => {
    const country = countries.find(c => c.countryId.toLowerCase() === countryId.toLowerCase());

    if (country) {
      setPreviouslySelectedCountryId(countryId);
      if (!isGtmContainerSet && isAnalyticalCookiesAccepted) {
        initialiseGTMContainer(countryId, dispatch);
      }
      dispatch(setSelectedCountry(country));
      dispatch(fetchProjectConfig(country.countryId));
    } else {
      console.warn(`No country matching ${countryId}, redirecing to homepage`);
      history.push('/');
    }
  }, [dispatch, countryId, history, countries, isGtmContainerSet, isAnalyticalCookiesAccepted]);

  return <Route {...props} />;
}

function initialiseGTMContainer(countryId: string, dispatch: Dispatch<any>) {
  const { gtmTrackingIds } = getAppConfig()!;
  const gtmTrackingId: string | null | undefined = gtmTrackingIds[countryId.toUpperCase()];

  if (gtmTrackingId) {
    const tagManagerArgs = {
      gtmId: gtmTrackingId,
    };

    TagManager.initialize(tagManagerArgs);

    console.info(`GTM container set to ${gtmTrackingId} for ${countryId}`);
    dispatch(setGTMContainer());
  }
}
