import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ThicknessComparisonResult } from '../../types/domain/calculation-data.types';
import ThicknessBuildUp from './ThicknessBuildUp';

export type ThicknessComparisonProps = {
  thicknessComparison: ThicknessComparisonResult | null | undefined,
};

const useStyle = makeStyles((theme: Theme) => (
  createStyles({
    tagLine: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: '1em',
    },
  })
));

export default function ThicknessComparison(props: ThicknessComparisonProps) {

  const classes = useStyle();
  const { t } = useTranslation();

  const thicknessComparison = props.thicknessComparison;

  return thicknessComparison
    ? <div>
        <Grid container direction="row" spacing={1} alignItems="flex-end">
          <Grid item xs={6}>
            <ThicknessBuildUp
              materialName={thicknessComparison.sourceMaterialName}
              totalConstructionThicknessMillimetres={thicknessComparison.sourceTotalConstructionThicknessMillimetres}
              layerThicknessPercentageNormalised={1.0}
              isKingspanProduct={false} />
          </Grid>
          <Grid item xs={6}>
            <ThicknessBuildUp
              materialName={thicknessComparison.comparisonMaterialName}
              totalConstructionThicknessMillimetres={thicknessComparison.comparisonTotalConstructionThicknessMillimetres}
              layerThicknessPercentageNormalised={1 - thicknessComparison.layerThicknessSavingPercentage / 100}
              isKingspanProduct={true} />
          </Grid>
        </Grid>

        <div className={classes.tagLine}>
          <div data-qa-id="thickness-comparison-text">
            {t('thickness-comparison-ui.you-will-save', { ...thicknessComparison })}
          </div>
          <div data-qa-id="thickness-comparison-product">
            {thicknessComparison.comparisonMaterialName}        
          </div>
        </div>
      </div>
    : <div />;
}
