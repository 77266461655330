import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { countryIdSelector } from '../../../store/component-state/component-state.selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
        }
    })
);

export function Contact() {

    const { t } = useTranslation();
    const theme = useStyles();
    const countryId = useSelector(countryIdSelector);

    const label1: string = t(`by-country.${countryId}.footer.contacts.contact-number-1.label`);
    const contactNumber1: string = t(`by-country.${countryId}.footer.contacts.contact-number-1.value`);
    const label2: string = t(`by-country.${countryId}.footer.contacts.contact-number-2.label`);
    const contactNumber2: string = t(`by-country.${countryId}.footer.contacts.contact-number-2.value`);

    return (
        <>
            {label1.length > 0 && contactNumber1.length > 0 &&
                <div>
                    <Typography variant="body1">
                        {parse(label1)}:
                        <br />
                        <a href={`tel:${contactNumber1}`} className={theme.link}>
                            {parse(t(`by-country.${countryId}.footer.contacts.contact-number-1.friendly`))}
                        </a>

                    </Typography>
                </div>
            }
            {label2.length > 0 && contactNumber2.length > 0 &&
                <div>
                    <Typography variant="body1">
                        {parse(label2)}:
                        <br />
                        <a href={`tel:${contactNumber2}`} className={theme.link}>
                            {parse(t(`by-country.${countryId}.footer.contacts.contact-number-2.friendly`))}
                        </a>

                    </Typography>
                </div>
            }
        </>
    );
}
