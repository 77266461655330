import { User } from '../../types/domain/account.types';

export const FETCH_USER_ATTEMPT = '[Account] Fetch User Attempt';
export const FETCH_USER_SUCCESS = '[Account] Fetch User Success';
export const FETCH_USER_FAILURE = '[Account] Fetch User Failure';
export const UPLOAD_COMPANY_LOGO_ATTEMPT = '[Account] Upload Company Logo Attempt';
export const UPLOAD_COMPANY_LOGO_SUCCESS = '[Account] Upload Company Logo Success';
export const UPLOAD_COMPANY_LOGO_BAD_REQUEST = '[Account] Upload Company Logo Bad Request';
export const UPLOAD_COMPANY_LOGO_FAILURE = '[Account] Upload Company Logo Attempt Failure';
export const GDPR_CONSENT_RESPONSE_SELECTED = '[Account] GDPR Consent response selected';
export const RECORD_GDPR_CONSENT_SUCCESS = '[Account] Record GDPR Consent response success';
export const RECORD_GDPR_CONSENT_FAILURE = '[Account] Record GDPR Consent response failure';
export const RECORD_GDPR_CONSENT_ATTEMPT = '[Account] Record GDPR Consent response attempt';
export const SET_IS_CONFIRMED_GUEST = '[Account] Set IsConfirmedGuest';

export interface FetchUserAttempt {
  type: typeof FETCH_USER_ATTEMPT;
}

export interface FetchUserSuccess {
  type: typeof FETCH_USER_SUCCESS;
  payload: { user: User, isNewUser: boolean };
}

export interface FetchUserFailure {
  type: typeof FETCH_USER_FAILURE;
  error: any;
}

export interface GDPRConsentResponseSelected {
  type: typeof GDPR_CONSENT_RESPONSE_SELECTED;
  payload: boolean;
}

export interface RecordGDPRConsentAttempt {
  type: typeof RECORD_GDPR_CONSENT_ATTEMPT;
}

export interface RecordGDPRConsentSuccess {
  type: typeof RECORD_GDPR_CONSENT_SUCCESS;
}

export interface RecordGDPRConsentFailure {
  type: typeof RECORD_GDPR_CONSENT_FAILURE;
}

export interface UploadCompanyLogoAttempt {
  type: typeof UPLOAD_COMPANY_LOGO_ATTEMPT;
}

export interface UploadCompanyLogoSuccess {
  type: typeof UPLOAD_COMPANY_LOGO_SUCCESS;
  payload: any;
}

export interface UploadCompanyLogoBadRequest {
  type: typeof UPLOAD_COMPANY_LOGO_BAD_REQUEST;
  error: any;
}

export interface UploadCompanyLogoFailure {
  type: typeof UPLOAD_COMPANY_LOGO_FAILURE;
  error: any;
}

export interface SetIsConfirmedGuest {
  type: typeof SET_IS_CONFIRMED_GUEST;
  payload: boolean;
}


export type AccountActionTypes =
  | FetchUserAttempt
  | FetchUserSuccess
  | FetchUserFailure
  | GDPRConsentResponseSelected
  | UploadCompanyLogoAttempt
  | UploadCompanyLogoBadRequest
  | UploadCompanyLogoFailure
  | UploadCompanyLogoSuccess
  | SetIsConfirmedGuest;