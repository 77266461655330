import axios from 'axios';
import {
  fetchNewsItemSuccess,
  fetchNewsItemFailure,
  fetchNewsItemSkipped,
  fetchNewsItemAttempt
} from './news-item.actions';
import { NewsItem } from '../../types/domain/news-item.types';
import { NewsItemActionTypes } from './news-item.action-types';
import { StoreModel } from '../store.model';

export type RequestResult = {
  success: boolean,
  error: string
}

export const fetchNewsItem = (constructionType: string, countryId: string, language: string) =>

  async (dispatch: (action: NewsItemActionTypes) => void, getState: () => StoreModel) => {
    dispatch(fetchNewsItemAttempt());

    try {
      const response  = await axios.get('/api/NewsItems', { params: {
        countryId,
        constructionType: constructionType,
        language: language,
      }});

      if (getState().componentState.activeConstructionTypeId !== constructionType) {
        // Fixes React sequencing issue that resulted on news items from previous construction type to be displayed.
        console.info('Ignored old news item response', {currentConstructionType: getState().componentState.activeConstructionTypeId, oldConstructionType: constructionType });
        dispatch(fetchNewsItemSkipped());
        return;
      }

      if (response.status === 200) {
        dispatch(fetchNewsItemSuccess(response.data as NewsItem));
      } else if (response.status === 204) {
        dispatch(fetchNewsItemFailure('No matching news item was found'));
      } else {
        dispatch(fetchNewsItemFailure(`Unexpected status: ${response.status}`));
      }

    } catch (error) {
      dispatch(fetchNewsItemFailure((error ?? {}).toString()));
    }
};
