import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { buildRouteWithCountry } from '../../helpers/routeHelper';
import { getPreviouslySelectedCountryId } from '../../helpers/memory-helper';
import { authenticationActionCreatorOverrides } from '../../store/authentication/authentication.action-creators';
import { Layout } from '../Layout/Layout';
import { LayoutMinimal } from '../Layout/LayoutMinimal';
import { store } from '../../store/store';
import { theme } from '../../theme/theme';
import ConstructionTypeSelection from '../ConstructionTypeSelection/ConstructionTypeSelection';
import CalculationPage from '../CalculationPage/CalculationPage';
import ChooseCountry from '../ChooseCountry/ChooseCountry';
import CountryRoute from './CountryRoute';
import AccountDetails from '../AccountDetails/AccountDetails';
import PasswordReset from '../PasswordReset/PasswordReset';
import MyCalculations from '../MyCalculations/MyCalculations';
import logo from '../../assets/images/kingspan-logo.svg';
import i18n from '../../i18n';
import { I18nextProvider } from 'react-i18next';
import authProviderFactory, { launchRedirectToPasswordReset, updateDetailsAuthConfig, buildAuthenticationParameters } from '../../authentication/authProvider';
import * as Msal from 'msal';
import interceptors from '../../interceptors/interceptors';
import AzureAD from 'react-aad-msal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import CountriesProvider from './CountriesProvider';

const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

export function App() {
  authenticationActionCreatorOverrides(store);

  authProviderFactory().registerReduxStore(store);

  interceptors.init();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <CountriesProvider>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/">
                    <LayoutMinimal>
                      <ChooseCountry />
                    </LayoutMinimal>
                  </Route>
                  <Layout>
                    <Switch>
                      <CountryRoute path="/:countryId/account">
                        <AzureAD provider={authProviderFactory()} forceLogin={true}>
                          <AccountDetails />
                        </AzureAD>
                      </CountryRoute>
                      <Route path="/account-updated">
                        <Redirect to={buildRouteWithCountry(getPreviouslySelectedCountryId(), '/account')} />
                      </Route>
                      <Route path="/reset-password" render={() => {
                        launchRedirectToPasswordReset();
                        return (<span>{i18n.t('navigation-ui.redirecting-ellipsis')}</span>);
                      }} />
                      <Route path="/update-details" render={() => {
                        const myMSALObj = new Msal.UserAgentApplication(updateDetailsAuthConfig());
                        setTimeout(() => myMSALObj.loginRedirect(buildAuthenticationParameters('updateDetails')), 250);
                        return (<span>{i18n.t('navigation-ui.redirecting-ellipsis')}</span>);
                      }} />
                      <Route path="/password-reset">
                        <PasswordReset />
                      </Route>
                      <CountryRoute exact path="/:countryId">
                        <ConstructionTypeSelection />
                      </CountryRoute>
                      <CountryRoute path="/:countryId/calculation/:constructionTypeId/:calculationId?">
                        <CalculationPage />
                      </CountryRoute>
                      <CountryRoute path="/:countryId/my-calculations">
                        <AzureAD provider={authProviderFactory()} forceLogin={true}>
                          <MyCalculations />
                        </AzureAD>
                      </CountryRoute>
                      <Redirect to="/" />
                    </Switch>
                  </Layout>
                </Switch>
              </BrowserRouter>
            </CountriesProvider>
            <ToastContainer />
          </Suspense>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  );
}


