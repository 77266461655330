import React, { useState } from 'react';
import { Box, IconButton, Drawer, makeStyles, createStyles, Theme, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { deepPurple } from '@material-ui/core/colors';
import { User } from '../../../types/domain/account.types';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from '../../../types/domain/navigation-item.types';
import CultureSelect from '../CultureSelect/CultureSelect';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    signedInAvatar: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    drawerList: {
      minWidth: 240,
    },
    link: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
  })
));

export type NavigationMobileProps = {
  isAuthenticated: boolean;
  user: User | undefined;
  navigationItems: NavigationItem[]; 
  handleSignInClick: () => void;
  handleSignOutClick: () => void;
};

export default function NavigationMobile(props: NavigationMobileProps) {

  const { isAuthenticated, user, navigationItems, handleSignInClick, handleSignOutClick } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [ drawerOpen, setDrawerOpen ] = useState(false);

  return (
    <Box>
      <IconButton onClick={() => setDrawerOpen(true)}><MenuIcon /></IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box className={classes.drawerList}>
          <List dense={false}>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <ListItemIcon><CloseIcon /></ListItemIcon>
              <ListItemText primary={t('navigation-ui.close')}></ListItemText>
            </ListItem>

            {navigationItems.map(navigationItem => {
              if (navigationItem.show) {
                return (
                  <RouterLink key={navigationItem.id} to={navigationItem.route} className={classes.link} onClick={() => setDrawerOpen(false)}>
                    <ListItem button>
                      <ListItemIcon><Avatar>{navigationItem.icon()}</Avatar></ListItemIcon>
                      <ListItemText primary={t(navigationItem.translationKey)}></ListItemText>
                    </ListItem>
                  </RouterLink>
                );
              }
              return null;
            })}

            <CultureSelect />
            
            {isAuthenticated
              ? 
              <ListItem button onClick={handleSignOutClick}>
                <ListItemAvatar><Avatar src="not-valid.jpg" className={classes.signedInAvatar}>{getInitials(user)}</Avatar></ListItemAvatar>
                <ListItemText primary={t('navigation-ui.sign-out')} secondary={getUserName(user)}></ListItemText>
              </ListItem>
              :
              <ListItem button onClick={handleSignInClick}>
                <ListItemAvatar><Avatar src="not-valid.jpg" /></ListItemAvatar>
                <ListItemText primary={t('navigation-ui.sign-in')}></ListItemText>
              </ListItem>
            }
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

const getInitials = (user: User | undefined): string => {
  const name = getUserName(user);
  
  return name === '' ? name : name[0];
};

const getUserName = (user: User | undefined): string => {
  if (user === undefined) {
    return '';
  }

  return `${user.givenName} ${user.familyName}`;
};
