import React from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';
import { Grid } from '@material-ui/core';
import Navigation from './Navigation/Navigation';

export function Header() {
  return (
    <Grid container justify="space-between" alignItems="center" className={styles.header}>
      <Grid item>
        {/*
          IMPORTANT NOTE: GTM (Google Tag Management) is required to be per country, and GTM cannot be reinitalised (no possible way to clear out tracking of previous countries)
          So we intentionally redirect the whole browser here, rather than redirecting within the SPA, to force a new GTM session.
        */}
        <a href={'/'}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </a>
      </Grid>
      <Grid item>
        <Navigation />
      </Grid>
    </Grid>
  );
}
