import React from 'react';
import { isEmpty } from 'lodash';
import NavigationMobile from './Navigation.mobile';
import NavigationTablet from './Navigation.tablet';
import NavigationDesktop from './Navigation.desktop';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../../store/authentication/authentication.selectors';
import authProviderFactory from '../../../authentication/authProvider';
import { loggedInUserSelector } from '../../../store/account/account.selectors';
import { countryIdSelector } from '../../../store/component-state/component-state.selectors';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FolderIcon from '@material-ui/icons/Folder';
import { buildRouteWithCountry } from '../../../helpers/routeHelper';
import { NavigationItemId } from '../../../types/domain/navigation-item.types';

export default function Navigation() {

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const countryId = useSelector(countryIdSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const navigationProps = {
    isAuthenticated,
    user: useSelector(loggedInUserSelector),
    navigationItems: [
      {
        id: NavigationItemId.NEW_CALCULATION,
        route: buildRouteWithCountry(countryId, '/'),
        translationKey: 'navigation-ui.new-calculation',
        show: true,
        icon: () => <AddRoundedIcon />,
      },
      {
        id: NavigationItemId.MY_CALCULATIONS,
        route: buildRouteWithCountry(countryId, '/my-calculations'),
        translationKey: 'navigation-ui.my-calculations',
        show: isAuthenticated,
        icon: () => <FolderIcon />,
      },
      {
        id: NavigationItemId.MY_ACCOUNT,
        route: buildRouteWithCountry(countryId, '/account'),
        translationKey: 'navigation-ui.my-account',
        show: isAuthenticated,
        icon: () => <AccountCircleIcon />,
      }
    ],
    handleSignInClick: () => {
      authProviderFactory().login();
    },
    handleSignOutClick: () => {
      authProviderFactory().logout();
    },
  };

  if (isEmpty(countryId)) {
    return null;
  }

  if (isMobile) {
    return <NavigationMobile {...navigationProps} />;
  }

  if (isTablet) {
    return <NavigationTablet {...navigationProps} />;
  }

  return <NavigationDesktop {...navigationProps} />;
}
