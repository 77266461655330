import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { User } from '../../types/domain/account.types';
import { loggedInUserSelector, uploadCompanyLogoSelector } from '../../store/account/account.selectors';
import PersonalInformation from './PersonalInformation';
import BusinessInformation from './BusinessInformation';
import authProviderFactory from '../../authentication/authProvider';
import { UploadCompanyLogoModel } from '../../types/application/upload-company-logo.model';

export default function AccountDetails() {

  useTranslation();

  const loggedInUser: User | undefined = useSelector(loggedInUserSelector);
  const uploadCompanyLogoState: UploadCompanyLogoModel = useSelector(uploadCompanyLogoSelector);

  const handleSignOutClick = () => {
    authProviderFactory().logout();
  };
  
  return <>
    <Box marginBottom={4}>
      <Typography variant="h1" component="h2">
        <Trans i18nKey='account-ui.title'>
          Account settings
        </Trans>
      </Typography>
    </Box>

    {loggedInUser === undefined
      ? <Box>
        <Trans i18nKey='account-ui.user-not-logged-in'>
          No logged in user to display.
        </Trans>
      </Box>
      : <Grid container>
        <BusinessInformation user={loggedInUser} uploadCompanyLogoState={uploadCompanyLogoState} />
        <PersonalInformation user={loggedInUser} handleSignOutClick={handleSignOutClick} />
      </Grid>
    }
  </>;
}
