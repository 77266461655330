import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery,
  FormControl,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trans } from 'react-i18next';
import { getLinks } from '../../common/links';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fullWidthButton: {
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    formControl: {
      width: '100%',
    },
    buttonWrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.info.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    checkboxLabel: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
      alignItems: 'start',
    },
    checkbox: {
      paddingTop: 0,
    },
  })
);

export interface EmailDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSend: (emailAddress: string, gdprConsentGiven: boolean) => void;
  sending: boolean;
}

export default function EmailDialog(props: EmailDialogProps) {
  const { open, handleClose, handleSend, sending } = props;

  const classes = useStyles();

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const [guestEmail, setGuestEmail] = React.useState('');
  const [sendingState, setSendingState] = React.useState(false);
  const [gdprConsentGiven, setGdprConsentChecked] = React.useState(false);

  const links = getLinks();
  const directMarketingConsentLink = links.marketingConsent;
  const privacyPolicyLink = links.privacyPolicy;

  const onGuestEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGuestEmail(event.target.value as string);
  };

  const handleGdprConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGdprConsentChecked(event.target.checked);
  };

  React.useEffect(() => {
    setSendingState(sending);
  }, [sending]);

  return (
    <Dialog open={open} keepMounted onClose={() => handleClose()} fullWidth={true} maxWidth="sm">
      <DialogTitle disableTypography>
        <Box marginRight={4}>
          <Typography variant="h4">
            <Trans i18nKey="email-dialog.guest-title">email-dialog.guest-title</Trans>
          </Typography>
        </Box>
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box>
          <Trans i18nKey="email-dialog.guest-subtitle">email-dialog.guest-subtitle</Trans>
          <FormControlLabel
            className={classes.checkboxLabel}
            value="start"
            control={<Checkbox checked={gdprConsentGiven} onChange={handleGdprConsentChange} color="primary" className={classes.checkbox} />}
            label={
              <Trans
                i18nKey="account-ui.gdpr-consent-question"
                defaults="I would like to hear about Kingspan promotions, products, services, event and other activities that may be of interest. For full details on how we use your personal data, please view our <anchorlinkMarketing>Direct Marketing Consent Notice</anchorlinkMarketing>.<br/><br/>We will use your personal data for marketing and retargeting purposes and you have the right to withdraw this consent at any time as explained in our <anchorlinkPrivacy>Privacy Policy.</anchorlinkPrivacy>"
                values={{ privacyPolicyLink: privacyPolicyLink, directMarketingConsentLink: directMarketingConsentLink }}
                components={{
                  anchorlinkMarketing: (
                    <a href={directMarketingConsentLink} rel="noopener noreferrer" target="_blank">
                      Direct Marketing Consent Notice
                    </a>
                  ),
                  anchorlinkPrivacy: (
                    <a href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">
                      Privacy Policy
                    </a>
                  ),
                }}
              />
            }
            labelPlacement="end"
          />
        </Box>
        <Box mx={2} my={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField id="project-name" label={<Trans i18nKey="email-dialog.email-address"></Trans>} variant="outlined" className={classes.formControl} value={guestEmail} onChange={onGuestEmailChange} />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid container justify="flex-end">
          <Grid item xs={12} sm={'auto'} className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={sendingState}
              className={isMobileView ? classes.fullWidthButton : ''}
              onClick={() => {
                handleSend(guestEmail, gdprConsentGiven);
              }}
            >
              <Trans i18nKey="email-dialog.send-email">email-dialog.send-email</Trans>
            </Button>
            {sendingState && <CircularProgress size={24} className={`${classes.buttonProgress}`} />}
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Button onClick={() => handleClose()} className={isMobileView ? classes.fullWidthButton : ''} color="primary" data-qa-id="cancel-btn">
              <Trans i18nKey="email-dialog.cancel">email-dialog.cancel</Trans>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
