import React from 'react';
import { activeConstructionLayerSelector, activeConstructionTypeSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedThicknessSelector, } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionLayer, ConstructionType } from '../../types/domain/construction-data.types';
import ThicknessSlider from './ThicknessSlider';
import { setWoodPercentage } from '../../store/current-calculation-data/current-calculation-data.actions';
import { setMaterialParameter } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import { countryIdSelector } from '../../store/component-state/component-state.selectors';

export default function WoodPercentage() {
  const dispatch = useDispatch();
  const constructionType: ConstructionType | undefined = useSelector(activeConstructionTypeSelector);
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const currentWoodPercentage: number = useSelector(currentSelectedThicknessSelector(false)); //using thickness property as % on pseudo layer
  const countryId = useSelector(countryIdSelector);
  const handleChange = (value: number) => {
    dispatch(setMaterialParameter(setWoodPercentage, layer, value, false));
  };
if (countryId === 'gb' || countryId === 'ie') {
    return (
    <ThicknessSlider
      labelKey='wood-percentage-label'
      handleChange={handleChange}
      selectedThickness={currentWoodPercentage}
      disabled={true}
      marks={[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]}
      min={5}
      max={25}
      defaultValue={Math.round((constructionType?.defaultWoodPercentage ?? 0.2) * 100)} // Added Math.round here to get round the JS floating point error with 0.07 & 0.14
      units='%'
      isValueFixed={false} />
  );
}
else {
  return (
    <ThicknessSlider
      labelKey='wood-percentage-label'
      handleChange={handleChange}
      selectedThickness={currentWoodPercentage}
      disabled={false}
      marks={[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]}
      min={5}
      max={25}
      defaultValue={Math.round((constructionType?.defaultWoodPercentage ?? 0.2) * 100)} // Added Math.round here to get round the JS floating point error with 0.07 & 0.14
      units='%'
      isValueFixed={false} />
  );
}
  

}
