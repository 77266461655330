import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Trans } from 'react-i18next';

export type NoSliderProps = {
  labelKey: string,
  selectedThickness: number,
  units: string,
}

export default function NoSlider(props: NoSliderProps) {
  const {
    labelKey,
    selectedThickness,
  } = props;

  return (
    <Box marginTop={4}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <Trans i18nKey={`calculation-ui.${labelKey}`}>Thickness</Trans>
          <div>{ selectedThickness }</div>
        </Grid>
      </Grid>
    </Box>
  );
}
