import {
  FetchNewsItemSuccess,
  FetchNewsItemFailure,
  FetchNewsItemSkipped,
  FetchNewsItemAttempt,
  ClearNewsItem,
  FETCH_NEWS_ITEM_SUCCESS,
  FETCH_NEWS_ITEM_FAILURE,
  FETCH_NEWS_ITEM_SKIPPED,
  FETCH_NEWS_ITEM_ATTEMPT,
  CLEAR_NEWS_ITEM,
} from './news-item.action-types';
import { NewsItem } from '../../types/domain/news-item.types';

export const fetchNewsItemAttempt = (): FetchNewsItemAttempt => ({
  type: FETCH_NEWS_ITEM_ATTEMPT,
});

export const fetchNewsItemSuccess = (newsItem: NewsItem): FetchNewsItemSuccess => ({
  type: FETCH_NEWS_ITEM_SUCCESS,
  payload: newsItem
});

export const fetchNewsItemFailure = (error: any): FetchNewsItemFailure => ({
  type: FETCH_NEWS_ITEM_FAILURE,
  error: error,
});

export const fetchNewsItemSkipped = (): FetchNewsItemSkipped => ({
  type: FETCH_NEWS_ITEM_SKIPPED,
});

export const clearNewsItem = (): ClearNewsItem => ({
  type: CLEAR_NEWS_ITEM,
});
