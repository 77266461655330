
import { cloneDeep } from 'lodash';
import * as he from 'he';
import * as actionTypes from './construction-data.action-types';
import { ConstructionDataModel } from '../store.model';

export const initialState: ConstructionDataModel = {
  types: [],
  layers: {}
};

export const constructionDataReducer = (
  state: ConstructionDataModel = initialState,
  action: actionTypes.ConstructionDataActionTypes,
  ): ConstructionDataModel => {
  switch (action.type) {

    case actionTypes.FETCH_CONSTRUCTION_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
      };

    case actionTypes.FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS:
    {
      const newState = {
        ...state,
        types: cloneDeep(state.types ?? []),
      };

      // Build dictionary of the latest names where the key is the Construction Type ID
      const latestNames: { [key: string]: string } = {};
      action.payload.forEach(ct => latestNames[ct.id] = ct.nameLocalized);

      // Update the name of each Construction Type in our state, from the latest known names
      newState.types.forEach(ct => ct.nameLocalized = latestNames[ct.id] ?? ct.nameLocalized);

      return newState;
    }

    case actionTypes.FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS:
    {
      const newState = {
        ...state,
        types: cloneDeep(state.types ?? []),
        layers: {
          ...state.layers,
          [action.payload.constructionType]: action.payload.layers,
        },
      };

      const constructionType = newState.types.find(ct => ct.id === action.payload.constructionType);
      if (constructionType) {
        constructionType.nameLocalized = he.decode(action.payload.constructionTypeNameLocalized);
      }

      return newState;
    }

    default:
      return state;
  }
};
