import { AppConfig } from '../types/config/config.types';

let appConfig: AppConfig | null = null;

export function setAppConfig(newAppConfig: AppConfig) {
  appConfig = newAppConfig;
}

export function getAppConfig() {
  return appConfig;
}
