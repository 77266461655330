import {
  SET_MATERIAL,
  SET_THICKNESS,
  SetMaterial,
  SetThickness,
  NEW_CALCULATION_STARTED,
  NewCalculcationStarted,
  SET_ANCHOR_DIAMETER,
  SET_ANCHOR_PER_SQUARE_METRE,
  SetAnchorDiameter,
  SetAnchorAmountPerSquareMetre,
  FETCH_CALCULATION_RESULTS_ATTEMPT,
  FETCH_CALCULATION_RESULTS_SUCCESS,
  FETCH_CALCULATION_RESULTS_CLIENT_ERROR,
  FETCH_CALCULATION_RESULTS_FAILURE,
  FetchCalculationResultsAttempt,
  FetchCalculationResultsSuccess,
  FetchCalculationResultsClientError,
  FetchCalculationResultsFailure,
  REMOVE_LINKED_LAYER,
  CREATE_LINKED_LAYER,
  RemoveLinkedLayer,
  CreateLinkedLayer,
  SetWoodPercentage,
  SET_WOOD_PERCENTAGE,
  SET_CALCULATED_LAYER_VALUE,
  SetCalculatedLayerValue,
  SetAnchorPenetration,
  SET_ANCHOR_PENETRATION,
  SET_NET_AREA_PER_METRE_SQUARE,
  SetNetAreaPerMetreSquare,
  SetProjectDetails,
  SET_PROJECT_DETAILS,
  SetAdditionalParameters,
  SET_ADDITIONAL_PARAMETERS,
  EditCalculcationStarted,
  EDIT_CALCULATION_STARTED,
  CLEAR_CURRENT_CALCULATION,
  ClearCurrentCalculation,
  COPY_CALCULATION_STARTED,
  CopyCalculcationStarted,
  SetAirCavityGrading,
  SET_AIR_CAVITY_GRADING
}
  from './current-calculation-data.action-types';
import { Material, AirCavityGrading, ConstructionLayer } from '../../types/domain/construction-data.types';
import { CalculationLayer, CalculationResult, ProjectDetails, Calculation, AdditionalParameters } from '../../types/domain/calculation-data.types';

export const setProjectDetails = (projectDetails: ProjectDetails): SetProjectDetails => ({
  type: SET_PROJECT_DETAILS,
  payload: projectDetails,
});

export const setAdditionalParameters = (additionalParameters: AdditionalParameters): SetAdditionalParameters => ({
  type: SET_ADDITIONAL_PARAMETERS,
  payload: additionalParameters,
});

export const setAirCavityGradingInternal = (airCavityGrading: AirCavityGrading, constructionLayerId: number): SetAirCavityGrading => ({
  type: SET_AIR_CAVITY_GRADING,
  payload: {
    airCavityGrading,
    constructionLayerId,
  }
});

export const setMaterialInternal = (material: Material, constructionLayerId: number, isLinkedLayer: boolean): SetMaterial => ({
  type: SET_MATERIAL,
  payload: {
    material,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setThickness = (thicknessMillimetres: number, constructionLayerId: number, isLinkedLayer: boolean): SetThickness => ({
  type: SET_THICKNESS,
  payload: {
    thicknessMillimetres,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const newCalculationStarted = (layers: { [key: string]: CalculationLayer }, constructionTypeId: string): NewCalculcationStarted => ({
  type: NEW_CALCULATION_STARTED,
  payload: {
    calculationLayers: layers,
    constructionTypeId
  }
});


export const editCalculationStarted = (
  calculationId: string,
  calculationAccessCode: string,
  layers: { [key: string]: CalculationLayer },
  constructionTypeId: string,
  projectDetails: ProjectDetails,
  calculationResult: CalculationResult,
  additionalParameters: AdditionalParameters,
): EditCalculcationStarted => ({
  type: EDIT_CALCULATION_STARTED,
  payload: {
    calculationId,
    calculationAccessCode,
    calculationLayers: layers,
    constructionTypeId,
    projectDetails,
    calculationResult,
    additionalParameters
  }
});


export const copyCalculationStarted = (layers: { [key: string]: CalculationLayer }, constructionTypeId: string, projectDetails: ProjectDetails, additionalParameters: AdditionalParameters): CopyCalculcationStarted => ({
  type: COPY_CALCULATION_STARTED,
  payload: {
    calculationLayers: layers,
    constructionTypeId,
    projectDetails,
    additionalParameters
  }
});

export const createLinkedLayer = (calculationLayer: CalculationLayer): CreateLinkedLayer => ({
  type: CREATE_LINKED_LAYER,
  payload: {
    calculationLayer,
  }
});

export const removeLinkedLayer = (constructionLayerId: number): RemoveLinkedLayer => ({
  type: REMOVE_LINKED_LAYER,
  payload: {
    constructionLayerId,
  }
});

export const setAnchorDiameter = (diameterOfAnchorsMillimetres: number, constructionLayerId: number, isLinkedLayer: boolean): SetAnchorDiameter => ({
  type: SET_ANCHOR_DIAMETER,
  payload: {
    diameterOfAnchorsMillimetres,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setAnchorAmountPerSquareMetre = (numberOfAnchorsPerMetreSquare: number, constructionLayerId: number, isLinkedLayer: boolean): SetAnchorAmountPerSquareMetre => ({
  type: SET_ANCHOR_PER_SQUARE_METRE,
  payload: {
    numberOfAnchorsPerMetreSquare,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setAnchorPenetration = (anchorPenetrationDepthMillimetres: number | undefined, constructionLayerId: number, isLinkedLayer: boolean): SetAnchorPenetration => ({
  type: SET_ANCHOR_PENETRATION,
  payload: {
    anchorPenetrationDepthMillimetres,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setNetAreaPerMetreSquare = (netAreaOfAnchorsPerMetreSquareMillimetresSqrd: number, constructionLayerId: number, isLinkedLayer: boolean): SetNetAreaPerMetreSquare => ({
  type: SET_NET_AREA_PER_METRE_SQUARE,
  payload: {
    netAreaOfAnchorsPerMetreSquareMillimetresSqrd,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setWoodPercentage = (percentage: number, constructionLayerId: number, isLinkedLayer: boolean): SetWoodPercentage => ({
  type: SET_WOOD_PERCENTAGE,
  payload: {
    percentage,
    constructionLayerId,
    isLinkedLayer,
  }
});

export const setCalculatedLayerValue = (bridgingLayer: ConstructionLayer, insulationLayer: ConstructionLayer, airCavityLayer: ConstructionLayer): SetCalculatedLayerValue => ({
  type: SET_CALCULATED_LAYER_VALUE,
  payload: {
    bridgingLayer,
    insulationLayer,
    airCavityLayer,
  }
});

export const clearCurrentCalculation = (): ClearCurrentCalculation => ({
  type: CLEAR_CURRENT_CALCULATION,
});

export const fetchCalculationResultsAttempt = (): FetchCalculationResultsAttempt => ({
  type: FETCH_CALCULATION_RESULTS_ATTEMPT,
});

export const fetchCalculationResultsSuccess = (data: Calculation): FetchCalculationResultsSuccess => ({
  type: FETCH_CALCULATION_RESULTS_SUCCESS,
  payload: data,
});

export const fetchCalculationResultsFailure = (error: any): FetchCalculationResultsFailure => ({
  type: FETCH_CALCULATION_RESULTS_FAILURE,
  error,
});

export const fetchCalculationResultsClientError = (): FetchCalculationResultsClientError => ({
  type: FETCH_CALCULATION_RESULTS_CLIENT_ERROR,
});
