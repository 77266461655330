import * as actionTypes from './calculations.action-types';
import { CalculationCollectionModel } from '../store.model';

export const initialState: CalculationCollectionModel = {
  calculations: {},
  deletingCalculations: []
};

export const calculationsReducer = (
  state: CalculationCollectionModel = initialState,
  action: actionTypes.CalculationActionTypes,
): CalculationCollectionModel => {
  switch (action.type) {

    case actionTypes.FETCH_CALCULATIONS_SUCCESS:
      return {
        ...state,
        calculations: {
          ...action.payload
        }
      };
    case actionTypes.DELETE_CALCULATION_ATTEMPT:
      return {
        ...state,
        deletingCalculations: [
          ...state.deletingCalculations,
          action.payload,
        ],
      };
    case actionTypes.DELETE_CALCULATION_SUCCESS:
      const calcs = Object
        .values(state.calculations)
        .filter(c => c.calculationId !== action.payload)
        .reduce((obj, calc) => { return { ...obj, [calc.calculationId]: calc }; }, {});

      return {
        calculations: {
          ...calcs
        },
        deletingCalculations: [
          ...(state.deletingCalculations.filter(c => c !== action.payload))
        ],
      };
    case actionTypes.DELETE_CALCULATION_FAILURE:
      return {
        ...state,
        deletingCalculations: [
          ...(state.deletingCalculations.filter(c => c !== action.payload))
        ],
      };
    default:
      return state;
  }
};
