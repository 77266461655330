import React from 'react';
import { Stepper, StepButton, Step, StepLabel, StepIconProps, useMediaQuery, Theme, Box, makeStyles, createStyles, Select, MenuItem } from '@material-ui/core';
import { toNumber } from 'lodash';
import SelectedIcon from '@material-ui/icons/LocationOn';
import { CalculationStep } from './calculation-page.types';
import styles from './CalculationStepper.module.scss';

type CalculationStepperProps = {
  activeStepIndex: number;
  steps: CalculationStep[];
  handleBreadcrumbStepChange: (newStepIndex: number) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    step: {
      hyphens: 'auto',
    },
  })
);

export default function CalculationStepper(props: CalculationStepperProps) {
  const { activeStepIndex, steps } = props;

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const classes = useStyles();

  return isMobileView || (isTabletView && steps.length > 5) ? (
    <Box>
      <Select fullWidth
              variant="outlined"
              value={activeStepIndex}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => props.handleBreadcrumbStepChange(toNumber(event.target.value))}>
        {steps.map((step, index) => (
          <MenuItem key={step.id} value={index}>
            {index + 1}:&nbsp;
            {step.renderLabel()}
          </MenuItem>
        ))}
      </Select>
    </Box>
  ) : (
    <Stepper nonLinear alternativeLabel activeStep={activeStepIndex}>
      {steps.map((step, index) => (
        <Step key={step.id} className={classes.step}>
          <StepButton color="inherit" onClick={() => props.handleBreadcrumbStepChange(index)}>
            <StepLabel StepIconComponent={StepIcon}>
              {step.renderLabel(true)}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

function StepIcon(props: StepIconProps) {
  const { active } = props;
  return <div className={styles.stepIcon}>
    {
      active
        ? <SelectedIcon className={styles.active} />
        : <div className={styles.circle} />
    }
  </div>;
}
