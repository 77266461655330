/**
 * Represents a specific step in the Calculaton Wizard.
 * The step can either be a layer in the calculation, or any other step in the wizard which isn't a layer.
 */
export class CalculationStep {
  constructor(
    public renderLabel: (insertSuggestedHyphenationIfRequired?: boolean) => JSX.Element,
    public id: string,
  ) { }
}
