import {
  FETCH_CONSTRUCTION_TYPES_ATTEMPT,
  FETCH_CONSTRUCTION_TYPES_SUCCESS,
  FETCH_CONSTRUCTION_TYPES_FAILURE,
  FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS,

  FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT,
  FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS,
  FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE,

  FetchConstructionTypesAttempt,
  FetchConstructionTypesSuccess,
  FetchConstructionTypesFailure,
  FetchConstructionTypeNamesSuccess,

  FetchConstructionLayersForTypeAttempt,
  FetchConstructionLayersForTypeSuccess,
  FetchConstructionLayersForTypeFailure,

} from './construction-data.action-types';
import { ConstructionType, ConstructionLayer } from '../../types/domain/construction-data.types';

export const fetchConstructionTypesAttempt = (): FetchConstructionTypesAttempt => ({
  type: FETCH_CONSTRUCTION_TYPES_ATTEMPT,
});

export const fetchConstructionTypesSuccess = (data: ConstructionType[]): FetchConstructionTypesSuccess => ({
  type: FETCH_CONSTRUCTION_TYPES_SUCCESS,
  payload: data,
});

export const fetchConstructionTypeNamesSuccess = (data: ConstructionType[]): FetchConstructionTypeNamesSuccess => ({
  type: FETCH_CONSTRUCTION_TYPE_NAMES_SUCCESS,
  payload: data,
});

export const fetchConstructionTypesFailure = (error: string): FetchConstructionTypesFailure => ({
  type: FETCH_CONSTRUCTION_TYPES_FAILURE,
  error,
});

export const fetchConstructionLayersForTypeAttempt = (): FetchConstructionLayersForTypeAttempt => ({
  type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_ATTEMPT,
});

export const fetchConstructionLayersForTypeSuccess = (constructionType: string, data: ConstructionLayer[], constructionTypeNameLocalized: string): FetchConstructionLayersForTypeSuccess => ({
  type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_SUCCESS,
  payload: {
    constructionType: constructionType,
    constructionTypeNameLocalized,
    layers: data,
  },
});

export const fetchConstructionLayersForTypeFailure = (error: string): FetchConstructionLayersForTypeFailure => ({
  type: FETCH_CONSTRUCTION_LAYERS_FOR_TYPE_FAILURE,
  error,
});
