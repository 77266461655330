import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ConstructionLayer, AirCavityGrading } from '../../types/domain/construction-data.types';
import { useDispatch, useSelector } from 'react-redux';
import { activeConstructionLayerSelector } from '../../store/component-state/component-state.selectors';
import { currentSelectedAirCavityGradingSelector } from '../../store/current-calculation-data/current-calculation-data.selectors';
import { setAirCavityGrading } from '../../store/current-calculation-data/current-calculation-data.action-creators';
import AirCavityThickness from './AirCavityThickness';

export default function AirCavity() {
  const dispatch = useDispatch();
  const layer: ConstructionLayer = useSelector(activeConstructionLayerSelector);
  const currentSelectedAcg: AirCavityGrading | undefined = useSelector(currentSelectedAirCavityGradingSelector);

  const handleAcgChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setAirCavityGrading(layer, layer.airCavityGradings.filter(ac => ac.airCavityGradingId === (event.target.value as string))[0]));
  };

  return (<>
    <div className={'marginTop20'}>
      <Typography gutterBottom data-qa-id="materialSelectLabel">
        <Trans i18nKey="calculation-ui.material-label">Material</Trans>
      </Typography>

      <Select fullWidth data-qa-id="materialSelect" variant="outlined" value={currentSelectedAcg?.airCavityGradingId ?? ''} onChange={handleAcgChange}>
        {layer.airCavityGradings.map(i => (
          <MenuItem key={i.airCavityGradingId} value={i.airCavityGradingId} data-qa-id={`${i.airCavityGradingId}-dropdownvalue`}>
            <Trans i18nKey={`calculation-ui.air-cavity-type.${i.airCavityGradingId}`}>{i.airCavityGradingId}</Trans>
          </MenuItem>
        ))}
      </Select>
    </div>
    <AirCavityThickness />
  </>);
}
