import React, { ReactNode } from 'react';
import { Container } from '@material-ui/core';

import styles from './Layout.module.scss';

type Props = {
  children: ReactNode;
};

export function LayoutMinimal({ children }: Props) {
  return (
    <Container disableGutters>
      <div className={styles.page}>
        <Container>
          <div data-qa-id="pageContent" className={styles.content}>
            {children}
          </div>
        </Container>
      </div>
    </Container>
  );
}
