import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation, Trans } from 'react-i18next';
import { ConstructionType, Category } from '../../types/domain/construction-data.types';
import { Card, CardActionArea, CardContent, CircularProgress, Typography, CardMedia, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { constructionTypesSelector, constructionCategoriesSelector } from '../../store/construction-data/construction-data.selectors';

import './ConstructionTypeSelection.module.scss';
import { getAppConfig } from '../../config/config';
import { StoreModel } from '../../store/store.model';
import { buildRoute } from '../../helpers/routeHelper';
import SignInDialog from './SignInDialog';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selectors';
import { setActiveConstructionType } from '../../store/component-state/component-state.actions';
import { countryIdSelector, countrySelector } from '../../store/component-state/component-state.selectors';
import { isLoadingConstructionTypesSelector } from '../../store/construction-data/construction-data.selectors';
import { isConfirmedGuestSelector } from '../../store/account/account.selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    media: {
      height: 270,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
  })
);

export default function ConstructionTypeSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore().getState() as StoreModel;
  const apiUrl = getAppConfig()?.apiUrl;

  const constructionTypes: ConstructionType[] = useSelector(constructionTypesSelector);
  const categories: Category[] = useSelector(constructionCategoriesSelector);
  const country = useSelector(countrySelector);
  const countryId = useSelector(countryIdSelector).toLowerCase();
  const isLoadingConstructionTypes = useSelector(isLoadingConstructionTypesSelector);
  const countryIdUpper = countryId.toUpperCase();
  const isAuthenticated: boolean = useSelector(isAuthenticatedSelector);
  const isConfirmedGuest: boolean | undefined = useSelector(isConfirmedGuestSelector);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState('');

  const { t } = useTranslation();

  const handleConstructionTypeSelection = (selectedConstructionType: string) => {
    if (!isAuthenticated && !isConfirmedGuest) {
      setOpen(true);
      setSelectedType(selectedConstructionType);
    } else {
      history.push(buildRoute(store, `/calculation/${selectedConstructionType}`));
    }
  };

  const handleSignInClose = (cont: boolean) => {
    setOpen(false);
    if (cont) {
      dispatch(setActiveConstructionType(selectedType));
      history.push(buildRoute(store, `/calculation/${selectedType}`));
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div className={'title'} data-qa-id="titleLabel">
            {t(`by-country.${countryId}.construction-type-selection-ui.title`, {
              resultType: t(`calculation-result-type.singular.${country?.calculationResultType}`),
            })}
          </div>
          <div className={'subTitle'} data-qa-id="subTitleLabel">
            {t(`by-country.${countryId}.construction-type-selection-ui.sub-title`, {
              resultTypePlural: t(`calculation-result-type.plural.${country?.calculationResultType}`),
              calculationStandardName: country?.calculationStandardName,
            })}
          </div>
        </Grid>
      </Grid>

      {isLoadingConstructionTypes &&
        <CircularProgress size={50} />
      }

      {categories.map((c, index) => (
        <div key={index}>
          <div className={'groupTitle'}>
            <Trans i18nKey={`construction-category.${c.toLowerCase()}`}>
              {c}
            </Trans>
          </div>
          <Divider />
          <br />
          <Grid container spacing={2}>
            {constructionTypes
              .filter(ct => ct.category === c)
              .map(ct => (
                <Grid item xs={12} sm={6} md={4} key={ct.id} data-qa-id={`${ct.id}-card`}>
                  <Card>
                    <CardActionArea onClick={() => { handleConstructionTypeSelection(ct.id); }}>
                      <CardMedia
                        className={classes.media}
                        image={`${apiUrl}/content/build-up-images/${countryIdUpper}/${countryIdUpper}-${ct.id}.jpg`}
                        title={ct.nameLocalized}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" data-qa-id={`${ct.id}-cardtext`}>
                          {ct.nameLocalized}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      ))}

      <SignInDialog open={open} handleClose={handleSignInClose} />
    </div>
  );
}
