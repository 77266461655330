import { TFunction } from 'i18next';
import { toast } from 'react-toastify';

export const displayPdfDownloadToast = (success: boolean, t: TFunction) => {
  if (success) {
      toast.success(t('email-dialog.success-dialog'));
  } else {
      toast.error(t('email-dialog.failure-dialog'));
  }
};
