import { default as axiosDefault } from 'axios';
import { store } from '../store/store';
import authProviderFactory from '../authentication/authProvider';
import { getAppConfig } from '../config/config';

function init() {
  axiosDefault.defaults.baseURL = getAppConfig()?.apiUrl;

  axiosDefault.interceptors.request.use(
    config => {
      config.headers = config.headers ?? {};
      config.headers['Accept-Language'] = store.getState().componentState.selectedCulture;
      return config;
    },
    error => (Promise.reject(error)));


  axiosDefault.interceptors.request.use(
    async (config) => {
      if (config.withCredentials) {
        const idToken = await authProviderFactory().getIdToken();
        config.headers['Authorization'] = `Bearer ${idToken?.idToken.rawIdToken}`;
      }
      return config;
    },
    error => (Promise.reject(error))
  );
}

export default {
  init,
};
