import { NewsItemModel } from '../store.model';

import {
  NewsItemActionTypes,
  FETCH_NEWS_ITEM_SUCCESS,
  FETCH_NEWS_ITEM_FAILURE,
  FETCH_NEWS_ITEM_ATTEMPT,
  CLEAR_NEWS_ITEM,
} from './news-item.action-types';

export const initialState: NewsItemModel = {
  isLoading: false,
  error: null,
};

export function newsItemReducer(state = initialState, action: NewsItemActionTypes): NewsItemModel {
  
  switch (action.type) {
    case FETCH_NEWS_ITEM_ATTEMPT:
      return {
        ...initialState,
        isLoading: true,
      };
    case FETCH_NEWS_ITEM_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        content: action.payload,
      };
    case FETCH_NEWS_ITEM_FAILURE:
      return {
        ...initialState,
        isLoading: false,
        error: action.error,
      };
    case CLEAR_NEWS_ITEM:
      return { 
        ...initialState
      };
    default:
      return state;
  }
}
