
import { AuthenticationActions, AuthenticationState, IAccountInfo, IdTokenResponse, AccessTokenResponse } from 'react-aad-msal';
import { AuthenticationStateModel } from '../store.model';
import { AuthError } from 'msal';

const initialState: AuthenticationStateModel = {
  initializing: false,
  initialized: false,
  state: AuthenticationState.Unauthenticated
};


export const authenticationReducer = (
  state: AuthenticationStateModel = initialState,
  action: { type: AuthenticationActions; payload: IAccountInfo | AuthError | IdTokenResponse | AccessTokenResponse | AuthenticationState },
): AuthenticationStateModel => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false
      };
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true
      };
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload as IdTokenResponse
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload as AccessTokenResponse
      };
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: undefined
      };
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload as IAccountInfo
      };
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return {
        ...state,
        idToken: undefined,
        accessToken: undefined,
        account: undefined,
      };
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload as AuthenticationState
      };
    default:
      return state;
  }
};